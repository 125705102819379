import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
} from "react-bootstrap";
import useCommonContext from "../../../hooks/useCommonContext";
import ModalError from "../../modals/ModalError";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import JablanaNastanekCvetnihBrstov from "./JablanaNastanekCvetnihBrstov";
import fetcherGet from "../../fetcher/fetcherGet";
import JablanaOcenaCvetenja from "./JablanaOcenaCvetenja";
import JablanaRodniNastavek from "./JablanaRodniNastavek";

const JablanaCvetenjeVnosPregled = ({ izbranoLeto, setNiPodatkovZaIzbranoLeto }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [allBloomingData, setAllBloomingData] = useState({
    ocenaNastankaCvetnihBrstov: [],
    ocenaCvetenja: [],
    ocenaRodnegaNastavka: [],
  });

  const fetchBloomingData = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/cvetenje",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setAllBloomingData
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchBloomingData(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Card>
      <Card.Body>
        <Container>
          <JablanaNastanekCvetnihBrstov
            ocenaNastankaCvetnihBrstov={allBloomingData.ocenaNastankaCvetnihBrstov.filter(
              (e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              }
            )}
            setModalError={setModalError}
            setShowModal={setShowModal}
            fetchBloomingData={fetchBloomingData}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />

          <JablanaOcenaCvetenja
            ocenaCvetenja={allBloomingData.ocenaCvetenja.filter((e) => {
              return new Date(e.datum).getFullYear() === izbranoLeto;
            })}
            setModalError={setModalError}
            setShowModal={setShowModal}
            fetchBloomingData={fetchBloomingData}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />

          <JablanaRodniNastavek
            ocenaRodnegaNastavka={allBloomingData.ocenaRodnegaNastavka.filter(
              (e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              }
            )}
            setModalError={setModalError}
            setShowModal={setShowModal}
            fetchBloomingData={fetchBloomingData}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />

          <ModalError
            modalError={modalError}
            show={showModal}
            setShowModal={setShowModal}
          />
        </Container>
      </Card.Body>
    </Card>
  );
};

export default JablanaCvetenjeVnosPregled;
