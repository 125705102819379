import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
} from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../../fetcher/fetcherGet";
import JablanaDatumTstadija from "./JablanaDatumTstadija";
import JablanaUspesnostRedcenja from "./JablanaUspesnostRedcenja";
import JablanaCiljniPridelek from "./JablanaCiljniPridelek";

const JablanaRazvojPlodicevVnosPregled = ({ izbranoLeto, setNiPodatkovZaIzbranoLeto }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [allRazvojPlodicevData, setAllRazvojPlodicevData] = useState({
    spremljanjeRazvojaPlodicev: [],
    ocenaUspesnostiRedcenja: [],
    ciljniPridelek: [],
    rocnoRedcenje: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const fetchRazvojPlodicevData = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/razvojPlodicev",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setAllRazvojPlodicevData
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();

    fetchRazvojPlodicevData(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Card>
      <Card.Body>
        <Container>
          <JablanaDatumTstadija
            spremljanjeRazvojaPlodicev={allRazvojPlodicevData.spremljanjeRazvojaPlodicev.filter(
              (e) => {
                return new Date(e.datumTStadija).getFullYear() === izbranoLeto;
              }
            )}
            setModalError={setModalError}
            setShowModal={setShowModal}
            fetchRazvojPlodicevData={fetchRazvojPlodicevData}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />

          <JablanaUspesnostRedcenja
            ocenaUspesnostiRedcenja={allRazvojPlodicevData.ocenaUspesnostiRedcenja.filter(
              (e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              }
            )}
            setModalError={setModalError}
            setShowModal={setShowModal}
            fetchRazvojPlodicevData={fetchRazvojPlodicevData}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />

          <JablanaCiljniPridelek
            ciljniPridelek={allRazvojPlodicevData.ciljniPridelek.filter((e) => {
              return new Date(e.createdAt).getFullYear() === izbranoLeto;
            })}
            rocnoRedcenje={allRazvojPlodicevData.rocnoRedcenje.filter((e) => {
              return new Date(e.datum).getFullYear() === izbranoLeto;
            })}
            setModalError={setModalError}
            setShowModal={setShowModal}
            fetchRazvojPlodicevData={fetchRazvojPlodicevData}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />

          <ModalError
            modalError={modalError}
            setShowModal={setShowModal}
            show={showModal}
          />
        </Container>
      </Card.Body>
    </Card>
  );
};

export default JablanaRazvojPlodicevVnosPregled;
