import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import cesnjaOcenaCvetenjaBesedilo from "../../../ocene/cesnjaOceneCvetenja";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import fetcherPost from "../../fetcher/fetcherPost";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";

const CesnjaOceneCvetenja = ({
  fetchPodatkeOcenjevanjeInNapoved,
  setShowModal,
  setModalError,
  oceneCvetenja,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [izbranaOcenaCvetenja, setIzbranaOcenaCvetenja] = useState();
  const [izbranDatumOceneCvetenja, setIzbranDatumOceneCvetenja] = useState(
    new Date().toISOString().slice(0, 10)
  );

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(oceneCvetenja?.length > 0 ? false : true);
  }, [oceneCvetenja]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "DarkSeaGreen" }}>
        <Card.Header>Ocene cvetenja</Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "DarkSeaGreen" }}>
                    Datum ocenjevanja
                  </th>
                  <th style={{ backgroundColor: "DarkSeaGreen" }}>Ocena</th>

                  {oceneCvetenja[0]?.datum &&
                  new Date(oceneCvetenja[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "DarkSeaGreen" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>

              <tbody>
                {oceneCvetenja.length > 0 &&
                  oceneCvetenja.map((item) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                      briseSe={"OCENA_CVETENJA"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={item.datum}
                              inputType={"date"}
                              id={item.id}
                              link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                              spreminjaSe={"CVETENJE_DATUM"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>

                          <td>
                            <EditableCell
                              initialValue={item.ocena}
                              inputType={"number"}
                              id={item.id}
                              link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                              imeLastnosti={"ocena"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                              spreminjaSe={"CVETENJE_OCENA"}
                              step={1}
                              min={1}
                              max={10}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>
          <Card className="m-1" style={{ backgroundColor: "DarkSeaGreen" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();

                  if (izbranaOcenaCvetenja < 1 || izbranaOcenaCvetenja > 10) {
                    setShowModal(true);
                    setModalError(
                      `Izbrana ocena cvetenja ni pravilna. Izberite oceno od 1 do 10!`
                    );

                    return;
                  }

                  await fetcherPost(
                    `cesnja/ocenjavanjeInNapovedovanjePridelka`,
                    {
                      datum: izbranDatumOceneCvetenja,
                      ocena: izbranaOcenaCvetenja,
                      tip: "OCENA CVETENJA",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setIzbranaOcenaCvetenja();
                  setIzbranDatumOceneCvetenja(
                    new Date().toISOString().slice(0, 10)
                  );

                  fetchPodatkeOcenjevanjeInNapoved();
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="ocenaCvetenja">
                    Ocena cvetenja:
                  </Form.Label>
                  <Form.Select
                    aria-label="Ocena rodnega nastavka"
                    name="ocenaCvetenja"
                    required
                    onChange={(e) => setIzbranaOcenaCvetenja(e.target.value)}
                    value={izbranaOcenaCvetenja}
                  >
                    <option hidden>Izberite oceno cvetenja</option>
                    {cesnjaOcenaCvetenjaBesedilo.map((ocena, index) => (
                      <option key={Math.random()} value={index + 1}>
                        {ocena}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Text className="text-muted">
                    Izberite oceno cvetenja
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="ocenaCvetenjaDatum">Datum:</Form.Label>
                  <Form.Control
                    id="ocenaCvetenjaDatum"
                    type="date"
                    value={izbranDatumOceneCvetenja}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) =>
                      setIzbranDatumOceneCvetenja(e.target.value)
                    }
                    placeholder="Vnesite datum ocenjevanja cvetenja"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum ocenjevanja cvetenja
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani oceno cvetenja
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CesnjaOceneCvetenja;
