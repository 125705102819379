import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import fetcherPost from "../../fetcher/fetcherPost";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import { useEffect } from "react";

const BorovnicaSladkornaStopnja = ({
  vseSladkorneStopnje,
  fetchPodatkePridelek,
  setShowModal,
  setModalError,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [datumSladkorneStopnje, setDatumSladkorneStopnje] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const [brix, setBrix] = useState();

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vseSladkorneStopnje?.length > 0 ? false : true);
  }, [vseSladkorneStopnje]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "DarkSalmon" }}>
        <Card.Header>Podatki o sladkorni stopnji med obiranjem</Card.Header>

        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "DarkSalmon" }}>
                    Datum meritve
                  </th>
                  <th style={{ backgroundColor: "DarkSalmon" }}>Brix°</th>

                  {vseSladkorneStopnje[0]?.datum &&
                  new Date(vseSladkorneStopnje[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "DarkSalmon" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {vseSladkorneStopnje &&
                  vseSladkorneStopnje.map((podatek) => (
                    <DeletionRow
                      id={podatek.id}
                      key={podatek.id}
                      link={"borovnica/pridelek"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkePridelek}
                      briseSe={"SLADKORNA_STOPNJA"}
                      jeTrenutnoLeto={
                        new Date(podatek.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={podatek.datum}
                              inputType={"date"}
                              id={podatek.id}
                              link={"borovnica/pridelek"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkePridelek}
                              spreminjaSe={"SLADKORNA_STOPNJA_DATUM"}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={podatek.brix}
                              inputType={"number"}
                              id={podatek.id}
                              link={"borovnica/pridelek"}
                              imeLastnosti={"brix"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkePridelek}
                              spreminjaSe={"SLADKORNA_STOPNJA_BRIX"}
                              step={0.1}
                              min={0}
                              max={Number.MAX_SAFE_INTEGER}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Card className="m-1" style={{ backgroundColor: "DarkSalmon" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await fetcherPost(
                    `borovnica/pridelek`,
                    {
                      datum: datumSladkorneStopnje,
                      brix: brix,
                      tip: "SLADKORNA_STOPNJA",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setBrix("");
                  setDatumSladkorneStopnje(
                    new Date().toISOString().slice(0, 10)
                  );

                  fetchPodatkePridelek(new AbortController().signal);
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="datumSladkorneStopnje">
                    Datum:
                  </Form.Label>
                  <Form.Control
                    id="datumSladkorneStopnje"
                    type="date"
                    value={datumSladkorneStopnje}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setDatumSladkorneStopnje(e.target.value)}
                    placeholder="Vnesite datum izmere sladkorne stopnje"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum izmere sladkorne stopnje
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="obrok">Brix°</Form.Label>
                  <Form.Control
                    id="obrok"
                    type="number"
                    min={0.0}
                    step={0.1}
                    required
                    value={brix}
                    onChange={(e) => setBrix(e.target.value)}
                    placeholder={`Vnesite sladkorno stopnjo v Brix°`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite sladkorno stopnjo v Brix°
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default BorovnicaSladkornaStopnja;
