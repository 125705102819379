import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import imenaKoristnegaOrganizma from "./imenaKoristnegaOrganizma";

const JablanaKoristniTabela = ({
  vsiKoristni,
  fetchKoristniData,
  setModalError,
  setShowModal,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsiKoristni?.length > 0 ? false : true);
  }, [vsiKoristni]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ backgroundColor: "BlanchedAlmond" }}>
              Ime koristnega organizma
            </th>

            <th style={{ backgroundColor: "BlanchedAlmond" }}>Datum</th>
            <th style={{ backgroundColor: "BlanchedAlmond" }}>
              Je organizem prisoten
            </th>
            <th style={{ backgroundColor: "BlanchedAlmond" }}>Opombe</th>

            {vsiKoristni[0]?.datum &&
            new Date(vsiKoristni[0]?.datum).getFullYear() ===
              new Date().getFullYear() ? (
              <th style={{ backgroundColor: "BlanchedAlmond" }}>Izbris</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {vsiKoristni &&
            vsiKoristni.map((item) => (
              <DeletionRow
                id={item.id}
                key={item.id}
                link={"jablana/koristni"}
                setModalError={setModalError}
                setShowModal={setShowModal}
                fetchNewData={fetchKoristniData}
                briseSe={"JABLANA_KORISTNI"}
                jeTrenutnoLeto={
                  new Date(item.datum).getFullYear() ===
                  new Date().getFullYear()
                }
                children={
                  <>
                    <td>
                      <EditableCell
                        initialValue={item.imeKoristnegaOrganizma}
                        inputType={"select"}
                        id={item.id}
                        link={"jablana/koristni"}
                        imeLastnosti={"imeKoristnegaOrganizma"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchKoristniData}
                        spreminjaSe={"KORISTNI_IME"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                        poljeMoznosti={imenaKoristnegaOrganizma}
                      />
                    </td>

                    <td>
                      <EditableCell
                        initialValue={item.datum}
                        inputType={"date"}
                        id={item.id}
                        link={"jablana/koristni"}
                        imeLastnosti={"datum"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchKoristniData}
                        spreminjaSe={"KORISTNI_DATUM"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>

                    <td>
                      <EditableCell
                        initialValue={
                          item.jeKoristniOrganizemPrisoten
                            ? "je prisoten"
                            : "ni prisoten"
                        }
                        inputType={"radio"}
                        id={item.id}
                        link={"jablana/koristni"}
                        radioValue={item.jeKoristniOrganizemPrisoten}
                        imeLastnosti={"jeKoristniOrganizemPrisoten"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchKoristniData}
                        spreminjaSe={"KORISTNI_PRISOTNOST"}
                        moznosti={["je prisoten", "ni prisoten"]}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.opombe}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/koristni"}
                        imeLastnosti={"opombe"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchKoristniData}
                        spreminjaSe={"KORISTNI_OPOMBE"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                  </>
                }
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default JablanaKoristniTabela;
