import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import imenaEnoteTerminiSkodljivcev from "./imenaEnoteTerminiSkodljivcev";

const JablanaSkodljivciTabela = ({
  vsiSkodljivci,
  fetchSkodljivciData,
  setModalError,
  setShowModal,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsiSkodljivci?.length > 0 ? false : true);
  }, [vsiSkodljivci]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ backgroundColor: "FloralWhite" }}>Ime škodljivca</th>
            <th style={{ backgroundColor: "FloralWhite" }}>
              Termin za spremljanje pojava
            </th>
            <th style={{ backgroundColor: "FloralWhite" }}>Moč pojava</th>
            <th style={{ backgroundColor: "FloralWhite" }}>Enota</th>
            <th style={{ backgroundColor: "FloralWhite" }}>Datum</th>
            <th style={{ backgroundColor: "FloralWhite" }}>Opombe</th>

            {vsiSkodljivci[0]?.datum &&
            new Date(vsiSkodljivci[0]?.datum).getFullYear() ===
              new Date().getFullYear() ? (
              <th style={{ backgroundColor: "FloralWhite" }}>Izbris</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {vsiSkodljivci &&
            vsiSkodljivci.map((item) => (
              <DeletionRow
                id={item.id}
                key={item.id}
                link={"jablana/skodljivci"}
                setModalError={setModalError}
                setShowModal={setShowModal}
                fetchNewData={fetchSkodljivciData}
                briseSe={"JABLANA_SKODLJIVEC"}
                jeTrenutnoLeto={
                  new Date(item.datum).getFullYear() ===
                  new Date().getFullYear()
                }
                children={
                  <>
                    <td>{item.imeSkodljivca}</td>
                    <td>
                      {new Set(
                        imenaEnoteTerminiSkodljivcev[item.imeSkodljivca].map(
                          (e) => e.termin
                        )
                      )?.size > 1 ? (
                        <EditableCell
                          key={JSON.stringify(item)}
                          initialValue={item.termin}
                          inputType={"select"}
                          radioValue={item.termin}
                          id={item.id}
                          link={"jablana/skodljivci"}
                          imeLastnosti={"termin"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchSkodljivciData}
                          spreminjaSe={"SKODLJIVEC_TERMIN"}
                          poljeMoznosti={imenaEnoteTerminiSkodljivcev[
                            item.imeSkodljivca
                          ].map((e) => e.termin)}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      ) : (
                        <>{item.termin}</>
                      )}
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.mocPojava}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skodljivci"}
                        imeLastnosti={"mocPojava"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkodljivciData}
                        spreminjaSe={"SKODLJIVEC_MOC_POJAVA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      {new Set(
                        imenaEnoteTerminiSkodljivcev[item.imeSkodljivca].map(
                          (e) => e.enota
                        )
                      )?.size > 1 ? (
                        <EditableCell
                          key={JSON.stringify(item)}
                          initialValue={item.enota}
                          inputType={"select"}
                          id={item.id}
                          link={"jablana/skodljivci"}
                          imeLastnosti={"enota"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchSkodljivciData}
                          spreminjaSe={"SKODLJIVEC_ENOTA"}
                          poljeMoznosti={imenaEnoteTerminiSkodljivcev[
                            item.imeSkodljivca
                          ].map((e) => e.enota)}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      ) : (
                        <>{item.enota}</>
                      )}
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.datum}
                        inputType={"date"}
                        id={item.id}
                        link={"jablana/skodljivci"}
                        imeLastnosti={"datum"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkodljivciData}
                        spreminjaSe={"SKODLJIVEC_DATUM"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.opombe}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skodljivci"}
                        imeLastnosti={"opombe"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkodljivciData}
                        spreminjaSe={"SKODLJIVEC_OPOMBE"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                  </>
                }
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default JablanaSkodljivciTabela;
