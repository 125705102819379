import React, { useCallback, useEffect, useState } from "react";
import { Container, Card, Tabs, Tab, Row, Col } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import AnalizaZrelostnegaStanjaForma from "./AnalizaZrelostnegaStanjaForma";
import AnalizaZrelostnegaStanjaTabela from "./AnalizaZrelostnegaStanjaTabela";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherPost from "../../fetcher/fetcherPost";
import fetcherGet from "../../fetcher/fetcherGet";

const JablanaAnalizaZrelostnegaStanjaVnosPregled = ({ izbranoLeto, setNiPodatkovZaIzbranoLeto }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [key, setKey] = useState("dvaTednaPredObiranjem");

  const [allAnalizaZrelostnegaStanjaData, setAllAnalizaZrelostnegaStanjaData] =
    useState({});
  const [
    analizaZrelostnegaStanjaDvaTednaPrej,
    setAnalizaZrelostnegaStanjaDvaTednaPrej,
  ] = useState({
    trdota: 0,
    tss: 0,
    skrobniIndeks: 0,
    datum: new Date().toISOString().slice(0, 10),
  });

  const [
    analizaZrelostnegaStanjaEnTedenPrej,
    setAnalizaZrelostnegaStanjaEnTedenPrej,
  ] = useState({
    trdota: 0,
    tss: 0,
    skrobniIndeks: 0,
    datum: new Date().toISOString().slice(0, 10),
  });
  const [
    analizaZrelostnegaStanjaObObiranju,
    setAnalizaZrelostnegaStanjaObObiranju,
  ] = useState({
    trdota: 0,
    tss: 0,
    skrobniIndeks: 0,
    datum: new Date().toISOString().slice(0, 10),
  });

  const [backgroundColor, setBackgroundColor] = useState("FloralWhite");

  const fetchAnalizaZrelostnegaStanjaData = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/analizaZrelostnegaStanja",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setAllAnalizaZrelostnegaStanjaData
      );
    },
    [nasadId, history, signOut]
  );

  const submitData = async (tip) => {
    const body = {
      tip: tip,
      trdota:
        tip === "dvaTednaPredObiranjem"
          ? analizaZrelostnegaStanjaDvaTednaPrej.trdota
          : tip === "enTedenPredObiranjem"
          ? analizaZrelostnegaStanjaEnTedenPrej.trdota
          : analizaZrelostnegaStanjaObObiranju.trdota,

      tss:
        tip === "dvaTednaPredObiranjem"
          ? analizaZrelostnegaStanjaDvaTednaPrej.tss
          : tip === "enTedenPredObiranjem"
          ? analizaZrelostnegaStanjaEnTedenPrej.tss
          : analizaZrelostnegaStanjaObObiranju.tss,
      skrobniIndeks:
        tip === "dvaTednaPredObiranjem"
          ? analizaZrelostnegaStanjaDvaTednaPrej.skrobniIndeks
          : tip === "enTedenPredObiranjem"
          ? analizaZrelostnegaStanjaEnTedenPrej.skrobniIndeks
          : analizaZrelostnegaStanjaObObiranju.skrobniIndeks,
      datum:
        tip === "dvaTednaPredObiranjem"
          ? analizaZrelostnegaStanjaDvaTednaPrej.datum
          : tip === "enTedenPredObiranjem"
          ? analizaZrelostnegaStanjaEnTedenPrej.datum
          : analizaZrelostnegaStanjaObObiranju.datum,
    };

    await fetcherPost(
      `jablana/analizaZrelostnegaStanja`,
      body,
      nasadId,
      setModalError,
      setShowModal,
      signOut,
      history
    );

    switch (tip) {
      case "dvaTednaPredObiranjem": {
        setAnalizaZrelostnegaStanjaDvaTednaPrej({
          trdota: 0,
          tss: 0,
          skrobniIndeks: 0,
          datum: new Date().toISOString().slice(0, 10),
        });
        break;
      }
      case "enTedenPredObiranjem": {
        setAnalizaZrelostnegaStanjaEnTedenPrej({
          trdota: 0,
          tss: 0,
          skrobniIndeks: 0,
          datum: new Date().toISOString().slice(0, 10),
        });
        break;
      }
      case "obObiranju": {
        setAnalizaZrelostnegaStanjaObObiranju({
          trdota: 0,
          tss: 0,
          skrobniIndeks: 0,
          datum: new Date().toISOString().slice(0, 10),
        });
        break;
      }

      default:
        break;
    }

    fetchAnalizaZrelostnegaStanjaData();
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchAnalizaZrelostnegaStanjaData(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Card style={{ backgroundColor: backgroundColor }}>
      <Card.Body>
        <Container>
          <Row>
            <Tabs
              onSelect={(k) => {
                console.log(`zavihek s ključem ${k}`);
                switch (k) {
                  case "dvaTednaPredObiranjem": {
                    setBackgroundColor("FloralWhite");
                    break;
                  }
                  case "enTedenPredObiranjem": {
                    setBackgroundColor("Lavender");
                    break;
                  }
                  case "obObiranju": {
                    setBackgroundColor("LemonChiffon");
                    break;
                  }
                  default: {
                    setBackgroundColor("white");
                    break;
                  }
                }

                setKey(k);
              }}
              id="vnosAnalizeZrelostnegaStanja"
              activeKey={key}
              className="mb-3"
            >
              <Tab
                eventKey="dvaTednaPredObiranjem"
                title="2 tedna pred obiranjem"
              >
                <Col>
                  <AnalizaZrelostnegaStanjaTabela
                    data={allAnalizaZrelostnegaStanjaData?.dvaTednaPredObiranjem?.filter(
                      (e) => {
                        return new Date(e.datum).getFullYear() === izbranoLeto;
                      }
                    )}
                    color={backgroundColor}
                    setModalError={setModalError}
                    setShowModal={setShowModal}
                    fetchAnalizaZrelostnegaStanjaData={
                      fetchAnalizaZrelostnegaStanjaData
                    }
                    setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
                  />

                  <AnalizaZrelostnegaStanjaForma
                    title="2 tedna pred obiranjem"
                    setAnalizaZrelostnegaStanja={
                      setAnalizaZrelostnegaStanjaDvaTednaPrej
                    }
                    analizaZrelostnegaStanja={
                      analizaZrelostnegaStanjaDvaTednaPrej
                    }
                    submitFunction={submitData}
                    tip="dvaTednaPredObiranjem"
                  />
                </Col>
              </Tab>
              <Tab
                eventKey="enTedenPredObiranjem"
                title="1 teden pred obiranjem"
              >
                <AnalizaZrelostnegaStanjaTabela
                  color={backgroundColor}
                  data={allAnalizaZrelostnegaStanjaData?.enTedenPredObiranjem?.filter(
                    (e) => {
                      return new Date(e.datum).getFullYear() === izbranoLeto;
                    }
                  )}
                  setModalError={setModalError}
                  setShowModal={setShowModal}
                  fetchAnalizaZrelostnegaStanjaData={
                    fetchAnalizaZrelostnegaStanjaData
                  }
                  setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
                />

                <AnalizaZrelostnegaStanjaForma
                  title="1 teden pred obiranjem"
                  setAnalizaZrelostnegaStanja={
                    setAnalizaZrelostnegaStanjaEnTedenPrej
                  }
                  analizaZrelostnegaStanja={analizaZrelostnegaStanjaEnTedenPrej}
                  submitFunction={submitData}
                  tip="enTedenPredObiranjem"
                />
              </Tab>
              <Tab eventKey="obObiranju" title="Ob obiranju">
                <AnalizaZrelostnegaStanjaTabela
                  color={backgroundColor}
                  data={allAnalizaZrelostnegaStanjaData?.obObiranju?.filter(
                    (e) => {
                      return new Date(e.datum).getFullYear() === izbranoLeto;
                    }
                  )}
                  setModalError={setModalError}
                  setShowModal={setShowModal}
                  fetchAnalizaZrelostnegaStanjaData={
                    fetchAnalizaZrelostnegaStanjaData
                  }
                  setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
                />
                <AnalizaZrelostnegaStanjaForma
                  title="ob obiranju"
                  setAnalizaZrelostnegaStanja={
                    setAnalizaZrelostnegaStanjaObObiranju
                  }
                  analizaZrelostnegaStanja={analizaZrelostnegaStanjaObObiranju}
                  submitFunction={submitData}
                  tip="obObiranju"
                />
              </Tab>
            </Tabs>
          </Row>
          <ModalError
            modalError={modalError}
            show={showModal}
            setShowModal={setShowModal}
          />
        </Container>
      </Card.Body>
    </Card>
  );
};

export default JablanaAnalizaZrelostnegaStanjaVnosPregled;
