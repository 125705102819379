import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";

const AnalizaZrelostnegaStanjaTabela = ({
  data,
  color,
  setShowModal,
  setModalError,
  fetchAnalizaZrelostnegaStanjaData,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(data?.length > 0 ? false : true);
  }, [data]);

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th style={{ backgroundColor: color }}>Trdota (kg/cm²)</th>
          <th style={{ backgroundColor: color }}>TSS (˚ Brix)</th>
          <th style={{ backgroundColor: color }}>Škrobni indeks (1-10)</th>
          <th style={{ backgroundColor: color }}>Streifov indeks</th>
          <th style={{ backgroundColor: color }}>Datum</th>

          {data &&
          data[0]?.datum &&
          new Date(data[0]?.datum).getFullYear() ===
            new Date().getFullYear() ? (
            <th style={{ backgroundColor: color }}>Izbris</th>
          ) : (
            <></>
          )}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((item) => (
            <DeletionRow
              id={item.id}
              key={item.id}
              link={"jablana/analizaZrelostnegaStanja"}
              setModalError={setModalError}
              setShowModal={setShowModal}
              fetchNewData={fetchAnalizaZrelostnegaStanjaData}
              briseSe={"ANALIZA_ZRELOSTNEGA_STANJA"}
              jeTrenutnoLeto={
                new Date(item.datum).getFullYear() === new Date().getFullYear()
              }
              children={
                <>
                  <td>
                    <EditableCell
                      initialValue={item.trdota}
                      inputType={"number"}
                      id={item.id}
                      link={"jablana/analizaZrelostnegaStanja"}
                      imeLastnosti={"trdota"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchAnalizaZrelostnegaStanjaData}
                      max={Number.MAX_SAFE_INTEGER}
                      min={0}
                      step={0.1}
                      spreminjaSe={"AZS_TRDOTA"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                    />
                  </td>
                  <td>
                    <EditableCell
                      initialValue={item.tss}
                      inputType={"number"}
                      id={item.id}
                      link={"jablana/analizaZrelostnegaStanja"}
                      imeLastnosti={"tss"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchAnalizaZrelostnegaStanjaData}
                      max={Number.MAX_SAFE_INTEGER}
                      min={0}
                      step={0.1}
                      spreminjaSe={"AZS_TSS"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                    />
                  </td>
                  <td>
                    <EditableCell
                      initialValue={item.skrobniIndeks}
                      inputType={"number"}
                      id={item.id}
                      link={"jablana/analizaZrelostnegaStanja"}
                      imeLastnosti={"skrobniIndeks"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchAnalizaZrelostnegaStanjaData}
                      min={1}
                      max={10}
                      step={1}
                      spreminjaSe={"AZS_SKROBNI_INDEX"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                    />
                  </td>
                  <td>{item && parseFloat(item.streifovIndex).toFixed(2)}</td>
                  <td>
                    <EditableCell
                      initialValue={item.datum}
                      inputType={"date"}
                      id={item.id}
                      link={"jablana/analizaZrelostnegaStanja"}
                      imeLastnosti={"datum"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchAnalizaZrelostnegaStanjaData}
                      spreminjaSe={"AZS_DATUM"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                    />
                  </td>
                </>
              }
            />
          ))}
      </tbody>
    </Table>
  );
};

export default AnalizaZrelostnegaStanjaTabela;
