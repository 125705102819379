import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import fetcherPost from "../../fetcher/fetcherPost";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import { useFetchAktivniNasadi } from "../../../hooks/useFetchAktivniNasadi";
import AktivniSeznamiChecklist from "../common/AktivniSeznamiChecklist";
import SaveButton from "../common/SaveButton";

const JablanaPosameznaFertirigacijaVnos = ({
  setModalError,
  setShowModal,
  mesaniceNaVoljo,
  fetchMesanice,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [datum, setDatum] = useState(new Date().toISOString().slice(0, 10));
  const [dozaFertirigacije, setDozaFertirigacije] = useState();

  const [aktivniNasadi, setAktivniNasadi] = useState([]);

  const [idIzbranihNasadov, setIdIzbranihNasadov] = useState({});
  const [idIzbraneMesanice, setIdIzbraneMesanice] = useState();

  const fetchAktivniNasadi = useFetchAktivniNasadi(
    setModalError,
    setShowModal,
    signOut,
    history,
    setAktivniNasadi
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchAktivniNasadi(controller.signal);

    fetchMesanice(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              e.target.reset();
              await fetcherPost(
                `jablana/fertirigacija`,
                {
                  kajSeDodaja: "FERTIRIGACIJA",
                  datum: datum,
                  dozaFertirigacije: dozaFertirigacije,
                  idIzbranihNasadov: Object.keys(idIzbranihNasadov)
                    .filter((key) => idIzbranihNasadov[key].isChecked === true)
                    .map((key) => ({
                      nasadId: key,
                    })),
                  idIzbraneMesanice: idIzbraneMesanice,
                },
                nasadId,
                setModalError,
                setShowModal,
                signOut,
                history
              );

              setDatum(new Date().toISOString().slice(0, 10));
              setDozaFertirigacije();

              setIdIzbranihNasadov({});
              setIdIzbraneMesanice({});
            }}
          >
            <Form.Label htmlFor="skodljivec">
              <h4>Vnos podatkov o fertirigaciji nasada jablane</h4>
            </Form.Label>

            <Table bordered responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "Gainsboro" }}>Datum</th>
                  <th style={{ backgroundColor: "Gainsboro" }}>Doza (m3/ha)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Control
                      id="datum"
                      type="date"
                      min={`${new Date().getFullYear()}-01-01`}
                      value={datum}
                      max={new Date().toISOString().slice(0, 10)}
                      onChange={(e) => setDatum(e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      id="dozaNaNasad"
                      type="number"
                      value={dozaFertirigacije ?? ""}
                      min={0}
                      step={0.1}
                      onChange={(e) => setDozaFertirigacije(e.target.value)}
                      required
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <FormGroup>
              <Row>
                <Col lg>
                  <AktivniSeznamiChecklist
                    text={
                      "Fertirigirani nasadi (GERK, sorta, leto nasaditve, datum spravila):"
                    }
                    setIdIzbranihNasadov={setIdIzbranihNasadov}
                    aktivniNasadi={aktivniNasadi}
                    idIzbranihNasadov={idIzbranihNasadov}
                  />
                </Col>
                <Col lg>
                  <Form.Label htmlFor="uporabljeneMesanice">
                    Uporabljene mešanice za fertirigacijo (količina vode,
                    količina gnojila, gnojilo)
                  </Form.Label>

                  <div
                    key={`checkboxMesanice`}
                    id={"uporabljeneMesanice"}
                    className="mb-3"
                  >
                    <Container>
                      {Object.keys(mesaniceNaVoljo).length > 0 &&
                        mesaniceNaVoljo.mesanice.map((mesanica) => (
                          <Row
                            className="w-75 py-2"
                            key={JSON.stringify(mesanica)}
                          >
                            <Col xl>
                              <Form.Check
                                className="w-50"
                                label={`${mesanica.kolicinaVode}l ${mesanica.kolicinaGnojila}kg ${mesanica.gnojilo}`}
                                name="aktivniNasadi"
                                size="lg"
                                type={"radio"}
                                value={mesanica.id || -1}
                                id={`${mesanica.kolicinaVode}_${mesanica.kolicinaGnojila}_${mesanica.gnojilo}`}
                                checked={
                                  idIzbraneMesanice === mesanica.id ?? false
                                }
                                onChange={(e) => {
                                  setIdIzbraneMesanice(
                                    parseInt(e.target.value)
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        ))}
                    </Container>
                  </div>
                </Col>
              </Row>
            </FormGroup>

            <SaveButton
              disabledCondition={
                Object.keys(idIzbranihNasadov).filter(
                  (key) => idIzbranihNasadov[key].isChecked === true
                ).length === 0 || !idIzbraneMesanice
              }
            />
          </Form>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default JablanaPosameznaFertirigacijaVnos;
