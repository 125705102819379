import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../../EditableCell";
import DeletionRow from "../../../DeletionRow";

const CesnjaPridelekTabela = ({
  pridelekCesnje,
  skupneVrednosti,
  fetchPodatkeOcenjevanjeInNapoved,
  setModalError,
  setShowModal,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(pridelekCesnje?.length > 0 ? false : true);
  }, [pridelekCesnje]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Datum obiranja
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Število dreves
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Pridelek tržne kakovosti (kg/obiranje)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Pridelek tržne kakovosti (kg/drevo)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Pridelek netržne kakovosti (kg/obiranje)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Pridelek netržne kakovosti (kg/drevo)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Pridelek skupaj (kg/drevo)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Pridelek skupaj (t/ha)
            </th>
            <th colSpan={3} style={{ backgroundColor: "AliceBlue" }}>
              Debelina plodov (ocena deleža v %)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Masa 50 plodov (g)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              Masa ploda (g)
            </th>
            <th rowSpan={2} style={{ backgroundColor: "AliceBlue" }}>
              TSS (Brix°)
            </th>

            {pridelekCesnje[0]?.datum &&
            new Date(pridelekCesnje[0]?.datum).getFullYear() ===
              new Date().getFullYear() ? (
              <th style={{ backgroundColor: "AliceBlue" }} rowSpan={2}>
                Izbris
              </th>
            ) : (
              <></>
            )}
          </tr>
          <tr>
            <th style={{ backgroundColor: "AliceBlue" }}>&lt;26mm</th>
            <th style={{ backgroundColor: "AliceBlue" }}>26-28mm</th>
            <th style={{ backgroundColor: "AliceBlue" }}>&gt;28mm</th>
          </tr>
        </thead>
        <tbody>
          <>
            {pridelekCesnje.length > 0 &&
              pridelekCesnje.map((item) => (
                <DeletionRow
                  id={item.id}
                  key={item.id}
                  link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                  setModalError={setModalError}
                  setShowModal={setShowModal}
                  fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                  briseSe={"PRIDELEK"}
                  jeTrenutnoLeto={
                    new Date(item.datum).getFullYear() ===
                    new Date().getFullYear()
                  }
                  children={
                    <>
                      <td>
                        <EditableCell
                          initialValue={item.datum}
                          inputType={"date"}
                          id={item.id}
                          link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                          imeLastnosti={"datum"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                          spreminjaSe={"PRIDELEK_DATUM"}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </td>
                      <td>
                        <EditableCell
                          initialValue={item.steviloObranihDreves}
                          inputType={"number"}
                          id={item.id}
                          link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                          imeLastnosti={"steviloObranihDreves"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                          spreminjaSe={"ST_OBRANIH_DREVES"}
                          step={1}
                          min={0}
                          max={Number.MAX_SAFE_INTEGER}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </td>
                      <td>
                        <EditableCell
                          initialValue={item.kgTrzneKakovosti}
                          inputType={"number"}
                          id={item.id}
                          link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                          imeLastnosti={"kgTrzneKakovosti"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                          spreminjaSe={"KG_TRZNE_KAKOVOSTI"}
                          step={0.1}
                          min={0}
                          max={Number.MAX_SAFE_INTEGER}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </td>
                      <td>{item && item.kgTrzneKakovostiNaDrevo}</td>
                      <td>
                        <EditableCell
                          initialValue={item.kgNetrzneKakovosti}
                          inputType={"number"}
                          id={item.id}
                          link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                          imeLastnosti={"kgNetrzneKakovosti"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                          spreminjaSe={"KG_NETRZNE_KAKOVOSTI"}
                          step={0.1}
                          min={0}
                          max={Number.MAX_SAFE_INTEGER}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </td>
                      <td>
                        {item &&
                          parseFloat(item.kgNetrzneKakovostiNaDrevo).toFixed(2)}
                      </td>
                      <td>
                        {item && parseFloat(item.kgPridelekNaDrevo).toFixed(2)}
                      </td>
                      <td>
                        {item && parseFloat(item.skupajPridelka).toFixed(2)}
                      </td>
                      <td>
                        <EditableCell
                          initialValue={item.debelinaPlodovDo26}
                          key={item.debelinaPlodovDo26}
                          inputType={"number"}
                          id={item.id}
                          link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                          imeLastnosti={"debelinaPlodovDo26"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                          spreminjaSe={"DELEZ_DO_26"}
                          step={1}
                          min={0}
                          max={100}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </td>
                      <td>
                        <EditableCell
                          initialValue={item.debelinaPlodovOd26Do28}
                          key={item.debelinaPlodovOd26Do28}
                          inputType={"number"}
                          id={item.id}
                          link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                          imeLastnosti={"debelinaPlodovOd26Do28"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                          spreminjaSe={"DELEZ_OD_26_28"}
                          step={1}
                          min={0}
                          max={100}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </td>
                      <td>
                        <EditableCell
                          initialValue={item.debelinaPlodovOd28}
                          key={item.debelinaPlodovOd28}
                          inputType={"number"}
                          id={item.id}
                          link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                          imeLastnosti={"debelinaPlodovOd28"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                          spreminjaSe={"DELEZ_OD_28"}
                          step={1}
                          min={0}
                          max={100}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </td>
                      <td>
                        <EditableCell
                          initialValue={item.masa50Plodov}
                          inputType={"number"}
                          id={item.id}
                          link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                          imeLastnosti={"masa50Plodov"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                          spreminjaSe={"MASA_50_PLODOV"}
                          step={0.1}
                          min={0}
                          max={Number.MAX_SAFE_INTEGER}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </td>
                      <td>{item && parseFloat(item.masaPloda).toFixed(2)}</td>
                      <td>
                        <EditableCell
                          initialValue={item.tss}
                          inputType={"number"}
                          id={item.id}
                          link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                          imeLastnosti={"tss"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                          spreminjaSe={"TSS"}
                          step={0.1}
                          min={0}
                          max={Number.MAX_SAFE_INTEGER}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </td>
                    </>
                  }
                />
              ))}

            {pridelekCesnje.length > 0 &&
              Object.keys(skupneVrednosti).length > 0 && (
                <tr>
                  <td>
                    <b>SKUPAJ</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.steviloVsehDreves}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.vsaMasaPridelkaTrzneKakovosti}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.masaPridelkaTrzneKakovostiNaDrevo}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.vsaMasaPridelkaNetrzneKakovosti}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.masaPridelkaNetrzneKakovostiNaDrevo}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.masaVsegaPridelkaNaDrevo}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.masaPridelkaToneNaHektar}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.povprecniDelezDo26}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.povprecniDelezOd26Do26}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.povprecniDelezOd28}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.povprecnaMasa50Plodov}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.povprecnaMasaPloda}</b>
                  </td>
                  <td>
                    <b>{skupneVrednosti.povprecniTSS}</b>
                  </td>
                  <td></td>
                </tr>
              )}
          </>
        </tbody>
      </Table>
    </div>
  );
};

export default CesnjaPridelekTabela;
