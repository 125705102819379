import React, { useState, useEffect, useCallback } from "react";
import { Container, Card } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherGet from "../../fetcher/fetcherGet";
import JablanaKoristniTabela from "./JablanaKoristniTabela";
import JablanaKoristniForma from "./JablanaKoristniForma";

const JablanaKoristniOrganizmi = ({
  izbranoLeto,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [vsiKoristni, setVsiKoristni] = useState({});

  const fetchKoristniData = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/koristni",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setVsiKoristni
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchKoristniData(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Card style={{ backgroundColor: "BlanchedAlmond" }}>
      <Card.Body>
        <Container>
          <h3>Pregled podatkov o koristnih organizmih na jablani</h3>

          <JablanaKoristniTabela
            vsiKoristni={
              Object.keys(vsiKoristni).length > 0 &&
              vsiKoristni?.koristni?.filter((e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              })
            }
            fetchKoristniData={fetchKoristniData}
            setModalError={setModalError}
            setShowModal={setShowModal}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />

          <JablanaKoristniForma
            fetchKoristniData={fetchKoristniData}
            setModalError={setModalError}
            setShowModal={setShowModal}
          />

          <ModalError
            modalError={modalError}
            show={showModal}
            setShowModal={setShowModal}
          />
        </Container>
      </Card.Body>
    </Card>
  );
};

export default JablanaKoristniOrganizmi;
