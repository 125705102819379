
import React, { useState, useEffect,useCallback } from "react";
import { Map, Marker, ZoomControl, Overlay } from "pigeon-maps";
import 'bootstrap/dist/css/bootstrap.min.css';
import BASE_URL from "../../utils/baseUrl";
import useCommonContext from "../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../fetcher/fetcherGet";
import Papa from 'papaparse';
import fetcherPost from "../fetcher/fetcherPost";
import ModalError from "./../modals/ModalError";




const MapComponent = (props) => {
  
  const [locations, setLocations] = useState([]);
  const [visiblePopup, setVisiblePopup] = useState(null);
  const { nasadId, currGerk } = useCommonContext();


  const signOut = useSignOut();
  const history = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [csvData, setCsvData] = useState([]);

  // Default center for the map
  const defaultCenter = [46.5547, 15.6459]; // Center of Maribor


  function dmmToDd(dmm) {
    // Replace comma with a period for proper decimal handling
    const dmmFixed = dmm.replace(',', '.');
  
    // Split the input string into degrees and minutes parts
    const [degreePart, minutePart] = dmmFixed.split('.');
  
    // Convert parts to numbers
    const degrees1 = parseInt(degreePart, 10);
    const minutes1 = parseFloat('0.' + minutePart); // Handle decimal minutes part
  
    // Convert DMM to DD
    const dmmValue = degrees1 + minutes1 ; // The minutes part is already in decimal
    const degrees = Math.floor(dmmValue / 100);  // Extract degrees
    const minutes = dmmValue % 100;  // Extract minutes
    return degrees + (minutes / 60);  // Convert to decimal degrees
  
  }



const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (!file) return;

  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete:  function(results) {
      if (results.errors.length > 0) {
        // Handle parsing errors
        setShowModal(true);
        setModalError("Napaka pri obdelavi CSV datoteke: " + results.errors[0].message);
        event.target.value = '';
        setCsvData([]);
        return;
      }
      try {
        // Validate column names
        const requiredColumns = ['št. drevesa', 'GPS Latitude', 'GPS Longitude', 'Prešteto število cvetov', 'Ocena št. cvetov', 'Ocena št. Plodov', 'izboljš. št. plodov'];
        const csvColumns = Object.keys(results.data[0]);
        
        const missingColumns = requiredColumns.filter(col => !csvColumns.includes(col));
        if (missingColumns.length > 0) {
          setShowModal(true);
          setModalError(`CSV manjka naslednje stolpce: ${missingColumns.join(', ')}`);
          event.target.value = '';
          setCsvData([]);
          return;
        }
        const mappedData = results.data.map(row => {
          const stDrevesa = parseInt(row['št. drevesa'], 10);
          const gpsLatitude = dmmToDd(row['GPS Latitude']);
          const gpsLongitude = dmmToDd(row['GPS Longitude']);
          const prestetoStCvetov = parseInt(row['Prešteto število cvetov'], 10);
          const ocenjenoStCvetov = parseInt(row['Ocena št. cvetov'], 10);
          const ocenjenoStPlodov = parseInt(row['Ocena št. Plodov'], 10);
          const izboljšanoStPlodov = parseInt(row['izboljš. št. plodov'], 10);

          // Validate if the parsed data is correct
          if (isNaN(stDrevesa) || isNaN(gpsLatitude) || isNaN(gpsLongitude)) {
            throw new Error(`Napaka v formatu podatkov v vrstici: ${JSON.stringify(row)}`);
          }

          return {
            ST_Drevesa: stDrevesa,
            ID_Nasada: nasadId,
            gpsŠirina: gpsLatitude,
            gpsDolžina: gpsLongitude,
            prestetoStCvetov,
            ocenjenoŠtCvetov: ocenjenoStCvetov,
            ocenjenoŠtPlodov: ocenjenoStPlodov,
            izboljšanoŠtPlodov: izboljšanoStPlodov,
            vrsta: 'JABLANA'
          };
        });

      setCsvData(mappedData)
      console.log("csv: ", mappedData)
    } catch (error) {
      // Handle mapping errors
      setShowModal(true);
      setModalError("Napaka pri obdelavi CSV podatkov: " + error.message);
      event.target.value = '';
      setCsvData([]);
    }
  },
  error: function (error) {
    // Handle general errors during file parsing
    setShowModal(true);
    setModalError("Napaka pri nalaganju datoteke: " + error.message);
    event.target.value = '';
    setCsvData([]);
  }
});
};

const sendCsvDataToBackend = async (e) => {
  try {
    e.preventDefault();
    // Assuming your backend can accept an array of GPS data
    // await fetcherPost(
    //   `gpsLocation/postGpsPodatekDrevesa`, // Example endpoint for bulk insert
    //   csvData, // Send the entire CSV data array
    //   nasadId,
    //   setModalError,
    //   setShowModal,
    //   signOut,
    //   history
    // );


    const response = await fetch(`${BASE_URL}/gpsLocation/postGpsPodatekDrevesa/${nasadId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(csvData),
    });
    console.log("response: ", response);
    if (response.status === 401) {
      console.log("Ni avtentizoran upodabnik pri vstavljanju!");
      signOut();
    }

    if (response.status === 201) {

      console.log("Uspesno vstavljeni CSV podatki! ")
      fetchGpsLocations1();
    }

  } catch (error) {
    console.error('Error sending CSV data to backend:', error);
  }
};

  const fetchGpsLocations1 = useCallback(
    (signal) => {
      return fetcherGet(
        "gpsLocation/getAll",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setLocations
      );
    },
    [ history, signOut]
  )

  useEffect(() => {
    // fetchGpsLocations();
    const controller = new AbortController();
    fetchGpsLocations1(controller.signal);
  }, []);


  const insertGpsPodatkiDrevesa = async () => {
    const bledData = {
      ID_Nasada: nasadId, // Example ID, replace with appropriate value
      gpsŠirina: 46.3692, // Latitude for Bled, Slovenia
      gpsDolžina: 14.1136, // Longitude for Bled, Slovenia
      ocenjenoŠtCvetov: 100, // Example value, replace as needed
      ocenjenoŠtPlodov: 50,  // Example value, replace as needed
      izboljšanoŠtPlodov: 60, // Example value, replace as needed
    };
    try {
      const response = await fetch(`${BASE_URL}/gpsLocation/postGpsPodatekDrevesa`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bledData),
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Failed to insert GPS data");
      }
  
      const result = await response.json();
      console.log("Successfully inserted GPS data:", result);
    } catch (err) {
      console.error("Error inserting GPS data:", err);
    }
  };




  const handleMarkerClick = (index) => {
    console.log(locations)
    setVisiblePopup(visiblePopup === index ? null : index);
  };

  const handleClosePopup = () => {
    setVisiblePopup(null);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col">
          <Map
            className="map"
            height={"60vh"}
            defaultCenter={defaultCenter}
            defaultZoom={16}
            maxZoom={21}
            minZoom={4}
          >
            {locations.map((point, index) => (
              <Marker
                key={index}
                anchor={[parseFloat(point.gpsŠirina), parseFloat(point.gpsDolžina)]}
                width={40}
                color="green" // You can set different colors based on conditions if needed
                onClick={() => handleMarkerClick(index)}
              />
            ))}

            {locations.map((point, index) => (
              visiblePopup === index && (
                <Overlay key={index} anchor={[parseFloat(point.gpsŠirina), parseFloat(point.gpsDolžina)]} offset={[-20, 80]}>
                  <div className="card" style={{ width: "18rem", border: "1px solid #ddd" }}>
                    <div className="card-body">
                      <button
                        type="button"
                        className="btn-close float-end"
                        aria-label="Close"
                        onClick={handleClosePopup}
                      ></button>
                      <h5 className="card-title">Drevo št. {point.ST_Drevesa}</h5>
                      <p className="card-text">Prešteto število cvetov: {point.prestetoStCvetov == null ? "/" : point.prestetoStCvetov}</p>
                      <p className="card-text">Ocenjeno št. cvetov: {point.ocenjenoŠtCvetov != null? point.ocenjenoŠtCvetov : "/"}</p>
                      <p className="card-text">Ocenjeno št. plodov: {point.ocenjenoŠtPlodov != null? point.ocenjenoŠtPlodov : "/"}</p>
                      <p className="card-text">Izboljšano št. plodov: {point.izboljšanoŠtPlodov != null? point.izboljšanoŠtPlodov : "/"}</p>
                      {/* Add other details as needed */}
                    </div>
                  </div>
                </Overlay>
              )
            ))}

            <ZoomControl />
          </Map>
        </div>
      </div>
      <div className="row mt-4">
    <div className="col-md-12">
      <div className="p-3" style={{ backgroundColor: "#f7f7f7", borderRadius: "8px", boxShadow: "0 6px 8px rgba(0, 0, 0, 0.3)" }}>
        <div className="row">
          <div className="col-md-8">
            <label htmlFor="formFile" className="form-label">Tukaj naložite GPS lokacije dreves v nasadu:</label>
            <input disabled={props.disableUploading} className="form-control" type="file" id="formFile" accept=".csv" onChange={handleFileUpload} />

          </div>
          <div className="col-md-4 d-flex align-items-end">
            <button disabled={props.disableUploading} className="btn btn-primary ms-2" style={{ height: "fit-content" }} onClick={sendCsvDataToBackend}>Naloži</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
      {/* <button onClick={insertGpsPodatkiDrevesa}>Kliknime</button> */}
    </div>
  );
};

export default MapComponent;



