import { useCallback } from "react";
import fetcherGet from "../components/fetcher/fetcherGet"; // assuming fetcherGet is in the same directory
import useCommonContext from "./useCommonContext";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";


export const useFetchAktivniNasadi = (
  setModalError,
  setShowModal,
  signOut,
  history,
  setAktivniNasadi
) => {
  const { nasadId } = useCommonContext();
  const auth = useAuthUser();

  return useCallback(
    async (signal) => {
      return fetcherGet(
        "midNumber/aktivniNasadi",
        signal,
        auth.midStevilka,
        setModalError,
        setShowModal,
        signOut,
        history,
        setAktivniNasadi
      );
    },
    [history, signOut, auth.midStevilka]
  );
};
