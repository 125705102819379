import BASE_URL from "../../utils/baseUrl";

const fetcherGet = async (
  link,
  signal,
  nasadId,
  setModalError,
  setShowModal,
  signOut,
  history,
  setSplosniPodatki
) => {
  try {
    const reqLink =
      nasadId !== null
        ? `${BASE_URL}/${link}/${nasadId}`
        : `${BASE_URL}/${link}`;

    const res = await fetch(reqLink, {
      method: "GET",
      signal: signal,
      credentials: "include",
    });

    if (res.status === 401) {
      setModalError(`Napaka pri avtorizaciji uporabnika.`);
      setShowModal(true);

      signOut();
      history("/prijava");

      return;
    }

    const data = await res.json();
    if (res.status === 400) {
      setModalError(
        `Napaka pri pridobivanju splošnih podatkov o ${reqLink} na zalednem delu. ${data?.message}`
      );
      setShowModal(true);
      return;
    }
    if (res.status === 200) {
      setSplosniPodatki(data);
    }
  } catch (error) {
    if (error.name === "AbortError") return;

    setModalError(
      `Napaka pri pridobivanju splošnih podatkov o ${link} na podlagi Nasad-idja. ${error?.message}`
    );
    setShowModal(true);
  }
};

export default fetcherGet;
