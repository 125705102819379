import React, { useState, useEffect, useCallback } from "react";
import { Button, Card } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../../fetcher/fetcherGet";

const CesnjaSplosniPodatki = ({ setJePrikazSplosnihPodatkov }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId, currGerk } = useCommonContext();
  const [cesnjaSplosniPodatki, setCesnjaSplosniPodatki] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const fetchSplosniPodatkiCesnja = useCallback(
    (signal) => {
      return fetcherGet(
        "cesnja/gerkData",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setCesnjaSplosniPodatki
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchSplosniPodatkiCesnja(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>
            <h3 className="text-center mb-4">
              Splošni podatki o nasadu češnje: {currGerk}
            </h3>
          </Card.Title>
          <p>
            <b>Ime in priimek nosilca:</b> {cesnjaSplosniPodatki.imeNosilca}{" "}
            {cesnjaSplosniPodatki.priimekNosilca}
          </p>

          <p>
            <b>Število dreves na hektar:</b>
            {cesnjaSplosniPodatki.drevesaNaHektar}
          </p>

          <p>
            <b>Število dreves na površino:</b>
            {cesnjaSplosniPodatki.steviloDrevesNaPovrsino}
          </p>

          <p>
            <b>Število sadik:</b> {cesnjaSplosniPodatki.steviloSadik}
          </p>

          <p>
            <b>Srednja razdalja med vrstami:</b>{" "}
            {cesnjaSplosniPodatki.srednjaRazdaljaMedVrstami}m
          </p>

          <p>
            <b>Srednja razdalja v vrsti:</b>{" "}
            {cesnjaSplosniPodatki.srednjaRazdaljaVVrsti}m
          </p>

          <p>
            <b>Naziv gojitvene oblike:</b>{" "}
            {cesnjaSplosniPodatki.gojitvenaOblika}
          </p>

          <p>
            <b>Leto nasaditve:</b>
            {cesnjaSplosniPodatki.letoNasaditve}
          </p>
        
          <p>
            <b>Sorta:</b> {cesnjaSplosniPodatki.sorta}
          </p>
         
          <p>
            <b>Podlaga:</b> {cesnjaSplosniPodatki.podlaga}
          </p>
         
          <p className="text-center">
            <Button
              onClick={() => {
                setJePrikazSplosnihPodatkov(false);
              }}
            >
              Uredi podatke
            </Button>
          </p>
        </Card.Body>
      </Card>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default CesnjaSplosniPodatki;
