import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  InputGroup,
  Table,
  Card,
} from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import fetcherGet from "../../fetcher/fetcherGet";
import fetcherPost from "../../fetcher/fetcherPost";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";

const JablanaPridelekVnosPregled = ({
  izbranoLeto,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [kgNaDrevo, setKgNaDrevo] = useState(0);
  const [kgPrviRazred, setKgPrviRazred] = useState(0);

  const [kgDrugiRazred, setKgDrugiRazred] = useState(0);
  const [datum, setDatum] = useState(new Date().toISOString().slice(0, 10));

  const [allPridelekData, setAllPridelekData] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const fetchPridelekData = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/pridelek",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setAllPridelekData
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchPridelekData(controller.signal);

    
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    allPridelekData.pridelek &&
    setNiPodatkovZaIzbranoLeto(
      allPridelekData.pridelek.filter((e) => {
        return new Date(e.datum).getFullYear() === izbranoLeto;
      }).length > 0
        ? false
        : true
    );

  }, [allPridelekData])
  

  return (
    <Card style={{ backgroundColor: "Coral" }}>
      <Card.Body>
        <Container>
          <Row>
            <h3>Pregled obstoječih podatkov o pridelku</h3>

            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>kg/drevo</th>
                  <th>1. razred (kg)</th>
                  <th>2. razred (kg)</th>
                  <th>Datum</th>
                  {allPridelekData.pridelek &&
                  new Date(
                    allPridelekData.pridelek?.filter((e) => {
                      return new Date(e.datum).getFullYear() === izbranoLeto;
                    })[0]?.datum
                  ).getFullYear() === new Date().getFullYear() ? (
                    <th>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {allPridelekData.pridelek &&
                  allPridelekData.pridelek
                    ?.filter((e) => {
                      return new Date(e.datum).getFullYear() === izbranoLeto;
                    })
                    ?.map((pridelek) => (
                      <DeletionRow
                        id={pridelek.id}
                        key={pridelek.id}
                        link={"jablana/pridelek"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchPridelekData}
                        briseSe={"PRIDELEK"}
                        jeTrenutnoLeto={
                          new Date(pridelek.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                        children={
                          <>
                            <td>
                              <EditableCell
                                initialValue={pridelek.kgNaDrevo}
                                inputType={"number"}
                                id={pridelek.id}
                                link={"jablana/pridelek"}
                                imeLastnosti={"kgNaDrevo"}
                                setModalError={setModalError}
                                setShowModal={setShowModal}
                                fetchNewData={fetchPridelekData}
                                max={Number.MAX_SAFE_INTEGER}
                                min={0}
                                step={0.1}
                                spreminjaSe={"PRIDELEK_KG_NA_DREVO"}
                                jeTrenutnoLeto={
                                  new Date(pridelek.datum).getFullYear() ===
                                  new Date().getFullYear()
                                }
                              />
                            </td>
                            <td>
                              <EditableCell
                                initialValue={pridelek.kgPrviRazred}
                                inputType={"number"}
                                id={pridelek.id}
                                link={"jablana/pridelek"}
                                imeLastnosti={"kgPrviRazred"}
                                setModalError={setModalError}
                                setShowModal={setShowModal}
                                fetchNewData={fetchPridelekData}
                                max={Number.MAX_SAFE_INTEGER}
                                min={0}
                                step={0.1}
                                spreminjaSe={"PRIDELEK_KG_PRVI_RAZRED"}
                                jeTrenutnoLeto={
                                  new Date(pridelek.datum).getFullYear() ===
                                  new Date().getFullYear()
                                }
                              />
                            </td>
                            <td>
                              <EditableCell
                                initialValue={pridelek.kgDrugiRazred}
                                inputType={"number"}
                                id={pridelek.id}
                                link={"jablana/pridelek"}
                                imeLastnosti={"kgDrugiRazred"}
                                setModalError={setModalError}
                                setShowModal={setShowModal}
                                fetchNewData={fetchPridelekData}
                                max={Number.MAX_SAFE_INTEGER}
                                min={0}
                                step={0.1}
                                spreminjaSe={"PRIDELEK_KG_DRUGI_RAZRED"}
                                jeTrenutnoLeto={
                                  new Date(pridelek.datum).getFullYear() ===
                                  new Date().getFullYear()
                                }
                              />
                            </td>
                            <td>
                              <EditableCell
                                initialValue={pridelek.datum}
                                inputType={"date"}
                                id={pridelek.id}
                                link={"jablana/pridelek"}
                                imeLastnosti={"datum"}
                                setModalError={setModalError}
                                setShowModal={setShowModal}
                                fetchNewData={fetchPridelekData}
                                spreminjaSe={"PRIDELEK_DATUM"}
                                jeTrenutnoLeto={
                                  new Date(pridelek.datum).getFullYear() ===
                                  new Date().getFullYear()
                                }
                              />
                            </td>
                          </>
                        }
                      />
                    ))}
              </tbody>
            </Table>
          </Row>
          <Row>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await fetcherPost(
                  `jablana/pridelek`,
                  {
                    kgNaDrevo: kgNaDrevo,
                    kgPrviRazred: kgPrviRazred,
                    kgDrugiRazred: kgDrugiRazred,
                    datum: datum,
                  },
                  nasadId,
                  setModalError,
                  setShowModal,
                  signOut,
                  history
                );

                setKgNaDrevo(0);
                setKgPrviRazred(0);

                setKgDrugiRazred(0);
                setDatum(new Date().toISOString().slice(0, 10));
                fetchPridelekData();
              }}
            >
              <Form.Label className="m-2" htmlFor="pridelek">
                <h4>Vnos podatkov o pridelku</h4>
              </Form.Label>
              <FormGroup className="m-2">
                <InputGroup>
                  <InputGroup.Text htmlFor="kgNaDrevo">
                    Kg/drevo:
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="kgNaDrevo"
                    value={kgNaDrevo}
                    required
                    min={0}
                    step={0.1}
                    onChange={(e) => setKgNaDrevo(e.target.value)}
                  />
                  <InputGroup.Text htmlFor="kgPrviRazred">
                    Masa 1. razreda (kg)
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="kgPrviRazred"
                    value={kgPrviRazred}
                    required
                    min={0}
                    step={0.1}
                    onChange={(e) => setKgPrviRazred(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="m-2">
                <InputGroup>
                  <InputGroup.Text htmlFor="kgDrugiRazred">
                    Masa 2. razreda (kg)
                  </InputGroup.Text>
                  <Form.Control
                    name="kgDrugiRazred"
                    type="number"
                    min={0}
                    step={0.1}
                    required
                    onChange={(e) => setKgDrugiRazred(e.target.value)}
                    value={kgDrugiRazred}
                  ></Form.Control>

                  <InputGroup.Text id="datumOcene">Datum:</InputGroup.Text>
                  <Form.Control
                    type="date"
                    value={datum}
                    onChange={(e) => setDatum(e.target.value)}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    placeholder="Datum pridelka"
                    aria-label="Datum pridelka"
                    aria-describedby="Datum pridelka"
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="m-2">
                <Container>
                  <Row className="g-3">
                    <Col className="d-flex align-items-center justify-content-center">
                      <Button type="submit" variant="success">
                        <span className="btn-label">
                          <i className="bi bi-plus-circle m-1"></i>
                          Shrani
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </FormGroup>
            </Form>
          </Row>

          <ModalError
            modalError={modalError}
            show={showModal}
            setShowModal={setShowModal}
          />
        </Container>
      </Card.Body>
    </Card>
  );
};

export default JablanaPridelekVnosPregled;
