import React, { useState } from "react";
import {
  Container,
  Row,
  Spinner,
  Card,
  Col,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import BASE_URL from "../../utils/baseUrl";
import ModalError from "../modals/ModalError";
import { useNavigate } from "react-router-dom";

const Registracija = () => {
  const INVALID_TEXT_DEFAULT = "MID številka ni pravilne oblike";
  const INVALID_TEXT_MID_ZASEDEN = "MID številka je že vezana na račun";

  const INVALID_TEXT_MID_NE_OBSTAJA =
    "MID številka ni v registru in ne obstaja";

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [ime, setIme] = useState("");
  const [priimek, setPriimek] = useState("");

  const [uporabniskoIme, setUporabniskoIme] = useState("");

  const [geslo, setGeslo] = useState("");
  const [gesloDrugic, setGesloDrugic] = useState("");

  const [isPasswordValid, setIsPasswordValid] = useState(null);
  const [midStevilka, setMidStevilka] = useState(null);

  const [ulica, setUlica] = useState("");
  const [hisnaStevilka, setHisnaStevilka] = useState("");

  const [postnaStevilka, setPostnaStevilka] = useState(0);
  const [isZipCodeValid, setIsZipCodeValid] = useState(null);

  const [kraj, setKraj] = useState("");
  const [isMidNumberFormatValid, setIsMidNumberFormatValid] = useState(null);

  const [doesMidNumExists, setDoesMidNumExists] = useState(false);
  const [invalidText, setInvalidText] = useState(INVALID_TEXT_DEFAULT);

  const history = useNavigate();

  const handlePasswordChange = (e, type) => {
    if (type) {
      setGeslo(e.target.value);
      if (e.target.value.length > 4 && gesloDrugic.length > 4)
        setIsPasswordValid(e.target.value === gesloDrugic);
      else if (isPasswordValid !== null) setIsPasswordValid(false);
    } else {
      setGesloDrugic(e.target.value);
      if (geslo.length > 4 && e.target.value.length > 4)
        setIsPasswordValid(geslo === e.target.value);
      else if (isPasswordValid !== null) setIsPasswordValid(false);
    }
  };

  const handleRegistrationSumbmition = async (e) => {
    e.preventDefault();
    if (geslo !== gesloDrugic) {
      setModalError("Vneseni gesli nista enaki!");
      setShowModal(true);
      return;
    }

    if (!isZipCodeValid) {
      setModalError("Vnesena poštna številka ni veljavna!");
      setShowModal(true);
      return;
    }

    if (!isMidNumberFormatValid) {
      setModalError("Vnesena MID številka ni veljavna!");
      setShowModal(true);
      return;
    }

    if (!doesMidNumExists) {
      setModalError(
        "Vnesena MID številka je zasedena oz. ni v registru vseh številk!"
      );
      setShowModal(true);
      return;
    }

    try {
      const response = await fetch(BASE_URL + `/uporabnik/registracija`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ime: ime,
          priimek: priimek,
          uporabniskoIme: uporabniskoIme,
          geslo: geslo,
          midStevilka: midStevilka,
          ulica: ulica,
          hisnaStevilka: hisnaStevilka,
          postnaStevilka: postnaStevilka,
          kraj: kraj,
        }),
      });

      const data = await response.json();

      if (response.status === 400) {
        setModalError(data?.message);
        setShowModal(true);
      }

      if (data.registerSuccessful === true && response.status === 201) {
        history("/prijava");
      }
    } catch (error) {
      setModalError(
        `Neznana napaka pri registraciji uporabnika! ${error?.message}`
      );
      setShowModal(true);
    }
  };

  const handlePostnaStevilkaChange = (postnaStevilkaStr) => {
    if (postnaStevilkaStr.length < 4 && isZipCodeValid === null) return;

    const postnaStevilka = parseInt(postnaStevilkaStr);

    if (
      postnaStevilka < 1000 ||
      (postnaStevilka > 1434 && postnaStevilka < 2000) ||
      (postnaStevilka > 2394 && postnaStevilka < 3000) ||
      (postnaStevilka > 3342 && postnaStevilka < 4000) ||
      (postnaStevilka > 4294 && postnaStevilka < 5000) ||
      (postnaStevilka > 5297 && postnaStevilka < 6000) ||
      (postnaStevilka > 6333 && postnaStevilka < 8000) ||
      (postnaStevilka > 8362 && postnaStevilka < 9000) ||
      postnaStevilka > 9265 ||
      postnaStevilkaStr.length === 0
    ) {
      setIsZipCodeValid(false);
      return;
    }

    setPostnaStevilka(postnaStevilka);
    setIsZipCodeValid(true);
  };

  const checkIfMidNumberExists = async (midStevilka) => {
    try {
      const response = await fetch(BASE_URL + `/midNumber/${midStevilka}`);
      const data = await response.json();

      if (response.status === 400) {
        setDoesMidNumExists(false);
        setIsMidNumberFormatValid(false);

        setInvalidText(INVALID_TEXT_MID_NE_OBSTAJA);
        console.log(`napaka: ${data?.message}`);
        return;
      }

      if (response.status === 200 && !data.isDataLinkedToUser) {
        setDoesMidNumExists(true);
        return;
      }

      setDoesMidNumExists(false);
      setIsMidNumberFormatValid(false);
      setInvalidText(INVALID_TEXT_MID_ZASEDEN);
    } catch (error) {
      setModalError(
        `Neznana napaka pri preverjanju obstoja MID številke! ${error?.message}`
      );
      setShowModal(true);
      setDoesMidNumExists(false);
    }
  };

  return (
    <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Col md={8} lg={10} xs={12}>
          <Card className="px-4">
            <Card.Body>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-center text-uppercase ">
                  Digisad registracija
                </h2>
                <div className="mb-3">
                  <Form onSubmit={handleRegistrationSumbmition}>
                    <Container>
                      <Row>
                        <Col lg>
                          <Form.Group className="mb-3" controlId="ime">
                            <Form.Label className="text-center">Ime</Form.Label>
                            <Form.Control
                              type="text"
                              minLength={3}
                              onChange={(e) => setIme(e.target.value)}
                              placeholder="Vnesite vašo ime"
                            />
                          </Form.Group>
                        </Col>

                        <Col lg>
                          <Form.Group className="mb-3" controlId="priimek">
                            <Form.Label className="text-center">
                              Priimek
                            </Form.Label>
                            <Form.Control
                              type="text"
                              onChange={(e) => setPriimek(e.target.value)}
                              minLength={3}
                              required
                              placeholder="Vnesite vaš priimek"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Form.Group className="mb-3" controlId="gesloPrvic">
                          <Form.Label>Uporabniško ime</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Izberite si uporabniško ime za račun, minimalno 5 znakov"
                            minLength={5}
                            required
                            onChange={(e) => {
                              setUporabniskoIme(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Col lg>
                          <Form.Group className="mb-3" controlId="gesloPrvic">
                            <Form.Label>Geslo</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Izberite si geslo za račun, minimalno 5 znakov"
                              minLength={5}
                              required
                              onChange={(e) => {
                                handlePasswordChange(e, true);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg>
                          <Form.Group className="mb-3" controlId="gesloDrugic">
                            <Form.Label>Potrdite geslo</Form.Label>

                            <InputGroup hasValidation>
                              <Form.Control
                                type="password"
                                required
                                minLength={5}
                                onChange={(e) => {
                                  handlePasswordChange(e, false);
                                }}
                                placeholder="Ponovno vnesite geslo"
                                isValid={isPasswordValid}
                                isInvalid={
                                  !isPasswordValid && isPasswordValid !== null
                                }
                              />
                              <Form.Control.Feedback type="valid">
                                Vneseni gesli se ujemata
                              </Form.Control.Feedback>

                              <Form.Control.Feedback type="invalid">
                                Vneseni gesli se ne ujemata
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Form.Group className="mb-3" controlId="gesloPrvic">
                          <Form.Label>MID številka</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="number"
                              placeholder="Vnesite vašo MID številko"
                              required
                              min={100000000}
                              max={999999999}
                              isValid={
                                isMidNumberFormatValid && doesMidNumExists
                              }
                              isInvalid={
                                !isMidNumberFormatValid &&
                                isMidNumberFormatValid !== null
                              }
                              onChange={async (e) => {
                                setDoesMidNumExists(false);
                                setInvalidText(INVALID_TEXT_DEFAULT);

                                if (
                                  parseInt(e.target.value) >= 100000000 &&
                                  parseInt(e.target.value) <= 999999999
                                ) {
                                  setIsMidNumberFormatValid(true);
                                  setMidStevilka(parseInt(e.target.value));

                                  await checkIfMidNumberExists(e.target.value);
                                } else {
                                  setIsMidNumberFormatValid(false);
                                }
                              }}
                            />

                            <Form.Control.Feedback type="valid">
                              MID številka je na voljo in obstaja v registru.
                            </Form.Control.Feedback>

                            <Form.Control.Feedback type="invalid">
                              {invalidText}
                            </Form.Control.Feedback>
                          </InputGroup>
                          <div
                            className="mb-0"
                            hidden={
                              !(isMidNumberFormatValid && !doesMidNumExists)
                            }
                          >
                            <b>Preverjanje veljavnosti MID številke</b>
                            <Spinner animation="border" role="status" size="sm">
                              <span className="visually-hidden">
                                Preverjanje veljavnosti MID številke
                              </span>
                            </Spinner>
                          </div>
                        </Form.Group>
                      </Row>

                      <Row>
                        <Col xl={5}>
                          <Form.Group className="mb-3" controlId="ulica">
                            <Form.Label>Ulica</Form.Label>

                            <Form.Control
                              type="text"
                              required
                              minLength={5}
                              onChange={(e) => {
                                setUlica(e.target.value);
                              }}
                              placeholder="Vnesite vašo ulico"
                            />
                          </Form.Group>
                        </Col>
                        <Col xl={3}>
                          <Form.Group
                            className="mb-3"
                            controlId="hisnaStevilka"
                          >
                            <Form.Label>Hišna številka</Form.Label>

                            <Form.Control
                              type="text"
                              required
                              minLength={1}
                              onChange={(e) => {
                                setHisnaStevilka(e.target.value);
                              }}
                              placeholder="Vnesite vašo hišno številko"
                            />
                          </Form.Group>
                        </Col>

                        <Col xl={4}>
                          <Form.Group
                            className="mb-3"
                            controlId="hisnaStevilka"
                          >
                            <Form.Label>Poštna številka</Form.Label>

                            <InputGroup>
                              <Form.Control
                                type="number"
                                required
                                minLength={4}
                                onChange={(e) => {
                                  handlePostnaStevilkaChange(e.target.value);
                                }}
                                isValid={isZipCodeValid}
                                isInvalid={
                                  !isZipCodeValid && isZipCodeValid !== null
                                }
                                placeholder="Vnesite vašo poštno številko"
                              />

                              <Form.Control.Feedback type="valid">
                                Poštna številka je veljavna
                              </Form.Control.Feedback>

                              <Form.Control.Feedback type="invalid">
                                Poštna številka ni veljavna
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Form.Group className="mb-3" controlId="gesloPrvic">
                          <Form.Label>Kraj/naselje</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Vnesite kraj oz. naselje"
                            minLength={3}
                            required
                            onChange={(e) => {
                              setKraj(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <div className="d-grid">
                          <Button variant="primary" disabled={doesMidNumExists === false} type="submit">
                            Ustvari račun
                          </Button>
                        </div>
                      </Row>
                    </Container>
                  </Form>
                  <div className="mt-3">
                    <p className="mb-0  text-center">
                      Ali že imate račun?
                      <a href="/prijava" className="text-primary fw-bold">
                        Prijava
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="mb-0  text-center">
              Proces registracije lahko traja do 120 sekund.
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </Container>
  );
};

export default Registracija;
