import React, { useState } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import ModalError from "./modals/ModalError";
import BASE_URL from "../utils/baseUrl";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../hooks/useCommonContext";

const MyNavbarComponent = () => {
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();

  const auth = useAuthUser();
  const history = useNavigate();

  const { setNasadId, setCurrGerk } = useCommonContext();

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleSingOutUserButtonClick = async () => {
    try {
      console.log(`žeton pri odjavi: ${JSON.stringify(auth)}`);

      const response = await fetch(
        BASE_URL + `/uporabnik/${auth.uporabniskoIme}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.status === 401) {
        setModalError(
          `Napaka pri odjavi uporabnika! Uporabnik ni avtoriziran!`
        );
        setShowModal(true);

        signOut();
        return;
      }

      const data = await response.json();

      setNasadId(-1);
      setCurrGerk(-1);

      if (response.status === 200 && data.logOutSuccessful) {
        signOut();
        history("/prijava");

        return;
      }

      setModalError(
        `Neznana napaka pri odjavi uporabnika na strežniški strani!`
      );
      setShowModal(true);

      signOut();
      history("/prijava");
    } catch (error) {
      setModalError(
        `Neznana napaka pri odjavi uporabnika na odjamelčevi strani! ${error?.message}`
      );
      setShowModal(true);
      history("/prijava");
    }
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/domacaStran">Digisad</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {isAuthenticated() ? (
                <>
                  <Nav.Link href="/nastavitve">Nastavitve</Nav.Link>

                  <NavDropdown title="Vnosi" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/dnevnikSkropljenj">
                      Vnos škropljenja za jablano
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/vnosGnojenjaJablana">
                      Vnos gnojenja za jablano
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/vnosNamakanjeJablana">
                      Vnos namakanja za jablano
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/vnosFertirigacijeJablana">
                      Vnos fertirigacije za jablano
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/vnosBiodiveritetaJablana">
                      Vnos ohranjanje biodiverzitete za jablano
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/vnosMehanskiUkrepiJablana">
                      Vnos mehanskih ukrepov za jablano
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <>
                  <Nav.Link href="/registracija">Registracija</Nav.Link>
                  <Nav.Link href="/prijava">Prijava</Nav.Link>
                </>
              )}
            </Nav>
            {isAuthenticated() ? (
              <Nav>
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                    Prijavljeni ste kot <b>{auth.uporabniskoIme}!</b>
                  </Navbar.Text>
                  <Nav.Link onClick={handleSingOutUserButtonClick}>
                    Odjava
                  </Nav.Link>
                </Navbar.Collapse>
              </Nav>
            ) : (
              <></>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default MyNavbarComponent;
