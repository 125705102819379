const imenaEnoteTerminiSkodljivcev = {
  ŠKRLUP: [
    { enota: "okužba / 100 listov", termin: "maj - junij" },
    { enota: "okužba / 100 plodov", termin: "maj - junij" },
  ],
  PLESEN: [{ enota: "okužba / 100 poganjkov", termin: "junij - julij" }],
  CVETOŽER: [
    { enota: "št. hroščkov / 100 vej (metoda stresanja)", termin: "april" },
    { enota: "št. poškodovanih brstov / 100 cvetnih brstov", termin: "april" },
  ],
  "PRŠICE PRELKE": [
    { enota: "število jajčec / m", termin: "april" },
    { enota: "povp. št. pršic / list", termin: "maj - avgust" },
  ],
  "MOKASTA JABLANOVA UŠ": [
    { enota: "št. kolonij / 100 poganjkov", termin: "maj - julij" },
  ],
  "ZELENA JABLANOVA UŠ": [
    { enota: "št. kolonij / 100 poganjkov", termin: "julij - avgust" },
  ],
  "UŠ ŠIŠKARICA": [{ enota: "% napadenih poganjkov", termin: "april - junij" }],
  "KRVAVA UŠ": [{ enota: "okužba / 100 dreves", termin: "junij - avgust" }],
  "JABOLČNA GRIZLICA": [{ enota: "število osic / vabo", termin: "maj" }],
  "JABOLČNI ZAVIJAČ": [
    { enota: "št. črvivih plodov / 100 plodov", termin: "julij - avgust" },
  ],
};

export default imenaEnoteTerminiSkodljivcev;
