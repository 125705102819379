import React, { useState, useEffect } from "react";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import EditableCell from "../../EditableCell";
import fetcherPost from "../../fetcher/fetcherPost";
import DeletionRow from "../../DeletionRow";

const JablanaDatumTstadija = ({
  spremljanjeRazvojaPlodicev,
  setModalError,
  setShowModal,
  fetchRazvojPlodicevData,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [datumTStadija, setDatumTStadija] = useState(
    new Date().toISOString().slice(0, 10)
  );

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(
      spremljanjeRazvojaPlodicev?.length > 0 ? false : true
    );
  }, [spremljanjeRazvojaPlodicev]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "DarkSeaGreen" }}>
        <Card.Header as="h5">Podatki o razvoju plodičev</Card.Header>

        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "DarkSeaGreen" }}>
                    Datum T-stadija
                  </th>

                  {spremljanjeRazvojaPlodicev[0]?.datumTStadija &&
                  new Date(
                    spremljanjeRazvojaPlodicev[0]?.datumTStadija
                  ).getFullYear() === new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "DarkSeaGreen" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {spremljanjeRazvojaPlodicev &&
                  spremljanjeRazvojaPlodicev.map((item) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"jablana/razvojPlodicev"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchRazvojPlodicevData}
                      briseSe={"DATUM_T_STADIJA"}
                      jeTrenutnoLeto={
                        new Date(item.datumTStadija).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={item.datumTStadija}
                              inputType={"date"}
                              id={item.id}
                              link={"jablana/razvojPlodicev"}
                              imeLastnosti={"datumTStadija"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchRazvojPlodicevData}
                              spreminjaSe={"DATUM_T_STADIJA"}
                              jeTrenutnoLeto={
                                new Date(item.datumTStadija).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              e.preventDefault();
              e.target.reset();

              await fetcherPost(
                `jablana/razvojPlodicev`,
                {
                  datumTStadija: datumTStadija,
                  tip: "spremljanjeRazvojaPlodicev",
                },
                nasadId,
                setModalError,
                setShowModal,
                signOut,
                history
              );

              setDatumTStadija(new Date().toISOString().slice(0, 10));
              fetchRazvojPlodicevData();
            }}
          >
            <FormGroup className="m-2">
              <Form.Label htmlFor="datumTStadija">Datum T-stadija:</Form.Label>
              <Form.Control
                id="datumTStadija"
                type="date"
                value={datumTStadija}
                min={`${new Date().getFullYear()}-01-01`}
                onChange={(e) => setDatumTStadija(e.target.value)}
                placeholder="Vnesite datum T-stadija"
              />
              <Form.Text className="text-muted">
                Izberite datum, ko drevesa pridejo v T-stadij
              </Form.Text>
            </FormGroup>
            <FormGroup className="m-2">
              <Container>
                <Row className="g-3">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Shrani datum T-stadija
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default JablanaDatumTstadija;
