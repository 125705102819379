import React from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Table,
  Card,
} from "react-bootstrap";

const SaveButton = ({ disabledCondition }) => {
  return (
    <FormGroup>
      <Container>
        <Row className="g-3">
          <Col className="d-flex align-items-center justify-content-center">
            <Button
              type="submit"
              variant="success"
              disabled={disabledCondition}
            >
              <span className="btn-label">
                <i className="bi bi-plus-circle m-1"></i>
                Shrani
              </span>
            </Button>
          </Col>
        </Row>
      </Container>
    </FormGroup>
  );
};

export default SaveButton;
