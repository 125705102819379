import React, { useEffect, useState } from "react";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import nastanekCvetnihBrstovOceneBesedilo from "../../../ocene/nastanekCvetnihBrstovOcene";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import EditableCell from "../../EditableCell";
import fetcherPost from "../../fetcher/fetcherPost";
import DeletionRow from "../../DeletionRow";

const JablanaNastanekCvetnihBrstov = ({
  ocenaNastankaCvetnihBrstov,
  setModalError,
  setShowModal,
  fetchBloomingData,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [
    izbranaOcenaNastankaCvetnihBrstov,
    setIzbranaOcenaNastankaCvetnihBrstov,
  ] = useState(0);

  const [
    izbranaOcenaNastankaCvetnihBrstovDatum,
    setIzbranaOcenaNastankaCvetnihBrstovDatum,
  ] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(
      ocenaNastankaCvetnihBrstov?.length > 0 ? false : true
    );
  }, [ocenaNastankaCvetnihBrstov]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "orange" }}>
        <Card.Header as="h5">Ocena nastavka cvetnih brstov</Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "orange" }}>
                    Datum ocenjevanja
                  </th>
                  <th style={{ backgroundColor: "orange" }}>Ocena</th>

                  {ocenaNastankaCvetnihBrstov[0]?.datum &&
                  new Date(
                    ocenaNastankaCvetnihBrstov[0]?.datum
                  ).getFullYear() === new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "orange" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>

              <tbody>
                {ocenaNastankaCvetnihBrstov &&
                  ocenaNastankaCvetnihBrstov.map((item) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"jablana/cvetenje"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchBloomingData}
                      briseSe={"NASTANEK_CVETNIH_BRSTOV"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={item.datum}
                              inputType={"date"}
                              id={item.id}
                              link={"jablana/cvetenje"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchBloomingData}
                              spreminjaSe={"CVETENJE_CVETNI_BRSTI_DATUM"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>

                          <td>
                            <EditableCell
                              initialValue={item.ocena}
                              inputType={"number"}
                              id={item.id}
                              link={"jablana/cvetenje"}
                              imeLastnosti={"ocena"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchBloomingData}
                              min={1}
                              max={5}
                              step={1}
                              spreminjaSe={"CVETENJE_CVETNI_BRSTI_OCENA"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Card className="m-1" style={{ backgroundColor: "orange" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();

                  await fetcherPost(
                    `jablana/cvetenje`,
                    {
                      ocena: izbranaOcenaNastankaCvetnihBrstov,
                      datum: izbranaOcenaNastankaCvetnihBrstovDatum,
                      tipOcene: "ocenaNastankaCvetnihBrstov",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setIzbranaOcenaNastankaCvetnihBrstov(0);
                  setIzbranaOcenaNastankaCvetnihBrstovDatum(
                    new Date().toISOString().slice(0, 10)
                  );
                  fetchBloomingData();
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="nastanekCvetnihBrstov">
                    Nastavek cvetnih brstov:
                  </Form.Label>
                  <Form.Select
                    name="nastanekCvetnihBrstov"
                    aria-label="Ocena nastavka cvetnih brstov"
                    required
                    onChange={(e) =>
                      setIzbranaOcenaNastankaCvetnihBrstov(e.target.value)
                    }
                    value={izbranaOcenaNastankaCvetnihBrstov}
                  >
                    <option hidden>
                      Izberite oceno nastavka cvetnih brstov
                    </option>
                    {nastanekCvetnihBrstovOceneBesedilo.map((ocena, index) => (
                      <option key={Math.random()} value={index + 1}>
                        {ocena}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Text className="text-muted">
                    Izberite oceno nastavka cvetnih brstov od 1 do 5
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="nastanekCvetnihBrstovDatum">
                    Datum:
                  </Form.Label>
                  <Form.Control
                    id="nastanekCvetnihBrstovDatum"
                    type="date"
                    min={`${new Date().getFullYear()}-01-01`}
                    value={izbranaOcenaNastankaCvetnihBrstovDatum}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) =>
                      setIzbranaOcenaNastankaCvetnihBrstovDatum(e.target.value)
                    }
                    placeholder="Vnesite datum ocenjevanja nastavka cvetnih brstov"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum ocenjevanja nastavka cvetnih brstov
                  </Form.Text>
                </FormGroup>
                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani oceno nastavka cvetnih brstov
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default JablanaNastanekCvetnihBrstov;
