import React, { useState, useEffect, useCallback } from "react";
import { Container, Card, Table } from "react-bootstrap";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherGet from "../../fetcher/fetcherGet";
import DeletionRow from "../../DeletionRow";

const JablanaNapovedPridelka = ({
  izbranoLeto,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [vsaNapovedPridelka, setVseNapovediPridelka] = useState({
    napovedPridelka: [],
  });

  const fetchNapovedPridelka = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/napovedPridelka",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setVseNapovediPridelka
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchNapovedPridelka(controller.signal);

    setNiPodatkovZaIzbranoLeto(
      vsaNapovedPridelka.napovedPridelka &&
        Object.keys(vsaNapovedPridelka.napovedPridelka).length > 0
        ? false
        : true
    );
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Card style={{ backgroundColor: "AntiqueWhite " }}>
      <Card.Body>
        <Container>
          <h3>Pregled napovedi pridelka jablane</h3>

          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "AntiqueWhite " }}>
                    Datum napovedi
                  </th>
                  <th style={{ backgroundColor: "AntiqueWhite " }}>
                    Število jabolk na sliko
                  </th>
                  <th style={{ backgroundColor: "AntiqueWhite " }}>
                    Napoved pridelka na hektar (kg/Ha)
                  </th>
                  <th style={{ backgroundColor: "AntiqueWhite " }}>
                    Napoved pridelka na drevo (kg/drevo)
                  </th>
                  <th style={{ backgroundColor: "AntiqueWhite " }}>
                   Napoved pridelka na sadovnjak (kg)
                  </th>
                  {vsaNapovedPridelka?.napovedPridelka[0]?.createdAt &&
                  new Date(
                    vsaNapovedPridelka?.napovedPridelka[0]?.createdAt
                  ).getFullYear() === new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "AntiqueWhite" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {Object.keys(vsaNapovedPridelka).length > 0 &&
                  vsaNapovedPridelka?.napovedPridelka
                    ?.filter((e) => {
                      return (
                        new Date(e.createdAt).getFullYear() === izbranoLeto
                      );
                    })
                    .map((item) => (
                      <DeletionRow
                        id={item.id}
                        key={item.id}
                        link={"jablana/napovedPridelka"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchNapovedPridelka}
                        briseSe={"CILJNI_PRIDELEK"}
                        jeTrenutnoLeto={
                          new Date(item.createdAt).getFullYear() ===
                          new Date().getFullYear()
                        }
                        children={
                          <>
                            <td>
                              {
                                new Date(item.createdAt)
                                  .toLocaleString("sl-Sl")
                                  .split(",")[0]
                              }
                            </td>
                            <td>{item.stJabolkNaSliko}</td>
                            <td>{item.napovedPridelkaNaHektar}</td>
                            <td>{item.napovedPridelkaNaDrevo}</td>
                            <td>{item.skupnaNapovedPridelka}</td>
                          </>
                        }
                      />
                    ))}
              </tbody>
            </Table>
          </div>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default JablanaNapovedPridelka;
