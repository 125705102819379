import React, { useEffect, useState } from "react";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import ocenaRodnegaNastavkaBesedilo from "../../../ocene/ocenaRodnegaNastavkaBesedilo";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import EditableCell from "../../EditableCell";
import fetcherPost from "../../fetcher/fetcherPost";
import DeletionRow from "../../DeletionRow";

const JablanaRodniNastavek = ({
  ocenaRodnegaNastavka,
  setModalError,
  setShowModal,
  fetchBloomingData,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [izbranaOcenaRodnegaNastavka, setIzbranaOcenaRodnegaNastavka] =
    useState(0);

  const [
    izbranaOcenaRodnegaNastavkaDatum,
    setIzbranaOcenaRodnegaNastavkaDatum,
  ] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(ocenaRodnegaNastavka?.length > 0 ? false : true);
  }, [ocenaRodnegaNastavka]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "Bisque" }}>
        <Card.Header as="h5">Ocena rodnega nastavka</Card.Header>

        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "Bisque" }}>
                    Datum ocenjevanja
                  </th>
                  <th style={{ backgroundColor: "Bisque" }}>Ocena</th>
                  {ocenaRodnegaNastavka[0]?.datum &&
                  new Date(ocenaRodnegaNastavka[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "Bisque" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>

              <tbody>
                {ocenaRodnegaNastavka &&
                  ocenaRodnegaNastavka.map((item) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"jablana/cvetenje"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchBloomingData}
                      briseSe={"RODNI_NASTAVEK"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={item.datum}
                              inputType={"date"}
                              id={item.id}
                              link={"jablana/cvetenje"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchBloomingData}
                              spreminjaSe={"CVETENJE_RODNI_NASTAVEK_DATUM"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>

                          <td>
                            <EditableCell
                              initialValue={item.ocena}
                              inputType={"number"}
                              id={item.id}
                              link={"jablana/cvetenje"}
                              imeLastnosti={"ocena"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchBloomingData}
                              min={1}
                              max={5}
                              step={1}
                              spreminjaSe={"CVETENJE_RODNI_NASTAVEK_OCENA"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              e.target.reset();

              await fetcherPost(
                `jablana/cvetenje`,
                {
                  ocena: izbranaOcenaRodnegaNastavka,
                  datum: izbranaOcenaRodnegaNastavkaDatum,
                  tipOcene: "ocenaRodnegaNastavka",
                },
                nasadId,
                setModalError,
                setShowModal,
                signOut,
                history
              );

              setIzbranaOcenaRodnegaNastavka(0);
              setIzbranaOcenaRodnegaNastavkaDatum(
                new Date().toISOString().slice(0, 10)
              );
              fetchBloomingData();
            }}
          >
            <FormGroup className="m-2">
              <Form.Label htmlFor="ocenaRodnegaNastavka">
                Ocena rodnega nastavka:
              </Form.Label>
              <Form.Select
                aria-label="Ocena rodnega nastavka"
                name="ocenaRodnegaNastavka"
                required
                onChange={(e) => setIzbranaOcenaRodnegaNastavka(e.target.value)}
                value={izbranaOcenaRodnegaNastavka}
              >
                <option hidden>Izberite oceno rodnega nastavka</option>
                {ocenaRodnegaNastavkaBesedilo.map((ocena, index) => (
                  <option key={Math.random()} value={index + 1}>
                    {ocena}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-muted">
                Izberite oceno rodnega nastavka od 1 do 10 ob premeru plodičev
                od 6 do 10mm
              </Form.Text>
            </FormGroup>

            <FormGroup className="m-2">
              <Form.Label htmlFor="ocenaRodnegaNastavkaDatum">
                Datum:
              </Form.Label>
              <Form.Control
                id="ocenaRodnegaNastavkaDatum"
                type="date"
                value={izbranaOcenaRodnegaNastavkaDatum}
                min={`${new Date().getFullYear()}-01-01`}
                max={new Date().toISOString().slice(0, 10)}
                onChange={(e) =>
                  setIzbranaOcenaRodnegaNastavkaDatum(e.target.value)
                }
                placeholder="Vnesite datum ocenjevanja rodnega nastavka"
              />
              <Form.Text className="text-muted">
                Izberite datum ocenjevanja rodnega nastavka
              </Form.Text>
            </FormGroup>

            <FormGroup className="m-2">
              <Container>
                <Row className="g-3">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Shrani oceno rodnega nastanka
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default JablanaRodniNastavek;
