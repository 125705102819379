import React, { useState, useEffect, useCallback } from "react";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import ModalError from "../../modals/ModalError";
import {
  Button,
  Card,
  Form,
  FormGroup,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import fetcherGet from "../../fetcher/fetcherGet";
import fetcherPut from "../../fetcher/fetcherPut";
import {
  JE_NAMAKAN_NASAD,
  NI_NAMAKAN_NASAD,
  RAZREDI_VISIN_JABLAN,
} from "../../../constants/constans";

const JablanaUrediSplosnePodatke = ({ setJePrikazSplosnihPodatkov }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId, currGerk } = useCommonContext();

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [jablanaSplosniPodatki, setJablanaSplosniPodatki] = useState({});

  const fetchSplosnePodatkeJablana = useCallback(
    (signal) => {
      return fetcherGet(
        "jablana/gerkData",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setJablanaSplosniPodatki
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchSplosnePodatkeJablana(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>
            <h3 className="text-center mb-4">
              Uredi podatke o nasadu jablane: {currGerk}
            </h3>
          </Card.Title>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              const isUpdateSuccessful = await fetcherPut(
                "jablana/gerkData",
                jablanaSplosniPodatki,
                nasadId,
                setModalError,
                setShowModal,
                signOut,
                history,
                setJePrikazSplosnihPodatkov
              );

              if (isUpdateSuccessful) {
                setJePrikazSplosnihPodatkov(true);
              }
            }}
          >
            <FormGroup className="m-2">
              <Form.Label htmlFor="jeNamakanNasad">
                Je nasad namakan:
              </Form.Label>
              <br />
              <Form.Check
                inline
                label="Je namakan"
                name={JE_NAMAKAN_NASAD}
                value={JE_NAMAKAN_NASAD}
                checked={jablanaSplosniPodatki.jeNamakanNasad === true}
                type={"radio"}
                onChange={(e) => {
                  setJablanaSplosniPodatki((prevState) => ({
                    ...prevState,
                    jeNamakanNasad: e.target.value === JE_NAMAKAN_NASAD,
                  }));
                }}
              />
              <Form.Check
                inline
                label="Ni namakan"
                name={NI_NAMAKAN_NASAD}
                value={NI_NAMAKAN_NASAD}
                checked={jablanaSplosniPodatki.jeNamakanNasad === false}
                type={"radio"}
                onChange={(e) => {
                  setJablanaSplosniPodatki((prevState) => ({
                    ...prevState,
                    jeNamakanNasad: e.target.value === JE_NAMAKAN_NASAD,
                  }));
                }}
              />
            </FormGroup>

            <FormGroup className="m-2">
              <Form.Label htmlFor="razredVisinDreves">
                Razred višine dreves (m):
              </Form.Label>
              <Form.Select
                name="razredVisinDreves"
                aria-label="Izberite razred višine dreve (m)"
                required
                onChange={(e) => {
                  setJablanaSplosniPodatki((prevState) => ({
                    ...prevState,
                    razredVisinDreves: e.target.value,
                  }));
                }}
                value={jablanaSplosniPodatki.razredVisinDreves}
              >
                <option hidden>Izberite razred višine dreve (m)</option>
                {RAZREDI_VISIN_JABLAN.map((visina, index) => (
                  <option key={index} value={visina}>
                    {visina}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-muted">
                Izberite razred višine dreves v nasadu (m)
              </Form.Text>
            </FormGroup>

            <FormGroup className="m-2">
              <Container>
                <Row className="g-3">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Shrani podatke
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </Card.Body>
      </Card>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default JablanaUrediSplosnePodatke;
