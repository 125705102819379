import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import JablanaObrazci from "./components/jablana/JablanaObrazci";
import CesnjaObrazci from "./components/cesnja/CesnjaObrazci";
import Registracija from "./components/uporabniki/Registracija";
import MyNavbarComponent from "./components/MyNavbarComponent";
import LoginUser from "./components/uporabniki/LoginUser";
import Nastavitve from "./components/nastavitve/Nastavitve";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";
import AktivniNasadi from "./components/AktivniNasadi";
import BorovnicaObrazci from "./components/borovnica/BorovnicaObrazci";
import JablanaGnojenjeVnos from "./components/jablana/obdelavaTal/JablanaGnojenjeVnos";
import JablanaNamakanjeVnos from "./components/jablana/namakanje/JablanaNamakanjeVnos";
import JablanaFertirigacijaVnos from "./components/jablana/fertirigacija/JablanaFertirigacijaVnos";
import JablanaBiodiverzitetaVnos from "./components/jablana/biodiverziteta/JablanaBiodiverzitetaVnos";
import JablanaMehanskiUkrepiVnos from "./components/jablana/mehanskiUkrepi/JablanaMehanskiUkrepiVnos";
import JablanaSkropivaVnos from "./components/jablana/skropiva/JablanaSkropivaVnos";

function App() {
  return (
    <BrowserRouter>
      <MyNavbarComponent />
      <Container fluid>
        <Routes>
          <Route path="/registracija" element={<Registracija />} />
          <Route path="/prijava" element={<LoginUser />} />

          <Route path="/" element={<AuthOutlet fallbackPath="/prijava" />}>
            <Route path="/domacaStran" element={<AktivniNasadi />} />
            <Route path="/nastavitve" element={<Nastavitve />} />

            <Route path="/jablanaVnos" element={<JablanaObrazci />} />
            <Route path="/cesnjaVnos" element={<CesnjaObrazci />} />
            <Route
              path="/ameriskaBorovnicaVnos"
              element={<BorovnicaObrazci />}
            />
            <Route
              path="/dnevnikSkropljenj"
              element={<JablanaSkropivaVnos />}
            />
            <Route
              path="/vnosGnojenjaJablana"
              element={<JablanaGnojenjeVnos />}
            />
            <Route
              path="/vnosNamakanjeJablana"
              element={<JablanaNamakanjeVnos />}
            />
            <Route
              path="/vnosFertirigacijeJablana"
              element={<JablanaFertirigacijaVnos />}
            />
            <Route
              path="/vnosBiodiveritetaJablana"
              element={<JablanaBiodiverzitetaVnos />}
            />
            <Route
              path="/vnosMehanskiUkrepiJablana"
              element={<JablanaMehanskiUkrepiVnos />}
            />
          </Route>
        </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;
