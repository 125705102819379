import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherPost from "../../fetcher/fetcherPost";
import ModalError from "../../modals/ModalError";
import { LITER_M_KVADRAT, LITER_NA_SADIKO } from "./jablanaNamakanjeEnote";
import { useFetchAktivniNasadi } from "../../../hooks/useFetchAktivniNasadi";
import AktivniSeznamiChecklist from "../common/AktivniSeznamiChecklist";
import SaveButton from "../common/SaveButton";

const JablanaNamakanjeVnos = () => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [aktivniNasadi, setAktivniNasadi] = useState([]);
  const [datum, setDatum] = useState(new Date().toISOString().slice(0, 10));

  const [dozaNamakanja, setDozaNamakanja] = useState();
  const [enotaDozeNamakanja, setEnotaDozeNamakanja] = useState(LITER_NA_SADIKO);

  const [idIzbranihNasadov, setIdIzbranihNasadov] = useState({});

  const fetchAktivniNasadi = useFetchAktivniNasadi(
    setModalError,
    setShowModal,
    signOut,
    history,
    setAktivniNasadi
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchAktivniNasadi(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div
      className="mt-5 d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
        <Card>
          <Card.Body>
            <Row>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();
                  await fetcherPost(
                    `jablana/namakanje`,
                    {
                      datum: datum,
                      dozaNamakanja: dozaNamakanja,
                      jeEnotaLNaSadiko: enotaDozeNamakanja === LITER_NA_SADIKO,
                      idIzbranihNasadov: Object.keys(idIzbranihNasadov)
                        .filter(
                          (key) => idIzbranihNasadov[key].isChecked === true
                        )
                        .map((key) => ({
                          nasadId: key,
                        })),
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setDatum(new Date().toISOString().slice(0, 10));

                  setDozaNamakanja("");
                  setEnotaDozeNamakanja(LITER_NA_SADIKO);

                  setIdIzbranihNasadov({});
                }}
              >
                <Form.Label htmlFor="skodljivec">
                  <h4>Vnos podatkov o namakanju nasada jablane</h4>
                </Form.Label>

                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "Gainsboro" }}>Datum</th>
                      <th style={{ backgroundColor: "Gainsboro" }}>
                        Doza ({LITER_NA_SADIKO} ali {LITER_M_KVADRAT})
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Control
                          id="datum"
                          type="date"
                          min={`${new Date().getFullYear()}-01-01`}
                          value={datum}
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={(e) => setDatum(e.target.value)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="dozaNamakanja"
                          type="number"
                          value={dozaNamakanja ?? ""}
                          min={0}
                          step={0.1}
                          onChange={(e) => setDozaNamakanja(e.target.value)}
                          required
                        />
                        <div key={`inline-radio`} className="mb-3">
                          <Form.Check // prettier-ignore
                            type="radio"
                            inline
                            id="enotaDozeNamakanja"
                            value={enotaDozeNamakanja}
                            checked={enotaDozeNamakanja === LITER_NA_SADIKO}
                            onChange={(e) => {
                              setEnotaDozeNamakanja(
                                e.target.value === LITER_NA_SADIKO
                                  ? LITER_M_KVADRAT
                                  : LITER_NA_SADIKO
                              );
                            }}
                            label={LITER_NA_SADIKO}
                          />
                          <Form.Check // prettier-ignore
                            inline
                            type="radio"
                            id="enotaDozeNamakanja"
                            value={enotaDozeNamakanja}
                            checked={enotaDozeNamakanja === LITER_M_KVADRAT}
                            onChange={(e) => {
                              setEnotaDozeNamakanja(
                                e.target.value === LITER_M_KVADRAT
                                  ? LITER_NA_SADIKO
                                  : LITER_M_KVADRAT
                              );
                            }}
                            label={LITER_M_KVADRAT}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <AktivniSeznamiChecklist
                  text={
                    "Namakani nasadi (GERK, sorta, leto nasaditve, datum spravila):"
                  }
                  setIdIzbranihNasadov={setIdIzbranihNasadov}
                  aktivniNasadi={aktivniNasadi}
                  idIzbranihNasadov={idIzbranihNasadov}
                />

                <SaveButton
                  disabledCondition={
                    Object.keys(idIzbranihNasadov).filter(
                      (key) => idIzbranihNasadov[key].isChecked === true
                    ).length === 0
                  }
                />
              </Form>
            </Row>
          </Card.Body>
        </Card>
      </div>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default JablanaNamakanjeVnos;
