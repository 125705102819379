import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Tabs, Tab } from "react-bootstrap";
import TabelaGerkovJablana from "./TabelaGerkovJablana";
import TabelaGerkovBrezTrajnihNasadov from "./TabelaGerkovBrezTrajnihNasadov";
import TabelaGerkovCesnja from "./TabelaGerkovCesnja";
import TabelaGerkovBorovnica from "./TabelaGerkovBorovnica";
import { useNavigate } from "react-router-dom";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import ModalError from "../modals/ModalError";
import BASE_URL from "../../utils/baseUrl";
import UrediPodatkeOLastniku from "./UrediPodatkeONosilcu";
import OgledSlik from "./OgledSlik";
// import PrikazGpsPodatkovDreves from "./PrikazGpsPodatkovDreves";

const Nastavitve = () => {
  const [key, setKey] = useState("jablanaGerki");
  const [zdruzeniPodatki, setZdruzeniPodatki] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const auth = useAuthUser();
  const signOut = useSignOut();
  const history = useNavigate();

  const fetchZdruzeniPodatki = useCallback(
    async (signal) => {
      try {
        const res = await fetch(
          `${BASE_URL}/midNumber/zdruzeniPodatki/${auth.midStevilka}`,
          {
            method: "GET",
            signal: signal,
            credentials: "include",
          }
        );

        if (res.status === 401) {
          setModalError(`Napaka pri avtorizaciji uporabnika.`);
          setShowModal(true);

          signOut();
          history("/prijava");

          return;
        }

        const data = await res.json();

        setZdruzeniPodatki(data);
      } catch (error) {
        if (error?.name === "AbortError") return;

        setModalError(
          `Napaka pri pridobivanju podatkov o vseh GERK-ih in nasadih lastnika. ${error?.message}`
        );
        setShowModal(true);
      }
    },
    [auth, history, signOut]
  );

  useEffect(() => {
    if (zdruzeniPodatki.length > 0) return;
    const controller = new AbortController();

    fetchZdruzeniPodatki(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  const handleCheckboxToggle = async (tip, vrsta, isChecked, id) => {
    console.log(`tip: ${tip} je označeno: ${isChecked} id: ${id}`);

    try {
      const res = await fetch(`${BASE_URL}/midNumber/${auth.midStevilka}`, {
        credentials: "include",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        signal: new AbortController().signal,
        body: JSON.stringify({
          tip: tip,
          vrsta: vrsta,
          id: id,
          jeVodenNasad: isChecked,
        }),
      });
      if (res.status === 401) {
        setModalError(`Napaka pri avtorizaciji uporabnika.`);
        setShowModal(true);

        signOut();
        history("/prijava");

        return;
      }
      const data = await res.json();

      if (res.status === 400 && data?.message) {
        setModalError(
          `Napaka pri posodabljanju statusa aktivnega nasada na strežniškem delu. ${data?.message}`
        );
        setShowModal(true);
        return;
      }

      if (res.status === 200 && data.statusNasadaPosodobljen === true) {
        fetchZdruzeniPodatki();
        return;
      }
    } catch (error) {
      if (error?.name === "AbortError") return;

      setModalError(
        `Napaka pri posodabljanju statusa aktivnega nasada. ${error?.message}`
      );
      setShowModal(true);
    }
  };

  return (
    <Container>
      <Row>
        <Tabs onSelect={(e) => setKey(e)} activeKey={key} className="m-1">
          <Tab eventKey="nedodeljeniGerki" title="GERK-i brez trajnih nasadov">
            <TabelaGerkovBrezTrajnihNasadov
              zdruzeniPodatki={zdruzeniPodatki.filter((e) => {
                return e.nasad.length === 0;
              })}
              setShowModal={setShowModal}
              setModalError={setModalError}
              signOut={signOut}
              history={history}
              fetchZdruzeniPodatki={fetchZdruzeniPodatki}
            />
          </Tab>
          <Tab eventKey="jablanaGerki" title="GERK-i z nasadi jablane">
            <TabelaGerkovJablana
              zdruzeniPodatki={zdruzeniPodatki.filter((e) => {
                return (
                  e?.nasad.filter((f) => {
                    return f.vrsta === "JABLANA";
                  }).length > 0
                );
              })}
              handleCheckboxToggle={handleCheckboxToggle}
            />
          </Tab>
          <Tab eventKey="cesnjaGerki" title="GERK-i z nasadi češnje">
            <TabelaGerkovCesnja
              zdruzeniPodatki={zdruzeniPodatki.filter((e) => {
                return (
                  e.nasad.filter((f) => {
                    return f.vrsta === "ČEŠNJA";
                  }).length > 0
                );
              })}
              handleCheckboxToggle={handleCheckboxToggle}
            />
          </Tab>
          <Tab
            eventKey="borovnicaGerki"
            title="GERK-i z nasadi ameriške borovnice"
          >
            <TabelaGerkovBorovnica
              zdruzeniPodatki={zdruzeniPodatki.filter((e) => {
                return (
                  e.nasad.filter((f) => {
                    return f.vrsta === "AMERIŠKA BOROVNICA";
                  }).length > 0
                );
              })}
              handleCheckboxToggle={handleCheckboxToggle}
            />
          </Tab>

          <Tab
            eventKey="urejanjePodatkovONosilcu"
            title="Urejanje podatkov o nosilcu"
          >
            <UrediPodatkeOLastniku
              setModalError={setModalError}
              setShowModal={setShowModal}
            />
          </Tab>
          <Tab
            eventKey="prikazSlikAktivnihNasadov"
            title="Prikaz slik aktivnih nasadov"
          >
            <OgledSlik
              setModalError={setModalError}
              setShowModal={setShowModal}
            />
          </Tab>

          {/* <Tab
            eventKey="prikazGpsPodatkovDreves"
            title="Dodatne informacije o nasadu"
          >
            <PrikazGpsPodatkovDreves
              setModalError={setModalError}
              setShowModal={setShowModal}
            />
          </Tab> */}


        </Tabs>
      </Row>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </Container>
  );
};

export default Nastavitve;
