import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import fetcherPost from "../../fetcher/fetcherPost";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";

const BorovnicaNamakanje = ({
  vsiNamakanje,
  fetchPodatkeUkrepi,
  setShowModal,
  setModalError,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [datumNamakanja, setDatumNamakanja] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const [obrok, setObrok] = useState();

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsiNamakanje?.length > 0 ? false : true);
  }, [vsiNamakanje]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "DarkSalmon" }}>
        <Card.Header>Podatki o namakanju nasada</Card.Header>

        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "DarkSalmon" }}>
                    Datum namakanja
                  </th>
                  <th style={{ backgroundColor: "DarkSalmon" }}>
                    Obrok namakanja (l/tekoči meter)
                  </th>

                  {vsiNamakanje[0]?.datum &&
                  new Date(vsiNamakanje[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "DarkSalmon" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {vsiNamakanje &&
                  vsiNamakanje.map((podatek) => (
                    <DeletionRow
                      id={podatek.id}
                      key={podatek.id}
                      link={"borovnica/ukrep"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkeUkrepi}
                      briseSe={"NAMAKANJE"}
                      jeTrenutnoLeto={
                        new Date(podatek.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={podatek.datum}
                              inputType={"date"}
                              id={podatek.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={"NAMAKANJE_DATUM"}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={podatek.obrok}
                              inputType={"number"}
                              id={podatek.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"obrok"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={"NAMAKANJE_OBROK"}
                              step={0.1}
                              min={0}
                              max={Number.MAX_SAFE_INTEGER}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Card className="m-1" style={{ backgroundColor: "DarkSalmon" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await fetcherPost(
                    `borovnica/ukrep`,
                    {
                      datum: datumNamakanja,
                      obrok: obrok,
                      tip: "NAMAKANJE",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setObrok("");
                  setDatumNamakanja(new Date().toISOString().slice(0, 10));

                  fetchPodatkeUkrepi(new AbortController().signal);
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="datumNamakanja">Datum:</Form.Label>
                  <Form.Control
                    id="datumNamakanja"
                    type="date"
                    value={datumNamakanja}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setDatumNamakanja(e.target.value)}
                    placeholder="Vnesite datum namakanja"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum namakanja
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="obrok">Obrok namakanja</Form.Label>
                  <Form.Control
                    id="obrok"
                    type="number"
                    min={0.0}
                    step={0.1}
                    required
                    value={obrok}
                    onChange={(e) => setObrok(e.target.value)}
                    placeholder={`Vnesite obrok (l/tekoči meter) namakanja`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite obrok (l/tekoči meter) namakanja
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default BorovnicaNamakanje;
