import React, { useState, useEffect, useCallback } from "react";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../../fetcher/fetcherGet";
import useCommonContext from "../../../hooks/useCommonContext";
import ModalError from "../../modals/ModalError";
import {
  Button,
  Card,
  Form,
  FormGroup,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import fetcherPut from "../../fetcher/fetcherPut";

const CesnjaUrediSplosnePodatke = ({ setJePrikazSplosnihPodatkov }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId, currGerk } = useCommonContext();

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [cesnjaSplosniPodatki, setCesnjaSplosniPodatki] = useState({});

  const fetchSplosniPodatkiCesnja = useCallback(
    (signal) => {
      return fetcherGet(
        "cesnja/gerkData",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setCesnjaSplosniPodatki
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchSplosniPodatkiCesnja(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>
            <h3 className="text-center mb-4">
              Uredi podatke o nasadu češnje: {currGerk}
            </h3>
          </Card.Title>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              const isUpdateSuccessful = await fetcherPut(
                "cesnja/gerkData",
                cesnjaSplosniPodatki,
                nasadId,
                setModalError,
                setShowModal,
                signOut,
                history,
                setJePrikazSplosnihPodatkov
              );

              if (isUpdateSuccessful) {
                setJePrikazSplosnihPodatkov(true);
              }
            }}
          >
            <FormGroup className="m-2">
              <Form.Label htmlFor="drevesaNaHa">
                Leto nasaditve sadovnjaka:
              </Form.Label>
              <Form.Control
                type="number"
                name="letoNasaditve"
                value={cesnjaSplosniPodatki.letoNasaditve ?? 1970}
                required
                step={1}
                min={1000}
                max={new Date().getFullYear()}
                placeholder="Uredite letnico nasaditve sadovnjaka"
                onChange={(e) => {
                  setCesnjaSplosniPodatki((prevState) => ({
                    ...prevState,
                    letoNasaditve: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>
            <FormGroup className="m-2">
              <Form.Label htmlFor="drevesaNaHa">
                Število sadik/dreves v sadovnjaku:
              </Form.Label>
              <Form.Control
                type="number"
                name="letoNasaditve"
                value={cesnjaSplosniPodatki.steviloSadik ?? 0}
                required
                min={1}
                step={1}
                placeholder="Uredite število sadik/dreves v sadovnjaku"
                onChange={(e) => {
                  setCesnjaSplosniPodatki((prevState) => ({
                    ...prevState,
                    steviloSadik: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>
            <FormGroup className="m-2">
              <Form.Label htmlFor="drevesaNaHa">
                Srednja razdalja med vrstami [m]:
              </Form.Label>
              <Form.Control
                type="number"
                name="letoNasaditve"
                value={cesnjaSplosniPodatki.srednjaRazdaljaMedVrstami ?? 0.0}
                required
                min={0.0}
                step={0.1}
                placeholder="Uredite srednjo razdaljo med vrstami [m] v sadovnjaku"
                onChange={(e) => {
                  setCesnjaSplosniPodatki((prevState) => ({
                    ...prevState,
                    srednjaRazdaljaMedVrstami: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>
            <FormGroup className="m-2">
              <Form.Label htmlFor="drevesaNaHa">
                Srednja razdalja med drevesi v vrsti [m]:
              </Form.Label>
              <Form.Control
                type="number"
                name="letoNasaditve"
                value={cesnjaSplosniPodatki.srednjaRazdaljaVVrsti ?? 0.0}
                required
                min={0.0}
                step={0.1}
                placeholder="Uredite srednjo razdaljo med drevesi v vrstami [m] v sadovnjaku"
                onChange={(e) => {
                  setCesnjaSplosniPodatki((prevState) => ({
                    ...prevState,
                    srednjaRazdaljaVVrsti: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>
            <FormGroup className="m-2">
              <Form.Label htmlFor="sorta">Sorta:</Form.Label>
              <Form.Control
                type="text"
                name="sorta"
                value={cesnjaSplosniPodatki.sorta ?? ""}
                required
                minLength={3}
                placeholder={`Uredite sorto češnje`}
                onChange={(e) => {
                  setCesnjaSplosniPodatki((prevState) => ({
                    ...prevState,
                    sorta: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>
            <FormGroup className="m-2">
              <Form.Label htmlFor="sorta">Gojitvena oblika:</Form.Label>
              <Form.Control
                type="text"
                name="sorta"
                value={cesnjaSplosniPodatki.gojitvenaOblika ?? ""}
                required
                minLength={3}
                placeholder={`Uredite gojitveno obliko češnje`}
                onChange={(e) => {
                  setCesnjaSplosniPodatki((prevState) => ({
                    ...prevState,
                    gojitvenaOblika: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>
            <FormGroup className="m-2">
              <Form.Label htmlFor="sorta">Podlaga:</Form.Label>
              <Form.Control
                type="text"
                name="sorta"
                value={cesnjaSplosniPodatki.podlaga ?? ""}
                required
                minLength={3}
                placeholder={`Uredite naziv podlage češnje`}
                onChange={(e) => {
                  setCesnjaSplosniPodatki((prevState) => ({
                    ...prevState,
                    podlaga: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>

            <FormGroup className="m-2">
              <Container>
                <Row className="g-3">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Shrani podatke
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </Card.Body>
      </Card>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default CesnjaUrediSplosnePodatke;
