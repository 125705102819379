import React, { useCallback, useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/sl";
import CesnjaUporabaFFS from "./CesnjaUporabaFFS";
import CesnjaUporabaGnojil from "./CesnjaUporabaGnojil";
import CesnjaPodatkiORezi from "./CesnjaPodatkiORezi";
import fetcherGet from "../../fetcher/fetcherGet";

const CesnjaTehnoloskiUkrepi = ({
  izbranoLeto,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [vsiPodatkiOTehnoloskihUkrepih, setVsiPodatkiOTehnoloskihUkrepih] =
    useState({
      fssPodatki: [],
      gnojenjePodatki: [],
      podatkiRez: [],
    });

  const fetchPodatkeOTehnoloskihUkrepih = useCallback(
    (signal) => {
      return fetcherGet(
        "cesnja/tehnoloskiUkrepi",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setVsiPodatkiOTehnoloskihUkrepih
      );
    },
    [signOut, history, nasadId]
  );

  useEffect(() => {
    const controller = new AbortController();

    fetchPodatkeOTehnoloskihUkrepih(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Container>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sl">
              <CesnjaUporabaFFS
                fetchPodatkeOTehnoloskihUkrepih={
                  fetchPodatkeOTehnoloskihUkrepih
                }
                setModalError={setModalError}
                setShowModal={setShowModal}
                fssPodatki={vsiPodatkiOTehnoloskihUkrepih.fssPodatki.filter(
                  (e) => {
                    return new Date(e.datumInCas).getFullYear() === izbranoLeto;
                  }
                )}
                setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
              />
            </LocalizationProvider>
            <CesnjaUporabaGnojil
              fetchPodatkeOTehnoloskihUkrepih={fetchPodatkeOTehnoloskihUkrepih}
              setModalError={setModalError}
              setShowModal={setShowModal}
              gnojenjePodatki={vsiPodatkiOTehnoloskihUkrepih.gnojenjePodatki.filter(
                (e) => {
                  return new Date(e.datum).getFullYear() === izbranoLeto;
                }
              )}
              setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
            />
            <CesnjaPodatkiORezi
              fetchPodatkeOTehnoloskihUkrepih={fetchPodatkeOTehnoloskihUkrepih}
              setModalError={setModalError}
              setShowModal={setShowModal}
              podatkiRez={vsiPodatkiOTehnoloskihUkrepih.podatkiRez.filter(
                (e) => {
                  return new Date(e.datum).getFullYear() === izbranoLeto;
                }
              )}
              setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
            />
          </Container>
        </Card.Body>
      </Card>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default CesnjaTehnoloskiUkrepi;
