import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import fetcherPost from "../../fetcher/fetcherPost";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";

const BorovnicaPosamezenUkrep = ({
  vsiUkrepi,
  fetchPodatkeUkrepi,
  setShowModal,
  setModalError,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [datumUkrepa, setDatumUkrepa] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const [ukrep, setUkrep] = useState();

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsiUkrepi?.length > 0 ? false : true);
  }, [vsiUkrepi]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "Beige" }}>
        <Card.Header>
          Podatki o ukrepih na nasadu ameriške borovnice
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "Beige" }}>Datum</th>
                  <th style={{ backgroundColor: "Beige" }}>Ukrep</th>

                  {vsiUkrepi[0]?.datum &&
                  new Date(vsiUkrepi[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "Beige" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {vsiUkrepi &&
                  vsiUkrepi.map((ukrep) => (
                    <DeletionRow
                      id={ukrep.id}
                      key={ukrep.id}
                      link={"borovnica/ukrep"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkeUkrepi}
                      briseSe={"UKREP"}
                      jeTrenutnoLeto={
                        new Date(ukrep.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={ukrep.datum}
                              inputType={"date"}
                              id={ukrep.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={"UKREP_DATUM"}
                              jeTrenutnoLeto={
                                new Date(ukrep.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={ukrep.ukrep}
                              inputType={"text"}
                              id={ukrep.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"ukrep"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={"UKREP_UKREP"}
                              jeTrenutnoLeto={
                                new Date(ukrep.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Card className="m-1" style={{ backgroundColor: "Beige" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await fetcherPost(
                    `borovnica/ukrep`,
                    { datum: datumUkrepa, ukrep: ukrep, tip: "UKREP" },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setUkrep("");
                  setDatumUkrepa(new Date().toISOString().slice(0, 10));

                  fetchPodatkeUkrepi(new AbortController().signal);
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="datumUkrepa">Datum:</Form.Label>
                  <Form.Control
                    id="datumUkrepa"
                    type="date"
                    value={datumUkrepa}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setDatumUkrepa(e.target.value)}
                    placeholder="Vnesite datum ukrepa"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum ukrepa
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="ukrep">Ukrep:</Form.Label>
                  <Form.Control
                    id="ukrep"
                    type="text"
                    required
                    rows={3}
                    as={"textarea"}
                    value={ukrep}
                    onChange={(e) => setUkrep(e.target.value)}
                    placeholder="Vnesite izveden ukrep"
                  />

                  <Form.Text className="text-muted">
                    Vnesite izveden ukrep
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default BorovnicaPosamezenUkrep;
