import React, { useState, useCallback } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import JablanaSplosniPodatki from "./splosniPodatki/JablanaSplosniPodatki";
import JablanaCvetenjeVnosPregled from "./cvetenje/JablanaCvetenjeVnosPregled";
import JablanaRazvojPlodicevVnosPregled from "./razvojPlodicev/JablanaRazvojPlodicevVnosPregled";
import JablanaAnalizaZrelostnegaStanjaVnosPregled from "./analizaZrelostnegaStanja/JablanaAnalizaZrelostnegaStanjaVnosPregled";
import JablanaPridelekVnosPregled from "./pridelek/JablanaPridelekVnosPregled";
import JablanaUrediSplosnePodatke from "./splosniPodatki/JablanaUrediSplosnePodatke";
import IzbiraLetaInNaslov from "../IzbiraLetaInNaslov";
import JablanaSkodljivci from "./skodljivci/JablanaSkodljivci";
import JablanaKoristniOrganizmi from "./koristniOrganizmi/JablanaKoristniOrganizmi";
import JablanaSkropivaPrikaz from "./skropiva/JablanaSkropivaPrikaz";
import JablanaNapovedPridelka from "./pridelek/JablanaNapovedPridelka";
import JablanaGnojenjePrikaz from "./obdelavaTal/JablanaGnojenjePrikaz";
import JablanaNamakanjePrikaz from "./namakanje/JablanaNamakanjePrikaz";
import JablanaFertirigacijaPrikaz from "./fertirigacija/JablanaFertirigacijaPrikaz";
import JablanaBiodiverzitetaPrikaz from "./biodiverziteta/JablanaBiodiverzitetaPrikaz";
import JablanaMehanskiUkrepiPrikaz from "./mehanskiUkrepi/JablanaMehanskiUkrepiPrikaz";
import PrikazGpsPodatkovDreves from "../nastavitve/PrikazGpsPodatkovDreves";

const JablanaObrazci = () => {
  const [key, setKey] = useState("gerk");
  const [jePrikazSplosnihPodatkov, setJePrikazSplosnihPodatkov] =
    useState(true);
  const [niPodatkovZaIzbranoLeto, setNiPodatkovZaIzbranoLeto] = useState(true);

  const [izbranoLeto, setIzbranoLeto] = useState(new Date().getFullYear());

  const memoizedSetNiPodatkovZaIzbranoLeto = useCallback(
    (newValue) => setNiPodatkovZaIzbranoLeto(newValue),
    [] // Empty dependency array to prevent unnecessary re-creations
  );

  return (
    <div
      className="mt-5 d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
        <Card>
          <Card.Body>
            <IzbiraLetaInNaslov
              naslov={"CRP obrazec za jablano"}
              izbranoLeto={izbranoLeto}
              setIzbranoLeto={setIzbranoLeto}
              niPodatkovZaIzbranoLeto={niPodatkovZaIzbranoLeto}
            />

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="gerk" title="GERK">
                {jePrikazSplosnihPodatkov ? (
                  <JablanaSplosniPodatki
                    setJePrikazSplosnihPodatkov={setJePrikazSplosnihPodatkov}
                  />
                ) : (
                  <JablanaUrediSplosnePodatke
                    setJePrikazSplosnihPodatkov={setJePrikazSplosnihPodatkov}
                  />
                )}
              </Tab>
              <Tab eventKey="cvetenje" title="Cvetenje">
                <JablanaCvetenjeVnosPregled
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="razvojPlodicev" title="Razvoj plodičev">
                <JablanaRazvojPlodicevVnosPregled
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="napovedPridelka" title="Napoved pridelka">
                <JablanaNapovedPridelka
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab
                eventKey="analizaZrelostnegaStanja"
                title="Analiza zrelostnega stanja"
              >
                <JablanaAnalizaZrelostnegaStanjaVnosPregled
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>

              <Tab eventKey="pridelek" title="Pridelek">
                <JablanaPridelekVnosPregled
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="skodljivci" title="Škodljivci">
                <JablanaSkodljivci
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="koristni" title="Koristni organizmi">
                <JablanaKoristniOrganizmi
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="skropiva" title="Dnevnik škropljenj">
                <JablanaSkropivaPrikaz
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="gnojenje" title="Dnevnik gnojenja">
                <JablanaGnojenjePrikaz
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="namakanje" title="Dnevnik namakanja">
                <JablanaNamakanjePrikaz
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="fertirigacija" title="Dnevnik fertirigacije">
                <JablanaFertirigacijaPrikaz
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="biodiverziteta" title="Ohranjanje biodiverzitete">
                <JablanaBiodiverzitetaPrikaz
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab eventKey="mehanskiUkrepi" title="Dnevnik mehanskih ukrepov">
                <JablanaMehanskiUkrepiPrikaz
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={
                    memoizedSetNiPodatkovZaIzbranoLeto
                  }
                />
              </Tab>
              <Tab
            eventKey="prikazGpsPodatkovDreves"
            title="Dodatne informacije o nasadu"
          >
            <PrikazGpsPodatkovDreves disableUploading={false}
            />
          </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default JablanaObrazci;
