import BASE_URL from "../../utils/baseUrl";

const fetcherPut = async (
  link,
  podatki,
  nasadId,
  setModalError,
  setShowModal,
  signOut,
  history,
) => {
  try {
    const res = await fetch(`${BASE_URL}/${link}/${nasadId}`, {
      method: "PUT",
      signal: new AbortController().signal,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(podatki),
    });

    if (res.status === 401) {
      setModalError(`Napaka pri avtorizaciji uporabnika.`);
      setShowModal(true);

      signOut();
      history("/prijava");

      return;
    }

    const data = await res.json();
    if (res.status === 400 && data?.message) {
      setModalError(
        `Napaka pri posodobitvi podatkov o nasadu na strežniškem delu. ${data?.message}`
      );
      setShowModal(true);
      return;
    }

    if (res.status === 200 && data.dataUpdatedSuccessfully === true) {
      return true;
    }
  } catch (error) {
    if (error?.name === "AbortError") return;

    setModalError(
      `Napaka pri posodobitvi splošnih podatkov o nasadu. ${error?.message}`
    );
    setShowModal(true);
  }

  return false;
};

export default fetcherPut;
