import React, { useState } from "react";
import fetcherPut from "./fetcher/fetcherPut";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import useCommonContext from "../hooks/useCommonContext";
import { Form } from "react-bootstrap";
function EditableCell({
  initialValue,
  inputType,
  link,
  id,
  imeLastnosti,
  setModalError,
  setShowModal,
  fetchNewData,
  spreminjaSe,
  moznosti,
  step,
  radioValue,
  min,
  max,
  jeTrenutnoLeto,
  poljeMoznosti,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const signOut = useSignOut();
  const history = useNavigate();
  const { nasadId } = useCommonContext();

  const NI_POSODOBITVE = "Brez vrednosti, ni posodobitve!";

  const handleDoubleClick = () => {
    if (jeTrenutnoLeto === false) return;
    setIsEditing(!isEditing);
  };

  const handleBlur = async (e) => {
    if (!e.currentTarget.reportValidity()) return;

    setIsEditing(false);

    if (typeof value === "string" && value.includes(NI_POSODOBITVE)) return;

    if (value !== initialValue) {
      await fetcherPut(
        link,
        { id: id, [imeLastnosti]: value, spreminjaSe: spreminjaSe },
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history
      );

      fetchNewData();
    }
  };

  const handleChange = (e) => {
    if (e.target.value === "" || !e.target.value) {
      setValue(`${NI_POSODOBITVE} ${e.target.value}`);
      return;
    }
    setValue(
      inputType === "number" && step < 1
        ? parseFloat(e.target.value)
        : e.target.value
    );
  };

  return isEditing ? (
    inputType === "number" ? (
      <Form.Control
        type={inputType}
        value={value}
        step={step}
        min={min ?? 0}
        max={max ?? 10}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    ) : inputType === "date" ? (
      <Form.Control
        type={inputType}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    ) : inputType === "radio" ? (
      <>
        <Form.Check
          inline
          onBlur={handleBlur}
          label={moznosti[0]}
          name={moznosti[0]}
          value={moznosti[0]}
          checked={moznosti.length === 2 ? value === moznosti[0] : true}
          type={"radio"}
          onChange={(e) => setValue(e.target.value)}
        />
        {moznosti.length === 2 ? (
          <Form.Check
            inline
            onBlur={handleBlur}
            label={moznosti[1]}
            name={moznosti[1]}
            value={moznosti[1]}
            checked={value === moznosti[1]}
            type={"radio"}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <></>
        )}
      </>
    ) : inputType === "time" ? (
      <input
        type={inputType}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        step="300"
      />
    ) : inputType === "select" ? (
      <>
        <Form.Select
          name="sezname"
          aria-label="Izberite iz seznama"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
        >
          {poljeMoznosti.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Form.Select>
      </>
    ) : (
      <input
        type={inputType}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    )
  ) : (
    <div
      onDoubleClick={handleDoubleClick}
      onBlur={handleBlur}
      style={{ cursor: "pointer" }}
    >
      {inputType === "date"
        ? new Date(value).toLocaleString("sl-Sl").split(",")[0]
        : inputType === "radio"
        ? radioValue
          ? moznosti[0]
          : moznosti[1]
        : value === ""
        ? "Brez vrednosti"
        : value}
    </div>
  );
}

export default EditableCell;
