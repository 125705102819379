import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Card,
} from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import JablanaSkodljivciForma from "./JablanaSkodljivciForma";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherGet from "../../fetcher/fetcherGet";
import JablanaSkodljivciTabela from "./JablanaSkodljivciTabela";

const JablanaSkodljivci = ({ izbranoLeto, setNiPodatkovZaIzbranoLeto }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [vsiSkodljivci, setVsiSkodljivci] = useState({});

  const fetchSkodljivciData = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/skodljivci",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setVsiSkodljivci
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchSkodljivciData(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);
  return (
    <Card style={{ backgroundColor: "FloralWhite" }}>
      <Card.Body>
        <Container>
          <h3>Pregled podatkov o škodljivcih na jablani</h3>

          <JablanaSkodljivciTabela
            vsiSkodljivci={
              Object.keys(vsiSkodljivci).length > 0 &&
              vsiSkodljivci?.skodljivci?.filter((e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              })
            }
            fetchSkodljivciData={fetchSkodljivciData}
            setModalError={setModalError}
            setShowModal={setShowModal}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />

          <JablanaSkodljivciForma
            fetchSkodljivciData={fetchSkodljivciData}
            setModalError={setModalError}
            setShowModal={setShowModal}
          />

          <ModalError
            modalError={modalError}
            show={showModal}
            setShowModal={setShowModal}
          />
        </Container>
      </Card.Body>
    </Card>
  );
};

export default JablanaSkodljivci;
