import React, { useState, useEffect, useCallback } from "react";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../../fetcher/fetcherGet";
import useCommonContext from "../../../hooks/useCommonContext";
import ModalError from "../../modals/ModalError";
import {
  Button,
  Card,
  Form,
  FormGroup,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import fetcherPut from "../../fetcher/fetcherPut";

const BorovnicaUrediSplosnePodatke = ({ setJePrikazSplosnihPodatkov }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId, currGerk } = useCommonContext();

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [borovnicaSplosniPodatki, setBorovnicaSplosniPodatki] = useState({});

  const fetchSplosniPodatkiBorovnica = useCallback(
    (signal) => {
      return fetcherGet(
        "borovnica/gerkData",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setBorovnicaSplosniPodatki
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchSplosniPodatkiBorovnica(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>
            <h3 className="text-center mb-4">
              Uredi podatke o nasadu ameriške borovnice: {currGerk}
            </h3>
          </Card.Title>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              const isUpdateSuccessful = await fetcherPut(
                "borovnica/gerkData",
                borovnicaSplosniPodatki,
                nasadId,
                setModalError,
                setShowModal,
                signOut,
                history,
              );

              if (isUpdateSuccessful === true) {
                setJePrikazSplosnihPodatkov(true);
              }
            }}
          >
            <FormGroup className="m-2">
              <Form.Label htmlFor="steviloSadikNaTekociMeter">
                Število sadik na tekoči meter:
              </Form.Label>
              <Form.Control
                type="number"
                name="steviloSadikNaTekociMeter"
                value={borovnicaSplosniPodatki.steviloSadikNaTekociMeter ?? 0}
                required
                min={0.0}
                step={0.1}
                placeholder="Uredite število sadik na tekoči meter"
                onChange={(e) => {
                  setBorovnicaSplosniPodatki((prevState) => ({
                    ...prevState,
                    steviloSadikNaTekociMeter: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>

            <FormGroup className="m-2">
              <Form.Label htmlFor="razdaljaMedVrstami">
                Razdalja med vrstami (m):
              </Form.Label>
              <Form.Control
                type="number"
                name="razdaljaMedVrstami"
                value={borovnicaSplosniPodatki.razdaljaMedVrstami ?? 0}
                required
                min={0.0}
                step={0.1}
                placeholder="Uredite razdaljo med vrstami (m)"
                onChange={(e) => {
                  setBorovnicaSplosniPodatki((prevState) => ({
                    ...prevState,
                    razdaljaMedVrstami: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>

            <FormGroup className="m-2">
              <Form.Label htmlFor="sorta">Sorta:</Form.Label>
              <Form.Control
                type="text"
                name="sorta"
                value={borovnicaSplosniPodatki.sorta ?? ""}
                required
                minLength={3}
                placeholder={`Uredite sorto ameriške borovnice`}
                onChange={(e) => {
                  setBorovnicaSplosniPodatki((prevState) => ({
                    ...prevState,
                    sorta: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>
            <FormGroup className="m-2">
              <Form.Label htmlFor="kvalitetaSadik">Kvaliteta sadik:</Form.Label>
              <Form.Control
                type="text"
                name="kvalitetaSadik"
                value={borovnicaSplosniPodatki.kvalitetaSadik ?? ""}
                required
                minLength={3}
                placeholder={`Uredite kvaliteto sadik ameriške borovnice`}
                onChange={(e) => {
                  setBorovnicaSplosniPodatki((prevState) => ({
                    ...prevState,
                    kvalitetaSadik: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>
            <FormGroup className="m-2">
              <Form.Label htmlFor="casSajenja">Čas sajenja:</Form.Label>
              <Form.Control
                type="date"
                name="casSajenja"
                value={borovnicaSplosniPodatki.casSajenja ?? new Date()}
                required
                minLength={3}
                placeholder={`Uredite čas sajenja sadik ameriške borovnice`}
                onChange={(e) => {
                  setBorovnicaSplosniPodatki((prevState) => ({
                    ...prevState,
                    casSajenja: e.target.value,
                  }));
                }}
              ></Form.Control>
            </FormGroup>

            <FormGroup className="m-2">
              <Form.Label htmlFor="sorta">Rodnost:</Form.Label>
              <br />
              <Form.Check
                inline
                label="Enkrat rodne"
                name="enkratRodne"
                value="enkratRodne"
                checked={borovnicaSplosniPodatki.jeEnkratRodna === true}
                type={"radio"}
                onChange={(e) =>
                  setBorovnicaSplosniPodatki((prevState) => ({
                    ...prevState,
                    jeEnkratRodna: true,
                  }))
                }
              />
              <Form.Check
                inline
                label="Dvakrat rodne"
                name="dvakratRodne"
                value="dvakratRodne"
                checked={borovnicaSplosniPodatki.jeEnkratRodna === false}
                type={"radio"}
                onChange={(e) =>
                  setBorovnicaSplosniPodatki((prevState) => ({
                    ...prevState,
                    jeEnkratRodna: false,
                  }))
                }
              />
            </FormGroup>

            <FormGroup className="m-2">
              <Container>
                <Row className="g-3">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Shrani podatke
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </Card.Body>
      </Card>
      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default BorovnicaUrediSplosnePodatke;
