import React from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import { useEffect } from "react";

const JablanaGnojenjeTabela = ({
  vsaGnojenja,
  fetchGnojenjeData,
  setModalError,
  setShowModal,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsaGnojenja?.length > 0 ? false : true);
  }, [vsaGnojenja]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ backgroundColor: "BurlyWood" }}>Datum</th>
            <th style={{ backgroundColor: "BurlyWood" }}>
              Gnojilo (vsebnost hranljivih snovi v %)
            </th>
            <th style={{ backgroundColor: "BurlyWood" }}>
              Doza gnojila (kg/nasad)
            </th>
            <th style={{ backgroundColor: "BurlyWood" }}>
              Doza gnojila (kg/Ha)
            </th>
            <th style={{ backgroundColor: "BurlyWood" }}>Izjavajec gnojenja</th>
            <th style={{ backgroundColor: "BurlyWood" }}>Odgovorna oseba</th>
            <th style={{ backgroundColor: "BurlyWood" }}>
              Uporabljena mehanizacija
            </th>
            {vsaGnojenja &&
            vsaGnojenja[0]?.datum &&
            new Date(vsaGnojenja[0]?.datum).getFullYear() ===
              new Date().getFullYear() ? (
              <th style={{ backgroundColor: "BurlyWood" }}>Izbris</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {vsaGnojenja &&
            vsaGnojenja.map((item) => (
              <DeletionRow
                id={item.id}
                key={item.id}
                link={"jablana/gnojenje"}
                setModalError={setModalError}
                setShowModal={setShowModal}
                fetchNewData={fetchGnojenjeData}
                briseSe={"JABLANA_GNOJENJE"}
                jeTrenutnoLeto={
                  new Date(item.datum).getFullYear() ===
                  new Date().getFullYear()
                }
                children={
                  <>
                    <td>
                      <EditableCell
                        initialValue={item.datum}
                        inputType={"date"}
                        id={item.id}
                        link={"jablana/gnojenje"}
                        imeLastnosti={"datum"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchGnojenjeData}
                        spreminjaSe={"GNOJENJE_DATUM"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.gnojilo}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/gnojenje"}
                        imeLastnosti={"gnojilo"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchGnojenjeData}
                        spreminjaSe={"GNOJENJE_GNOJILO"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.dozaNaNasad}
                        inputType={"number"}
                        id={item.id}
                        link={"jablana/gnojenje"}
                        imeLastnosti={"dozaNaNasad"}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                        step={0.1}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchGnojenjeData}
                        spreminjaSe={"GNOJENJE_DOZA_NA_NASAD"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td key={item.dozaNaNasad}>{item.dozaNaHa}</td>
                    <td>
                      <EditableCell
                        initialValue={item.izvajalecGnojenja}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/gnojenje"}
                        imeLastnosti={"izvajalecGnojenja"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchGnojenjeData}
                        spreminjaSe={"GNOJENJE_IZVAJALEC_GNOJENJA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.odgovornaOseba}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/gnojenje"}
                        imeLastnosti={"odgovornaOseba"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchGnojenjeData}
                        spreminjaSe={"GNOJENJE_ODGOVORNA_OSEBA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.uporabljenaMehanizacija}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/gnojenje"}
                        imeLastnosti={"uporabljenaMehanizacija"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchGnojenjeData}
                        spreminjaSe={"GNOJENJE_UPORABLJENA_MEHANIZACIJA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                  </>
                }
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default JablanaGnojenjeTabela;
