import React from "react";
import { Container, Row, Modal, Button, Col } from "react-bootstrap";

const ModalKateraKultura = ({
  izbranGERK,
  setShowAppleInputModal,
  setShowWhichFruitTypeModal,
  showWhichFruitTypeModal,
  setShowCherryInputModal,
  setShowBlueberryInputModal,
}) => {
  return (
    <Modal
      show={showWhichFruitTypeModal}
      onHide={() => setShowWhichFruitTypeModal(false)}
      onExited={() => setShowWhichFruitTypeModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>GERK-a {izbranGERK} ni na seznamu MKGP</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Izbranega GERK-a {izbranGERK} ni na seznamu intenzivnih sadovnjakov
          MKGP.
        </p>
        <p>
          Kljub temu lahko vodite poljuben obrazec, za GERK {izbranGERK}, četudi
          ga ni seznamu MKGP.
        </p>
        <p>Katera kultura raste na GERK-u {izbranGERK}?</p>
      </Modal.Body>

      <Modal.Footer>
        <Container className="d-flex align-items-center justify-content-center g-3">
          <Row className="g-3">
            <Col>
              <Button
                variant="success"
                onClick={() => {
                  setShowWhichFruitTypeModal(false);
                  setShowAppleInputModal(true);
                }}
                style={{ height: "100%" }}
              >
                Jablana
              </Button>
            </Col>
            <Col>
              <Button
                variant="danger"
                onClick={() => {
                  setShowWhichFruitTypeModal(false);
                  setShowCherryInputModal(true);
                }}
                style={{ height: "100%" }}
              >
                Češnja
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  setShowWhichFruitTypeModal(false);
                  setShowBlueberryInputModal(true);
                }}
                style={{ height: "100%" }}
              >
                Ameriška borovnica
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalKateraKultura;
