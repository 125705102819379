import React from "react";
import {
  Container,
  Row,
  Modal,
  Button,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";

const ModalCesnjaMetaPodatki = ({
    showCherryInputModal,
    setShowCherryInputModal,
    izbranGERK,
    uploadNewGerkData,
    refCesnjaLetoNasaditve,
    refCesnjaSteviloSadik,
    refCesnjaSorta,
    refCesnjaGojitvenaOblika,
    refCesnjaPodlaga,
    refCesnjaSrednjaRazdaljaMedVrstami,
    refCesnjaSrednjaRazdaljaVVrsti
  }) => {
  return (
    <Modal
      show={showCherryInputModal}
      onHide={() => setShowCherryInputModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Vnesite podatke o nasadu češnje na GERK-u {izbranGERK}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setShowCherryInputModal(false);
            uploadNewGerkData("ČEŠNJA");
          }}
        > 
          <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">
              Leto nasaditve sadovnjaka:
            </Form.Label>
            <Form.Control
              type="number"
              name="letoNasaditve"
              ref={refCesnjaLetoNasaditve}
              required
              step={1}
              min={1000}
              max={new Date().getFullYear()}
              placeholder="Vnesite letnico nasaditve sadovnjaka"
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">
              Število sadik/dreves v sadovnjaku:
            </Form.Label>
            <Form.Control
              type="number"
              name="letoNasaditve"
              ref={refCesnjaSteviloSadik}
              required
              min={1}
              step={1}
              placeholder="Vnesite število sadik/dreves v sadovnjaku"
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">
              Srednja razdalja med vrstami [m]:
            </Form.Label>
            <Form.Control
              type="number"
              name="letoNasaditve"
              ref={refCesnjaSrednjaRazdaljaMedVrstami}
              required
              min={0.0}
              step={0.1}
              placeholder="Vnesite srednjo razdaljo med vrstami [m] v sadovnjaku"
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">
              Srednja razdalja med drevesi v vrsti [m]:
            </Form.Label>
            <Form.Control
              type="number"
              name="letoNasaditve"
              ref={refCesnjaSrednjaRazdaljaVVrsti}
              required
              min={0.0}
              step={0.1}
              placeholder="Vnesite srednjo razdaljo med drevesi v vrstami [m] v sadovnjaku"
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="sorta">Sorta:</Form.Label>
            <Form.Control
              type="text"
              name="sorta"
              ref={refCesnjaSorta}
              required
              minLength={3}
              placeholder={`Vnesite sorto češnje, ki raste na GERK-u ${izbranGERK}`}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="sorta">Gojitvena oblika:</Form.Label>
            <Form.Control
              type="text"
              name="sorta"
              ref={refCesnjaGojitvenaOblika}
              required
              minLength={3}
              placeholder={`Vnesite gojitveno obliko češnje, ki raste na GERK-u ${izbranGERK}`}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="sorta">Podlaga:</Form.Label>
            <Form.Control
              type="text"
              name="sorta"
              ref={refCesnjaPodlaga}
              required
              minLength={3}
              placeholder={`Vnesite naziv podlage češnje, ki raste na GERK-u ${izbranGERK}`}
            ></Form.Control>
          </FormGroup>

          <FormGroup className="m-2">
            <Container>
              <Row className="g-3">
                <Col className="d-flex align-items-center justify-content-center">
                  <Button type="submit" variant="success">
                    <span className="btn-label">
                      <i className="bi bi-plus-circle m-1"></i>
                      Shrani podatke
                    </span>
                  </Button>
                </Col>
              </Row>
            </Container>
          </FormGroup>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCesnjaMetaPodatki;
