import React, { useState, useEffect, useCallback } from "react";
import { Container, Card } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherGet from "../../fetcher/fetcherGet";
import JablanaFertirigacijaTabela from "./JablanaFertirigacijaTabela";

const JablanaFertirigacijaPrikaz = ({
  izbranoLeto,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [vsaFertirigacija, setVsaFertirigacija] = useState({});

  const fetchFertitigacijaData = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/fertirigacija",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setVsaFertirigacija
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchFertitigacijaData(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);
  return (
    <Card style={{ backgroundColor: "LightBlue " }}>
      <Card.Body>
        <Container>
          <h3>Pregled podatkov o fertirigaciji nasada jablan</h3>

          <JablanaFertirigacijaTabela
            vsaFertirigacija={
              Object.keys(vsaFertirigacija).length > 0 &&
              vsaFertirigacija?.skupnoPolje?.filter((e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              })
            }
            fetchFertitigacijaData={fetchFertitigacijaData}
            setModalError={setModalError}
            setShowModal={setShowModal}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />
        </Container>
      </Card.Body>
      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </Card>
  );
};

export default JablanaFertirigacijaPrikaz;
