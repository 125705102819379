import React, { useState, useEffect } from "react";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import ocenaCvetenjaBesedilo from "../../../ocene/ocenaCvetenjaBesedilo";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import EditableCell from "../../EditableCell";
import fetcherPost from "../../fetcher/fetcherPost";
import DeletionRow from "../../DeletionRow";

const JablanaOcenaCvetenja = ({
  ocenaCvetenja,
  setModalError,
  setShowModal,
  fetchBloomingData,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [izbranaOcenaCvetenja, setIzbranaOcenaCvetenja] = useState(0);
  const [izbranaOcenaCvetenjaDatum, setIzbranaOcenaCvetenjaDatum] = useState(
    new Date().toISOString().slice(0, 10)
  );

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(ocenaCvetenja?.length > 0 ? false : true);
  }, [ocenaCvetenja]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "lightgreen" }}>
        <Card.Header as="h5">Ocena cvetenja</Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "lightgreen" }}>
                    Datum ocenjevanja
                  </th>
                  <th style={{ backgroundColor: "lightgreen" }}>Ocena</th>
                  {ocenaCvetenja[0]?.datum &&
                  new Date(ocenaCvetenja[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "lightgreen" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>

              <tbody>
                {ocenaCvetenja &&
                  ocenaCvetenja.map((item) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"jablana/cvetenje"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchBloomingData}
                      briseSe={"OCENA_CVETENJA"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={item.datum}
                              inputType={"date"}
                              id={item.id}
                              link={"jablana/cvetenje"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchBloomingData}
                              spreminjaSe={"CVETENJE_CVETENJE_DATUM"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>

                          <td>
                            <EditableCell
                              initialValue={item.ocena}
                              inputType={"number"}
                              id={item.id}
                              link={"jablana/cvetenje"}
                              imeLastnosti={"ocena"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchBloomingData}
                              min={1}
                              max={10}
                              step={1}
                              spreminjaSe={"CVETENJE_CVETENJE_OCENA"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              e.target.reset();

              await fetcherPost(
                `jablana/cvetenje`,
                {
                  ocena: izbranaOcenaCvetenja,
                  datum: izbranaOcenaCvetenjaDatum,
                  tipOcene: "ocenaCvetenja",
                },
                nasadId,
                setModalError,
                setShowModal,
                signOut,
                history
              );

              setIzbranaOcenaCvetenja(0);
              setIzbranaOcenaCvetenjaDatum(
                new Date().toISOString().slice(0, 10)
              );
              fetchBloomingData();
            }}
          >
            <FormGroup className="m-2">
              <Form.Label htmlFor="ocenaCvetenja">Ocena cvetenja:</Form.Label>
              <Form.Select
                aria-label="Ocena cvetenja"
                name="ocenaCvetenja"
                required
                onChange={(e) => setIzbranaOcenaCvetenja(e.target.value)}
                value={izbranaOcenaCvetenja}
              >
                <option hidden>Izberite oceno cvetenja</option>
                {ocenaCvetenjaBesedilo.map((ocena, index) => (
                  <option key={Math.random()} value={index + 1}>
                    {ocena}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-muted">
                Izberite oceno cvetenja ob polnem cvetenju od 1 do 10
              </Form.Text>
            </FormGroup>

            <FormGroup className="m-2">
              <Form.Label htmlFor="ocenaCvetenjaDatum">Datum:</Form.Label>
              <Form.Control
                id="ocenaCvetenjaDatum"
                type="date"
                value={izbranaOcenaCvetenjaDatum}
                min={`${new Date().getFullYear()}-01-01`}
                max={new Date().toISOString().slice(0, 10)}
                onChange={(e) => setIzbranaOcenaCvetenjaDatum(e.target.value)}
                placeholder="Vnesite datum ocenjevanja cvetenja"
              />
              <Form.Text className="text-muted">
                Izberite datum ocenjevanja cvetenja
              </Form.Text>
            </FormGroup>

            <FormGroup className="m-2">
              <Container>
                <Row className="g-3">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Shrani oceno cvetenja
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default JablanaOcenaCvetenja;
