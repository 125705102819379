import React, { useCallback, useEffect, useState } from "react";
import { Container, Card, Row, Table } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../../fetcher/fetcherGet";
import EditableCell from "../../EditableCell";

const BorovnicaTla = () => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [podatkiTla, setPodatkiTla] = useState({});

  const fetchPodatkeTla = useCallback(
    (signal) => {
      return fetcherGet(
        "borovnica/tla",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setPodatkiTla
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchPodatkeTla(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Container>
            <Row>
              <Card className="m-1" style={{ backgroundColor: "DarkSalmon" }}>
                <Card.Header>Tla v nasadu</Card.Header>

                <Card.Body>
                  <div className="d-flex align-items-center justify-content-center">
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th
                            style={{ backgroundColor: "DarkSalmon" }}
                            colSpan={2}
                            className="text-center"
                          >
                            <b>Tla</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Tip tal</td>
                          <td>
                            <EditableCell
                              key={podatkiTla.tipTal}
                              initialValue={podatkiTla.tipTal ?? "Ni podatkov o tipu tal"}
                              inputType={"text"}
                              id={podatkiTla.id}
                              link={"borovnica/tla"}
                              imeLastnosti={"tipTal"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeTla}
                              spreminjaSe={"TLA_TIP"}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>% organske snovi</td>
                          <td>
                            <EditableCell
                              initialValue={podatkiTla.delezOrganskeSnovi ?? "Ni podatkov o deležu organske snovi"}
                              key={podatkiTla.delezOrganskeSnovi}
                              inputType={"number"}
                              id={podatkiTla.id}
                              link={"borovnica/tla"}
                              imeLastnosti={"delezOrganskeSnovi"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeTla}
                              spreminjaSe={"TLA_DELEZ"}
                              step={1}
                              min={0}
                              max={100}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>Mg / Ca / K /P</td>
                          <td>
                            <EditableCell
                              initialValue={podatkiTla.mgCaKP ?? "Ni podatkov o Mg / Ca / K / P"}
                              key={podatkiTla.mgCaKP}
                              inputType={"text"}
                              id={podatkiTla.id}
                              link={"borovnica/tla"}
                              imeLastnosti={"mgCaKP"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeTla}
                              spreminjaSe={"TLA_MG"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>pH</td>
                          <td>
                            <EditableCell
                              initialValue={podatkiTla.pH ?? "Ni podatkov o pH"}
                              key={podatkiTla.pH}
                              inputType={"number"}
                              id={podatkiTla.id}
                              link={"borovnica/tla"}
                              imeLastnosti={"pH"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeTla}
                              spreminjaSe={"TLA_PH"}
                              step={0.1}
                              min={0}
                              max={14}
                            />

                          </td>
                        </tr>

                        <tr>
                          <td>Založno gnojenje</td>
                          <td>
                            <EditableCell
                              initialValue={podatkiTla.zaloznoGnojenje ?? "Ni podatkov o založnem gnojenju"}
                              inputType={"text"}
                              key={podatkiTla.zaloznoGnojenje}
                              id={podatkiTla.id}
                              link={"borovnica/tla"}
                              imeLastnosti={"zaloznoGnojenje"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeTla}
                              spreminjaSe={"TLA_ZALOZNO_GNOJENJE"}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Row>
          </Container>
        </Card.Body>
      </Card>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default BorovnicaTla;
