import React from "react";
import {
  Container,
  Row,
  Modal,
  Button,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import {
  JE_NAMAKAN_NASAD,
  NI_NAMAKAN_NASAD,
  RAZREDI_VISIN_JABLAN,
} from "../../constants/constans";

const ModalJablanaMetapodatki = ({
  showAppleInputModal,
  setShowAppleInputModal,
  izbranGERK,
  uploadNewGerkData,
  refJablanaLetoNasaditve,
  refJablanaSteviloSadik,
  refJablanaSorta,
  refJablanaSrednjaRazdaljaVVrsti,
  refJablanaSrednjaRazdaljaMedVrstami,
  jeNamakanNasad,
  setJeNamakanNasad,
  razredVisinDreves,
  setRazredVisinDreves,
}) => {
  return (
    <Modal
      show={showAppleInputModal}
      onHide={() => setShowAppleInputModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Vnesite podatke o nasadu jablane na GERK-u {izbranGERK}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setShowAppleInputModal(false);
            uploadNewGerkData("JABLANA");
          }}
        >
          {/* <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">
              Število dreves na hektar:
            </Form.Label>
            <Form.Control
              type="number"
              name="drevesaNaHa"
              ref={refJablanaDrevesaNaHa}
              required
              min={0.0}
              step={0.1}
              placeholder="Vnesite število dreves na hektar"
            ></Form.Control>
          </FormGroup> */}
          <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">
              Leto nasaditve sadovnjaka:
            </Form.Label>
            <Form.Control
              type="number"
              name="letoNasaditve"
              ref={refJablanaLetoNasaditve}
              required
              min={1000}
              step={1}
              max={new Date().getFullYear()}
              placeholder="Vnesite letnico nasaditve sadovnjaka"
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">
              Število sadik/dreves v sadovnjaku:
            </Form.Label>
            <Form.Control
              type="number"
              name="letoNasaditve"
              ref={refJablanaSteviloSadik}
              required
              min={1}
              step={1}
              placeholder="Vnesite število sadik/dreves v sadovnjaku"
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="sorta">Sorta:</Form.Label>
            <Form.Control
              type="text"
              name="sorta"
              ref={refJablanaSorta}
              required
              minLength={3}
              placeholder={`Vnesite sorto jablane, ki raste na GERK-u ${izbranGERK}`}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">
              Srednja razdalja med vrstami [m]:
            </Form.Label>
            <Form.Control
              type="number"
              name="letoNasaditve"
              ref={refJablanaSrednjaRazdaljaMedVrstami}
              required
              min={0.0}
              step={0.1}
              placeholder="Vnesite srednjo razdaljo med vrstami [m] v sadovnjaku"
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">
              Srednja razdalja med drevesi v vrsti [m]:
            </Form.Label>
            <Form.Control
              type="number"
              name="letoNasaditve"
              ref={refJablanaSrednjaRazdaljaVVrsti}
              required
              min={0.0}
              step={0.1}
              placeholder="Vnesite srednjo razdaljo med drevesi v vrstami [m] v sadovnjaku"
            ></Form.Control>
          </FormGroup>

          <FormGroup className="m-2">
            <Form.Label htmlFor="jeNamakanNasad">Je nasad namakan:</Form.Label>
            <br />
            <Form.Check
              inline
              label="Je namakan"
              name={JE_NAMAKAN_NASAD}
              value={JE_NAMAKAN_NASAD}
              checked={jeNamakanNasad === JE_NAMAKAN_NASAD}
              type={"radio"}
              onChange={(e) => setJeNamakanNasad(e.target.value)}
            />
            <Form.Check
              inline
              label="Ni namakan"
              name={NI_NAMAKAN_NASAD}
              value={NI_NAMAKAN_NASAD}
              checked={jeNamakanNasad === NI_NAMAKAN_NASAD}
              type={"radio"}
              onChange={(e) => setJeNamakanNasad(e.target.value)}
            />
          </FormGroup>

          <FormGroup className="m-2">
            <Form.Label htmlFor="razredVisinDreves">
              Razred višine dreves (m):
            </Form.Label>
            <Form.Select
              name="razredVisinDreves"
              aria-label="Izberite razred višine dreve (m)"
              required
              onChange={(e) => setRazredVisinDreves(e.target.value)}
              value={razredVisinDreves}
            >
              <option hidden>Izberite razred višine dreve (m)</option>
              {RAZREDI_VISIN_JABLAN.map((visina, index) => (
                <option key={index} value={visina}>
                  {visina}
                </option>
              ))}
            </Form.Select>
            <Form.Text className="text-muted">
              Izberite razred višine dreves v nasadu (m)
            </Form.Text>
          </FormGroup>

          <FormGroup className="m-2">
            <Container>
              <Row className="g-3">
                <Col className="d-flex align-items-center justify-content-center">
                  <Button type="submit" variant="success">
                    <span className="btn-label">
                      <i className="bi bi-plus-circle m-1"></i>
                      Shrani podatke
                    </span>
                  </Button>
                </Col>
              </Row>
            </Container>
          </FormGroup>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalJablanaMetapodatki;
