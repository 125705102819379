import React from "react";
import {
  FormGroup,
  Form,
  InputGroup,
  Container,
  Button,
  Row,
  Col,
} from "react-bootstrap";
const AnalizaZrelostnegaStanjaForma = ({
  title,
  setAnalizaZrelostnegaStanja,
  analizaZrelostnegaStanja,
  submitFunction,
  tip,
}) => {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        submitFunction(tip);
      }}
    >
      <Form.Label className="m-2" htmlFor="analizaZrelostnegaStanja">
        <h4>Analiza zrelostnega stanja {title}:</h4>
      </Form.Label>
      <FormGroup className="m-2">
        <InputGroup>
          <InputGroup.Text htmlFor="trdota">Trdota v kg/cm²:</InputGroup.Text>
          <Form.Control
            type="number"
            name="trdota"
            value={analizaZrelostnegaStanja.trdota}
            required
            min={0}
            step={0.1}
            onChange={(e) =>
              setAnalizaZrelostnegaStanja({
                trdota: e.target.value,
                tss: analizaZrelostnegaStanja.tss,
                skrobniIndeks: analizaZrelostnegaStanja.skrobniIndeks,
              })
            }
          />
          <InputGroup.Text htmlFor="tss">TSS v °Brix:</InputGroup.Text>
          <Form.Control
            type="number"
            name="tss"
            value={analizaZrelostnegaStanja.tss}
            required
            min={0}
            step={0.1}
            onChange={(e) =>
              setAnalizaZrelostnegaStanja({
                trdota: analizaZrelostnegaStanja.trdota,
                tss: e.target.value,
                skrobniIndeks: analizaZrelostnegaStanja.skrobniIndeks,
              })
            }
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="m-2">
        <InputGroup>
          <InputGroup.Text htmlFor="tss">Škrobni indeks:</InputGroup.Text>
          <Form.Select
            name="skrobniIndeks"
            aria-label="Škrobni indeks"
            required
            onChange={(e) =>
              setAnalizaZrelostnegaStanja({
                trdota: analizaZrelostnegaStanja.trdota,
                tss: analizaZrelostnegaStanja.tss,
                skrobniIndeks: e.target.value,
              })
            }
            value={analizaZrelostnegaStanja.skrobniIndeks}
          >
            <option hidden>Izberite škrobni indeks od 1 do 10</option>
            {Array.from({ length: 10 }, (_, i) => i + 1).map((index) => (
              <option key={Math.random()} value={index}>
                {index}
              </option>
            ))}
          </Form.Select>

          <InputGroup.Text id="datumOcene">Datum:</InputGroup.Text>
          <Form.Control
            type="date"
            value={analizaZrelostnegaStanja.datum}
            onChange={(e) =>
              setAnalizaZrelostnegaStanja({
                trdota: analizaZrelostnegaStanja.trdota,
                tss: analizaZrelostnegaStanja.tss,
                skrobniIndeks: analizaZrelostnegaStanja.skrobniIndeks,
                datum: e.target.value,
              })
            }
            min={`${new Date().getFullYear()}-01-01`}
            max={new Date().toISOString().slice(0, 10)}
            placeholder="Datum analize zrelostnega stanja"
            aria-label="Datum analize zrelostnega stanja"
            aria-describedby="Datum analize zrelostnega stanja"
            required
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="m-2">
        <Container>
          <Row className="g-3">
            <Col className="d-flex align-items-center justify-content-center">
              <Button type="submit" variant="success">
                <span className="btn-label">
                  <i className="bi bi-plus-circle m-1"></i>
                  Shrani
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </FormGroup>
    </Form>
  );
};

export default AnalizaZrelostnegaStanjaForma;
