import React, { useState, createContext, useEffect } from "react";

const CommonContext = createContext({});

export const CommonProvider = ({ children }) => {
  const NASAD_ID_LS_KEY = "nasadId";
  const CURR_GERK_LS_KEY = "currGerk";

  const initNasadId = () => {
    const storedNasadId = localStorage.getItem(NASAD_ID_LS_KEY);
    return storedNasadId ? storedNasadId : -1;
  };

  const initGerkId = () => {
    const storedCurrGerk = localStorage.getItem(CURR_GERK_LS_KEY);
    return storedCurrGerk ? storedCurrGerk : -1;
  };

  const [nasadId, setNasadId] = useState(initNasadId());
  const [currGerk, setCurrGerk] = useState(initGerkId());

  useEffect(() => {
    localStorage.setItem(NASAD_ID_LS_KEY, nasadId);
    localStorage.setItem(CURR_GERK_LS_KEY, currGerk);
  }, [nasadId, currGerk]);

  return (
    <CommonContext.Provider
      value={{
        nasadId: nasadId,
        setNasadId: setNasadId,
        currGerk: currGerk,
        setCurrGerk: setCurrGerk,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;
