import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherPost from "../../fetcher/fetcherPost";
import EditableCell from "../../EditableCell";
import { Trash } from "react-bootstrap-icons";
import fetcherDelete from "../../fetcher/fetcherDelete";
import { FENOFAZE_CESNJE } from "../../../constants/constans";

const CesnjaFenofaze = ({
  fetchPodatkeOcenjevanjeInNapoved,
  setShowModal,
  setModalError,
  fenofazeCesnje,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const narediPrimernoPoljeZaTabelo = () => {
    const primernoPolje = [];

    const tempPolje = [
      fenofazeCesnje.filter((e) => {
        return e.tipFenofaze === "03";
      }).length,
      fenofazeCesnje.filter((e) => {
        return e.tipFenofaze === "61";
      }).length,
      fenofazeCesnje.filter((e) => {
        return e.tipFenofaze === "65";
      }).length,
      fenofazeCesnje.filter((e) => {
        return e.tipFenofaze === "69";
      }).length,
      fenofazeCesnje.filter((e) => {
        return e.tipFenofaze === "87";
      }).length,
    ];

    const dolzina = Math.max(...tempPolje);
    // const dolzina =
    //   fenofazeCesnje.length < 5
    //     ? 1
    //     : Math.max(...tempPolje);

    for (let _ = 0; _ < dolzina; _++) {
      primernoPolje.push({
        ff03: {
          datum: "",
          id: -1,
        },
        ff61: {
          datum: "",
          id: -1,
        },
        ff65: {
          datum: "",
          id: -1,
        },
        ff69: {
          datum: "",
          id: -1,
        },
        ff87: {
          datum: "",
          id: -1,
        },
      });
    }

    for (const fenofaza of fenofazeCesnje) {
      for (let index = 0; index < primernoPolje.length; index++) {
        let breakLoop = false;

        switch (fenofaza.tipFenofaze) {
          case "03": {
            if (primernoPolje[index].ff03.datum === "") {
              primernoPolje[index].ff03.datum = fenofaza.datum;
              primernoPolje[index].ff03.id = fenofaza.id;
              breakLoop = true;
            }
            break;
          }
          case "61": {
            if (primernoPolje[index].ff61.datum === "") {
              primernoPolje[index].ff61.datum = fenofaza.datum;
              primernoPolje[index].ff61.id = fenofaza.id;
              breakLoop = true;
            }
            break;
          }
          case "65": {
            if (primernoPolje[index].ff65.datum === "") {
              primernoPolje[index].ff65.datum = fenofaza.datum;
              primernoPolje[index].ff65.id = fenofaza.id;
              breakLoop = true;
            }
            break;
          }
          case "69": {
            if (primernoPolje[index].ff69.datum === "") {
              primernoPolje[index].ff69.datum = fenofaza.datum;
              primernoPolje[index].ff69.id = fenofaza.id;
              breakLoop = true;
            }
            break;
          }
          case "87": {
            if (primernoPolje[index].ff87.datum === "") {
              primernoPolje[index].ff87.datum = fenofaza.datum;
              primernoPolje[index].ff87.id = fenofaza.id;
              breakLoop = true;
            }
            break;
          }

          default:
            break;
        }

        if (breakLoop) break;
      }
    }

    return primernoPolje;
  };

  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [izbranTipFenofaze, setIzbranTipFenofaze] = useState("");
  const [izbranDatumFenofaze, setIzbranDatumFenofaze] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const [indexTd, setIndexTd] = useState(null);

  useEffect(() => {
      setNiPodatkovZaIzbranoLeto(
        fenofazeCesnje?.length > 0 ? false : true
      )
  }, [fenofazeCesnje])
  

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "orange" }}>
        <Card.Header>Fenološke razvojne faze - BBCH</Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "orange" }}>03</th>
                  <th style={{ backgroundColor: "orange" }}>61</th>
                  <th style={{ backgroundColor: "orange" }}>65</th>
                  <th style={{ backgroundColor: "orange" }}>69</th>
                  <th style={{ backgroundColor: "orange" }}>87</th>
                </tr>
              </thead>

              <tbody>
                {fenofazeCesnje.length > 0 &&
                  narediPrimernoPoljeZaTabelo().map((item) => (
                    <tr key={item}>
                      {item.ff03.datum !== "" ? (
                        <td
                          onMouseEnter={() => setIndexTd(item.ff03.id)}
                          onMouseLeave={() => setIndexTd(null)}
                        >
                          <EditableCell
                            initialValue={item.ff03.datum}
                            inputType={"date"}
                            id={item.ff03.id}
                            link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                            imeLastnosti={"datum"}
                            setModalError={setModalError}
                            setShowModal={setShowModal}
                            fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                            spreminjaSe={"FENOFAZE_DATUM"}
                            jeTrenutnoLeto={
                              new Date(item.ff03.datum).getFullYear() ===
                              new Date().getFullYear()
                            }
                          />

                          {item.ff03.id === indexTd &&
                            new Date(item.ff03.datum).getFullYear() ===
                              new Date().getFullYear() && (
                              <Button
                                className="m-1"
                                variant="danger"
                                onClick={async () => {
                                  await fetcherDelete(
                                    "cesnja/ocenjavanjeInNapovedovanjePridelka",
                                    item.ff03.id,
                                    "FENOFAZE",
                                    setModalError,
                                    setShowModal,
                                    signOut,
                                    history
                                  );

                                  fetchPodatkeOcenjevanjeInNapoved(
                                    new AbortController().signal
                                  );
                                }}
                              >
                                <Trash className="hover-icon" />
                              </Button>
                            )}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {item.ff61.datum !== "" ? (
                        <td
                          onMouseEnter={() => setIndexTd(item.ff61.id)}
                          onMouseLeave={() => setIndexTd(null)}
                        >
                          <EditableCell
                            initialValue={item.ff61.datum}
                            inputType={"date"}
                            id={item.ff61.id}
                            link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                            imeLastnosti={"datum"}
                            setModalError={setModalError}
                            setShowModal={setShowModal}
                            fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                            spreminjaSe={"FENOFAZE_DATUM"}
                            jeTrenutnoLeto={
                              new Date(item.ff61.datum).getFullYear() ===
                              new Date().getFullYear()
                            }
                          />

                          {item.ff61.id === indexTd &&
                            new Date(item.ff61.datum).getFullYear() ===
                              new Date().getFullYear() && (
                              <Button
                                className="m-1"
                                variant="danger"
                                onClick={async () => {
                                  await fetcherDelete(
                                    "cesnja/ocenjavanjeInNapovedovanjePridelka",
                                    item.ff61.id,
                                    "FENOFAZE",
                                    setModalError,
                                    setShowModal,
                                    signOut,
                                    history
                                  );

                                  fetchPodatkeOcenjevanjeInNapoved(
                                    new AbortController().signal
                                  );
                                }}
                              >
                                <Trash className="hover-icon" />
                              </Button>
                            )}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {item.ff65.datum !== "" ? (
                        <td
                          onMouseEnter={() => setIndexTd(item.ff65.id)}
                          onMouseLeave={() => setIndexTd(null)}
                        >
                          <EditableCell
                            initialValue={item.ff65.datum}
                            inputType={"date"}
                            id={item.ff65.id}
                            link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                            imeLastnosti={"datum"}
                            setModalError={setModalError}
                            setShowModal={setShowModal}
                            fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                            spreminjaSe={"FENOFAZE_DATUM"}
                            jeTrenutnoLeto={
                              new Date(item.ff65.datum).getFullYear() ===
                              new Date().getFullYear()
                            }
                          />

                          {item.ff65.id === indexTd &&
                            new Date(item.ff65.datum).getFullYear() ===
                              new Date().getFullYear() && (
                              <Button
                                className="m-1"
                                variant="danger"
                                onClick={async () => {
                                  await fetcherDelete(
                                    "cesnja/ocenjavanjeInNapovedovanjePridelka",
                                    item.ff65.id,
                                    "FENOFAZE",
                                    setModalError,
                                    setShowModal,
                                    signOut,
                                    history
                                  );

                                  fetchPodatkeOcenjevanjeInNapoved(
                                    new AbortController().signal
                                  );
                                }}
                              >
                                <Trash className="hover-icon" />
                              </Button>
                            )}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {item.ff69.datum !== "" ? (
                        <td
                          onMouseEnter={() => setIndexTd(item.ff69.id)}
                          onMouseLeave={() => setIndexTd(null)}
                        >
                          <EditableCell
                            initialValue={item.ff69.datum}
                            inputType={"date"}
                            id={item.ff69.id}
                            link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                            imeLastnosti={"datum"}
                            setModalError={setModalError}
                            setShowModal={setShowModal}
                            fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                            spreminjaSe={"FENOFAZE_DATUM"}
                            jeTrenutnoLeto={
                              new Date(item.ff69.datum).getFullYear() ===
                              new Date().getFullYear()
                            }
                          />

                          {item.ff69.id === indexTd &&
                            new Date(item.ff69.datum).getFullYear() ===
                              new Date().getFullYear() && (
                              <Button
                                className="m-1"
                                variant="danger"
                                onClick={async () => {
                                  await fetcherDelete(
                                    "cesnja/ocenjavanjeInNapovedovanjePridelka",
                                    item.ff69.id,
                                    "FENOFAZE",
                                    setModalError,
                                    setShowModal,
                                    signOut,
                                    history
                                  );

                                  fetchPodatkeOcenjevanjeInNapoved(
                                    new AbortController().signal
                                  );
                                }}
                              >
                                <Trash className="hover-icon" />
                              </Button>
                            )}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {item.ff87.datum !== "" ? (
                        <td
                          onMouseEnter={() => setIndexTd(item.ff87.id)}
                          onMouseLeave={() => setIndexTd(null)}
                        >
                          <EditableCell
                            initialValue={item.ff87.datum}
                            inputType={"date"}
                            id={item.ff87.id}
                            link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                            imeLastnosti={"datum"}
                            setModalError={setModalError}
                            setShowModal={setShowModal}
                            fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                            spreminjaSe={"FENOFAZE_DATUM"}
                            jeTrenutnoLeto={
                              new Date(item.ff87.datum).getFullYear() ===
                              new Date().getFullYear()
                            }
                          />

                          {item.ff87.id === indexTd &&
                            new Date(item.ff87.datum).getFullYear() ===
                              new Date().getFullYear() && (
                              <Button
                                className="m-1"
                                variant="danger"
                                onClick={async () => {
                                  await fetcherDelete(
                                    "cesnja/ocenjavanjeInNapovedovanjePridelka",
                                    item.ff87.id,
                                    "FENOFAZE",
                                    setModalError,
                                    setShowModal,
                                    signOut,
                                    history
                                  );

                                  fetchPodatkeOcenjevanjeInNapoved(
                                    new AbortController().signal
                                  );
                                }}
                              >
                                <Trash className="hover-icon" />
                              </Button>
                            )}
                        </td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <Card className="m-1" style={{ backgroundColor: "orange" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();

                  if (!FENOFAZE_CESNJE.includes(izbranTipFenofaze)) {
                    setShowModal(true);
                    setModalError(
                      `Izbrana fenološka faza ni pravilna. Izberite nekaj do naslednjega: 03, 61, 65, 69, 87!`
                    );

                    return;
                  }

                  await fetcherPost(
                    `cesnja/ocenjavanjeInNapovedovanjePridelka`,
                    {
                      tipFenofaze: izbranTipFenofaze,
                      datum: izbranDatumFenofaze,
                      tip: "FENOFAZA",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setIzbranDatumFenofaze(new Date().toISOString().slice(0, 10));
                  setIzbranTipFenofaze("");

                  fetchPodatkeOcenjevanjeInNapoved();
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="fenoloskaFaza">
                    Fenološka faza - BBCH:
                  </Form.Label>
                  <Form.Select
                    name="fenoloskaFaza"
                    aria-label="Izberite fenološko fazo - BBCH"
                    required
                    onChange={(e) => setIzbranTipFenofaze(e.target.value)}
                    value={izbranTipFenofaze}
                  >
                    <option hidden>
                      Izberite fenološko fazo - BBCH češnje
                    </option>
                    {FENOFAZE_CESNJE.map((fazaBBCH, index) => (
                      <option key={index} value={fazaBBCH}>
                        {fazaBBCH}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Text className="text-muted">
                    Izberite fenolosko fazo - BBCH češnje v nasadu
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="datumFenofaze">Datum:</Form.Label>
                  <Form.Control
                    id="datumFenofaze"
                    type="date"
                    value={izbranDatumFenofaze}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setIzbranDatumFenofaze(e.target.value)}
                    placeholder="Vnesite datum posamezne fenofaze"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum ocenjevanja posamezne fenofaze
                  </Form.Text>
                </FormGroup>
                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CesnjaFenofaze;
