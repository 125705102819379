import React, { useEffect, useState } from "react";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  InputGroup,
  Table,
} from "react-bootstrap";
import EditableCell from "../../EditableCell";
import fetcherPost from "../../fetcher/fetcherPost";
import DeletionRow from "../../DeletionRow";

const JablanaUspesnostRedcenja = ({
  ocenaUspesnostiRedcenja,
  setModalError,
  setShowModal,
  fetchRazvojPlodicevData,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [
    izbranDatumOceneUspesnostiRedcenja,
    setIzbranDatumOceneUspesnostiRedcenja,
  ] = useState(new Date().toISOString().slice(0, 10));

  const [izbranaOcenaUspesnostiRedcenja, setIzbranaOcenaUspesnostiRedcenja] =
    useState(0);

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(
      ocenaUspesnostiRedcenja?.length > 0 ? false : true
    );
  }, [ocenaUspesnostiRedcenja]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "Ivory" }}>
        <Card.Header as="h5">
          Rezultat redčenja ob premeru plodičev 20 mm
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "Ivory" }}>
                    Datum ocenjevanja
                  </th>
                  <th style={{ backgroundColor: "Ivory" }}>Ocena</th>
                  {ocenaUspesnostiRedcenja[0]?.datum &&
                  new Date(ocenaUspesnostiRedcenja[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "Ivory" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {ocenaUspesnostiRedcenja &&
                  ocenaUspesnostiRedcenja.map((item) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"jablana/razvojPlodicev"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchRazvojPlodicevData}
                      briseSe={"USPESNOST_REDCENJE"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={item.datum}
                              inputType={"date"}
                              id={item.id}
                              link={"jablana/razvojPlodicev"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchRazvojPlodicevData}
                              spreminjaSe={"REDCENJE_DATUM"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={item.ocena}
                              inputType={"number"}
                              id={item.id}
                              link={"jablana/razvojPlodicev"}
                              imeLastnosti={"ocena"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchRazvojPlodicevData}
                              spreminjaSe={"REDCENJE_OCENA"}
                              min={0}
                              step={1}
                              max={Number.MAX_SAFE_INTEGER}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              e.preventDefault();
              e.target.reset();

              await fetcherPost(
                `jablana/razvojPlodicev`,
                {
                  ocena: izbranaOcenaUspesnostiRedcenja,
                  datum: izbranDatumOceneUspesnostiRedcenja,
                  tip: "ocenaUspesnostiRedcenja",
                },
                nasadId,
                setModalError,
                setShowModal,
                signOut,
                history
              );
              setIzbranaOcenaUspesnostiRedcenja(0);
              setIzbranDatumOceneUspesnostiRedcenja(
                new Date().toISOString().slice(0, 10)
              );
              fetchRazvojPlodicevData();
            }}
          >
            <FormGroup className="m-2">
              <Form.Label htmlFor="ocenaUspesnostiRedcenja">
                Rezultat redčenja:
              </Form.Label>
              <InputGroup>
                <InputGroup.Text id="datumOcene">
                  Datum ocene uspešnosti redčenja
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  value={izbranDatumOceneUspesnostiRedcenja}
                  onChange={(e) =>
                    setIzbranDatumOceneUspesnostiRedcenja(e.target.value)
                  }
                  min={`${new Date().getFullYear()}-01-01`}
                  max={new Date().toISOString().slice(0, 10)}
                  placeholder="Datum ocene uspšnosti redčenja"
                  aria-label="Datum ocene uspšnosti redčenja"
                  aria-describedby="Datum ocene uspšnosti redčenja"
                  required
                />

                <InputGroup.Text id="datumOcene">
                  Ocena uspešnosti redčenja
                </InputGroup.Text>

                <Form.Control
                  type="number"
                  value={izbranaOcenaUspesnostiRedcenja}
                  onChange={(e) =>
                    setIzbranaOcenaUspesnostiRedcenja(e.target.value)
                  }
                  min={0}
                  placeholder="Ocene uspšnosti redčenja"
                  aria-label="Ocene uspšnosti redčenja"
                  aria-describedby="Ocene uspšnosti redčenja"
                  required
                />
              </InputGroup>
              <Form.Text className="text-muted">
                Izberite oceno uspešnosti redčenja in datum ocenjevanja
              </Form.Text>
            </FormGroup>

            <FormGroup className="m-2">
              <Container>
                <Row className="g-3">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Shrani oceno uspešnosti redčenja
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default JablanaUspesnostRedcenja;
