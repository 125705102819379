import React from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import { useEffect } from "react";

const JablanaFertirigacijaTabela = ({
  vsaFertirigacija,
  fetchFertitigacijaData,
  setModalError,
  setShowModal,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsaFertirigacija?.length > 0 ? false : true);
  }, [vsaFertirigacija]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ backgroundColor: "LightBlue " }} colSpan={2}>
              Fertirigacija
            </th>
            <th style={{ backgroundColor: "LightBlue " }} colSpan={8}>
              Uporabljena mešanica
            </th>
          </tr>

          <tr>
            <th style={{ backgroundColor: "LightBlue " }} rowSpan={2}>
              Datum fertirigacije
            </th>
            <th style={{ backgroundColor: "LightBlue " }} rowSpan={2}>
              Doza (m^3/ha)
            </th>
            <th style={{ backgroundColor: "LightBlue " }} rowSpan={2}>
              Datum priprave mešanice
            </th>
            <th style={{ backgroundColor: "LightBlue " }} rowSpan={2}>
              Količina vode (l)
            </th>
            <th style={{ backgroundColor: "LightBlue " }} rowSpan={2}>
              Količina gnojila (kg)
            </th>
            <th style={{ backgroundColor: "LightBlue " }} rowSpan={2}>
              Gnojilo
            </th>
            <th style={{ backgroundColor: "LightBlue " }} colSpan={3}>
              Delež (%)
            </th>
            {vsaFertirigacija &&
            vsaFertirigacija[0]?.datum &&
            new Date(vsaFertirigacija[0]?.datum).getFullYear() ===
              new Date().getFullYear() ? (
              <th style={{ backgroundColor: "LightBlue " }} rowSpan={2}>
                Izbris
              </th>
            ) : (
              <></>
            )}
          </tr>
          <tr>
            <th style={{ backgroundColor: "LightBlue " }}>N</th>
            <th style={{ backgroundColor: "LightBlue " }}>P</th>
            <th style={{ backgroundColor: "LightBlue " }}>K</th>
          </tr>
        </thead>
        <tbody>
          {vsaFertirigacija &&
            vsaFertirigacija.map((item) => (
              <DeletionRow
                id={item.fertirigacijaId}
                key={item.fertirigacijaId}
                link={"jablana/fertirigacija"}
                setModalError={setModalError}
                setShowModal={setShowModal}
                fetchNewData={fetchFertitigacijaData}
                briseSe={"JABLANA_FERIRIGACIJA"}
                jeTrenutnoLeto={
                  new Date(item.datum).getFullYear() ===
                  new Date().getFullYear()
                }
                children={
                  <>
                    <td>
                      <EditableCell
                        initialValue={item.datum}
                        inputType={"date"}
                        id={item.fertirigacijaId}
                        link={"jablana/fertirigacija"}
                        imeLastnosti={"datum"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchFertitigacijaData}
                        spreminjaSe={"FERTIRIGACIJA_DATUM"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.dozaFertirigacije}
                        inputType={"number"}
                        id={item.fertirigacijaId}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                        step={0.1}
                        link={"jablana/fertirigacija"}
                        imeLastnosti={"dozaFertirigacije"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchFertitigacijaData}
                        spreminjaSe={"FERTIRIGACIJA_DOZA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.datumMesanice}
                        inputType={"date"}
                        id={item.mesanicaId}
                        link={"jablana/fertirigacija"}
                        imeLastnosti={"datumMesanice"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchFertitigacijaData}
                        spreminjaSe={"FERTIRIGACIJA_DATUM_MESANICE"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.kolicinaVode}
                        inputType={"number"}
                        id={item.mesanicaId}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                        step={0.1}
                        link={"jablana/fertirigacija"}
                        imeLastnosti={"kolicinaVode"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchFertitigacijaData}
                        spreminjaSe={"FERTIRIGACIJA_KOLICINA_VODE"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.kolicinaGnojila}
                        inputType={"number"}
                        id={item.mesanicaId}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                        step={0.1}
                        link={"jablana/fertirigacija"}
                        imeLastnosti={"kolicinaGnojila"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchFertitigacijaData}
                        spreminjaSe={"FERTIRIGACIJA_KOLICINA_GNOJILA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.gnojilo}
                        inputType={"text"}
                        id={item.mesanicaId}
                        link={"jablana/fertirigacija"}
                        imeLastnosti={"gnojilo"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchFertitigacijaData}
                        spreminjaSe={"FERTIRIGACIJA_GNOJILO"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.delezN}
                        inputType={"number"}
                        id={item.mesanicaId}
                        min={0}
                        max={100}
                        step={1}
                        link={"jablana/fertirigacija"}
                        imeLastnosti={"delezN"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchFertitigacijaData}
                        spreminjaSe={"FERTIRIGACIJA_DELEZ_N"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.delezP}
                        inputType={"number"}
                        id={item.mesanicaId}
                        min={0}
                        max={100}
                        step={1}
                        link={"jablana/fertirigacija"}
                        imeLastnosti={"delezP"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchFertitigacijaData}
                        spreminjaSe={"FERTIRIGACIJA_DELEZ_P"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.delezK}
                        inputType={"number"}
                        id={item.mesanicaId}
                        min={0}
                        max={100}
                        step={1}
                        link={"jablana/fertirigacija"}
                        imeLastnosti={"delezK"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchFertitigacijaData}
                        spreminjaSe={"FERTIRIGACIJA_DELEZ_K"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                  </>
                }
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default JablanaFertirigacijaTabela;
