import React, { useState, useRef, useEffect } from "react";
import { Row, Table, Card, Form, Button } from "react-bootstrap";
import ModalKateraKultura from "../modals/ModalKateraKultura";
import ModalJablanaMetapodatki from "../modals/ModalJablanaMetapodatki";
import ModalCesnjaMetaPodatki from "../modals/ModalCesnjaMetaPodatki";
import ModalBorovnicaMetaPodatki from "../modals/ModalBorovnicaMetaPodatki";
import { JE_NAMAKAN_NASAD, JE_ENKRAT_RODNA } from "../../constants/constans";
import fetcherPost from "../fetcher/fetcherPost";

const TabelaGerkovBrezTrajnihNasadov = ({
  zdruzeniPodatki,
  setShowModal,
  setModalError,
  signOut,
  history,
  fetchZdruzeniPodatki,
}) => {
  const [showWhichFruitTypeModal, setShowWhichFruitTypeModal] = useState(false);
  const [izbranGERK, setIzbranGERK] = useState(-1);

  const [showAppleInputModal, setShowAppleInputModal] = useState(false);
  const [showCherryInputModal, setShowCherryInputModal] = useState(false);

  const [showBlueberryInputModal, setShowBlueberryInputModal] = useState(false);

  const [jeEnkratRodna, setJeEnkratRodna] = useState(JE_ENKRAT_RODNA);
  const [jeNamakanNasad, setJeNamakanNasad] = useState(JE_NAMAKAN_NASAD);
  const [razredVisinDreves, setRazredVisinDreves] = useState();

  const refJablanaSorta = useRef();

  const refJablanaSteviloSadik = useRef();
  const refJablanaLetoNasaditve = useRef();

  const refJablanaSrednjaRazdaljaMedVrstami = useRef();
  const refJablanaSrednjaRazdaljaVVrsti = useRef();

  const refCesnjaLetoNasaditve = useRef();
  const refCesnjaSteviloSadik = useRef();
  const refCesnjaSorta = useRef();
  const refCesnjaGojitvenaOblika = useRef();
  const refCesnjaPodlaga = useRef();
  const refCesnjaSrednjaRazdaljaMedVrstami = useRef();
  const refCesnjaSrednjaRazdaljaVVrsti = useRef();

  const refBorovnicaLetoNasaditve = useRef();
  const refBorovnicaStSadikNaTekociMeter = useRef();

  const refBorovnicaSorta = useRef();
  const refBorovnicaKvalitetaSadik = useRef();

  const refBorovnicaCasSajenja = useRef();
  const refBorovnicaRazdaljaMedVrstami = useRef();

  const [clickedRow, setClickedRow] = useState(null);

  useEffect(() => {
    if (showWhichFruitTypeModal === false) {
      const id = zdruzeniPodatki.find((e) => {
        return e.gerkPodatki.gerkId === izbranGERK;
      })?.gerkPodatki.id;

      if (!id || !clickedRow) return;

      console.log(
        `modalno okno za izbiro kulture ni vidno! izbran gerk: ${izbranGERK} id: ${id} ${clickedRow}`
      );

      clickedRow.checked = false;
      setClickedRow(null);
    }
  }, [showWhichFruitTypeModal, clickedRow, izbranGERK, zdruzeniPodatki]);

  const uploadNewGerkData = async (vrsta) => {
    setShowWhichFruitTypeModal(false);

    if (!izbranGERK || izbranGERK < 9999) {
      setModalError(`GERK ${izbranGERK} ni pravilen`);
      setShowModal(true);

      return;
    }

    let body = {};

    switch (vrsta) {
      case "JABLANA": {
        body = {
          drevesaNaHektar: 0.0,
          steviloSadik: refJablanaSteviloSadik.current.value,
          letoNasaditve: refJablanaLetoNasaditve.current.value,
          sorta: refJablanaSorta.current.value,
          vrsta: "JABLANA",
          srednjaRazdaljaMedVrstami:
            refJablanaSrednjaRazdaljaMedVrstami.current.value,
          srednjaRazdaljaVVrsti: refJablanaSrednjaRazdaljaVVrsti.current.value,
          jeNamakanNasad: jeNamakanNasad === JE_NAMAKAN_NASAD,
          razredVisinDreves: razredVisinDreves,
        };

        break;
      }

      case "ČEŠNJA": {
        body = {
          steviloSadik: refCesnjaSteviloSadik.current.value,
          letoNasaditve: refCesnjaLetoNasaditve.current.value,
          sorta: refCesnjaSorta.current.value,
          gojitvenaOblika: refCesnjaGojitvenaOblika.current.value,
          vrsta: "ČEŠNJA",
          podlaga: refCesnjaPodlaga.current.value,
          srednjaRazdaljaMedVrstami:
            refCesnjaSrednjaRazdaljaMedVrstami.current.value,
          srednjaRazdaljaVVrsti: refCesnjaSrednjaRazdaljaVVrsti.current.value,
        };
        break;
      }

      case "AMERIŠKA BOROVNICA": {
        body = {
          steviloSadikNaTekociMeter:
            refBorovnicaStSadikNaTekociMeter.current.value,
          razdaljaMedVrstami: refBorovnicaRazdaljaMedVrstami.current.value,
          sorta: refBorovnicaSorta.current.value,
          kvalitetaSadik: refBorovnicaKvalitetaSadik.current.value,
          letoNasaditve: refBorovnicaLetoNasaditve.current.value,
          casSajenja: refBorovnicaCasSajenja.current.value,
          jeEnkratRodna: jeEnkratRodna,
          vrsta: "AMERIŠKA BOROVNICA",
        };
        break;
      }

      default: {
        break;
      }
    }

    await fetcherPost(
      `midNumber`,
      body,
      izbranGERK,
      setModalError,
      setShowModal,
      signOut,
      history
    );

    fetchZdruzeniPodatki();
  };

  return (
    <>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Card className="m-1">
          <Card.Body>
            <Card.Title>Pregled GERK-ov brez trajnih nasadov</Card.Title>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Je v seznamu aktivnih nasadov?</th>
                  <th>GERK</th>
                  <th>Blok ID</th>
                  <th>Raba</th>
                  <th>Domače ime</th>
                  <th>Površina (m²)</th>
                  <th>NUP (m²)</th>
                  <th>Povprečna NMV</th>
                  <th>Povprečna Ekspozicija</th>
                  <th>Povprečni naklon</th>
                  <th>Opomba</th>
                  <th>Je na seznamu MKGP?</th>
                </tr>
              </thead>
              <tbody>
                {zdruzeniPodatki &&
                  zdruzeniPodatki.map((data) => (
                    <tr key={data.gerkPodatki.id}>
                      <td>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id={data.id}
                          label={data.jeAktivenNasad ? "Da" : "Ne"}
                          checked={data.jeAktivenNasad}
                          onChange={async (e) => {
                            if (e.target.checked) {
                              setClickedRow(e.target);

                              setIzbranGERK(data.gerkPodatki.gerkId);
                              console.log(
                                `izrbani GERK: ${data.gerkPodatki.gerkId}`
                              );
                              setShowWhichFruitTypeModal(true);
                            }
                          }}
                        />
                      </td>
                      <td>{data.gerkPodatki.gerkId}</td>
                      <td>{data.gerkPodatki.blokId}</td>
                      <td>{data.gerkPodatki.raba}</td>
                      <td>{data.gerkPodatki.domaceIme}</td>
                      <td>{data.gerkPodatki.povrsina}</td>
                      <td>{data.gerkPodatki.nup}</td>
                      <td>{data.gerkPodatki.povprecnaNMV}</td>
                      <td>{data.gerkPodatki.povprecnaEKS}</td>
                      <td>{data.gerkPodatki.povprecniNaklon}</td>
                      <td>{data.gerkPodatki.opombe}</td>
                      <td>{data.jeAktivenNasad ? "Da" : "Ne"}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer className="text-center">
            <Button href="/domacaStran" variant="primary">
              Domača stran
            </Button>
          </Card.Footer>
        </Card>
      </Row>

      <ModalKateraKultura
        izbranGERK={izbranGERK}
        setShowAppleInputModal={setShowAppleInputModal}
        setShowWhichFruitTypeModal={setShowWhichFruitTypeModal}
        showWhichFruitTypeModal={showWhichFruitTypeModal}
        setShowCherryInputModal={setShowCherryInputModal}
        setShowBlueberryInputModal={setShowBlueberryInputModal}
      />

      <ModalJablanaMetapodatki
        showAppleInputModal={showAppleInputModal}
        setShowAppleInputModal={setShowAppleInputModal}
        izbranGERK={izbranGERK}
        uploadNewGerkData={uploadNewGerkData}
        refJablanaLetoNasaditve={refJablanaLetoNasaditve}
        refJablanaSteviloSadik={refJablanaSteviloSadik}
        refJablanaSorta={refJablanaSorta}
        refJablanaSrednjaRazdaljaMedVrstami={
          refJablanaSrednjaRazdaljaMedVrstami
        }
        refJablanaSrednjaRazdaljaVVrsti={refJablanaSrednjaRazdaljaVVrsti}
        jeNamakanNasad={jeNamakanNasad}
        setJeNamakanNasad={setJeNamakanNasad}
        razredVisinDreves={razredVisinDreves}
        setRazredVisinDreves={setRazredVisinDreves}
      />

      <ModalCesnjaMetaPodatki
        showCherryInputModal={showCherryInputModal}
        setShowCherryInputModal={setShowCherryInputModal}
        izbranGERK={izbranGERK}
        uploadNewGerkData={uploadNewGerkData}
        refCesnjaLetoNasaditve={refCesnjaLetoNasaditve}
        refCesnjaSteviloSadik={refCesnjaSteviloSadik}
        refCesnjaSorta={refCesnjaSorta}
        refCesnjaGojitvenaOblika={refCesnjaGojitvenaOblika}
        refCesnjaPodlaga={refCesnjaPodlaga}
        refCesnjaSrednjaRazdaljaMedVrstami={refCesnjaSrednjaRazdaljaMedVrstami}
        refCesnjaSrednjaRazdaljaVVrsti={refCesnjaSrednjaRazdaljaVVrsti}
      />

      <ModalBorovnicaMetaPodatki
        showBlueberryInputModal={showBlueberryInputModal}
        setShowBlueberryInputModal={setShowBlueberryInputModal}
        izbranGERK={izbranGERK}
        uploadNewGerkData={uploadNewGerkData}
        refBorovnicaLetoNasaditve={refBorovnicaLetoNasaditve}
        refBorovnicaStSadikNaTekociMeter={refBorovnicaStSadikNaTekociMeter}
        refBorovnicaSorta={refBorovnicaSorta}
        refBorovnicaKvalitetaSadik={refBorovnicaKvalitetaSadik}
        refBorovnicaCasSajenja={refBorovnicaCasSajenja}
        refBorovnicaRazdaljaMedVrstami={refBorovnicaRazdaljaMedVrstami}
        jeEnkratRodna={jeEnkratRodna}
        setJeEnkratRodna={setJeEnkratRodna}
      />
    </>
  );
};

export default TabelaGerkovBrezTrajnihNasadov;
