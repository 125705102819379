import React from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import { useEffect } from "react";
import { LITER_M_KVADRAT, LITER_NA_SADIKO } from "./jablanaNamakanjeEnote";

const JablanaNamakanjeTabela = ({
  vsaNamakanja,
  fetchNamakanjeData,
  setModalError,
  setShowModal,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsaNamakanja?.length > 0 ? false : true);
  }, [vsaNamakanja]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ backgroundColor: "Gainsboro" }}>Datum</th>
            <th style={{ backgroundColor: "Gainsboro" }}>
              Doza ({LITER_NA_SADIKO} ali {LITER_M_KVADRAT})
            </th>
            {vsaNamakanja && vsaNamakanja[0]?.datum &&
            new Date(vsaNamakanja[0]?.datum).getFullYear() ===
              new Date().getFullYear() ? (
              <th style={{ backgroundColor: "Gainsboro" }}>Izbris</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {vsaNamakanja &&
            vsaNamakanja.map((item) => (
              <DeletionRow
                id={item.id}
                key={item.id}
                link={"jablana/namakanje"}
                setModalError={setModalError}
                setShowModal={setShowModal}
                fetchNewData={fetchNamakanjeData}
                briseSe={"JABLANA_NAMAKANJE"}
                jeTrenutnoLeto={
                  new Date(item.datum).getFullYear() ===
                  new Date().getFullYear()
                }
                children={
                  <>
                    <td>
                      <EditableCell
                        initialValue={item.datum}
                        inputType={"date"}
                        id={item.id}
                        link={"jablana/namakanje"}
                        imeLastnosti={"datum"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchNamakanjeData}
                        spreminjaSe={"NAMAKANJE_DATUM"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <div style={{ display: "flex" }}>
                        <EditableCell
                          initialValue={item.dozaNamakanja}
                          inputType={"number"}
                          id={item.id}
                          link={"jablana/namakanje"}
                          imeLastnosti={"dozaNamakanja"}
                          min={0}
                          max={Number.MAX_SAFE_INTEGER}
                          step={0.1}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchNamakanjeData}
                          spreminjaSe={"NAMAKANJE_DOZA"}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />{" "}
                        <EditableCell
                          initialValue={
                            item.jeEnotaLNaSadiko
                              ? LITER_NA_SADIKO
                              : LITER_M_KVADRAT
                          }
                          inputType={"radio"}
                          radioValue={item.jeEnotaLNaSadiko}
                          id={item.id}
                          link={"jablana/namakanje"}
                          imeLastnosti={"kateraEnota"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchNamakanjeData}
                          spreminjaSe={"NAMAKANJE_DOZA_ENOTA"}
                          moznosti={[LITER_NA_SADIKO, LITER_M_KVADRAT]}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />
                      </div>
                    </td>
                  </>
                }
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default JablanaNamakanjeTabela;
