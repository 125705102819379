import React, { useState, useEffect, useCallback } from "react";
import { Card, Button } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../../fetcher/fetcherGet";

const JablanaSplosniPodatki = ({ setJePrikazSplosnihPodatkov }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId, currGerk } = useCommonContext();
  const [jablanaSplosniPodatki, setJablanaSplosniPodatki] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const fetchSplosnePodatkeJablana = useCallback(
    (signal) => {
      return fetcherGet(
        "jablana/gerkData",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setJablanaSplosniPodatki
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchSplosnePodatkeJablana(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>
            <h3 className="text-center mb-4">
              Podatki o nosilcu GERK-ka: {currGerk}
            </h3>
          </Card.Title>
          <p>
            <b>Ime in priimek nosilca:</b> {jablanaSplosniPodatki.imeNosilca}{" "}
            {jablanaSplosniPodatki.priimekNosilca}
          </p>

          <p>
            <b>Površina nasada (m^2):</b>{" "}
            {jablanaSplosniPodatki.povrsina}
          </p>

          <p>
            <b>Število sadik:</b> {jablanaSplosniPodatki.steviloSadik}
          </p>

          <p>
            <b>Leto sajenja:</b> {jablanaSplosniPodatki.letoNasaditve}
          </p>

          <p>
            <b>Sorta:</b> {jablanaSplosniPodatki.sorta}
          </p>

          <p>
            <b>Razdalja sajenja med vrstami (m):</b>{" "}
            {jablanaSplosniPodatki.srednjaRazdaljaMedVrstami}
          </p>
          <p>
            <b>Razdalja v vrsti (m):</b>{" "}
            {jablanaSplosniPodatki.srednjaRazdaljaVVrsti}
          </p>

          <p>
            <b>Je nasad namakan:</b>{" "}
            {jablanaSplosniPodatki.jeNamakanNasad === true ? "Da" : "Ne"}
          </p>
          
          <p>
            <b>Razred višin dreves (m):</b>{" "}
            {jablanaSplosniPodatki.razredVisinDreves}
          </p>

          <p className="text-center">
            <Button
              onClick={() => {
                setJePrikazSplosnihPodatkov(false);
              }}
            >
              Uredi podatke
            </Button>
          </p>
        </Card.Body>
      </Card>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default JablanaSplosniPodatki;
