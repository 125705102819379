import React from "react";
import { Card, Container, Row, Col, Form, FormGroup } from "react-bootstrap";

const IzbiraLetaInNaslov = ({
  izbranoLeto,
  setIzbranoLeto,
  naslov,
  niPodatkovZaIzbranoLeto,
}) => {
  const years = Array.from(
    { length: 70 },
    (_, i) => new Date().getFullYear() - i
  );

  return (
    <Card.Title>
      <Container>
        <Row>
          <Col>
            <h3 className="text-center mb-4">{naslov}</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 2, offset: 10 }} className="m-2">
            <FormGroup>
              <Form.Label>Izbira leta</Form.Label>
              <Form.Control
                size="sm"
                as="select"
                value={izbranoLeto}
                onChange={(e) => {
                  setIzbranoLeto(parseInt(e.target.value));
                }}
                isInvalid={
                  niPodatkovZaIzbranoLeto &&
                  izbranoLeto !== new Date().getFullYear()
                }
              >
                {years.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Za izbrano leto ni popolnih podatkov
              </Form.Control.Feedback>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </Card.Title>
  );
};

export default IzbiraLetaInNaslov;
