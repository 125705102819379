import React, { useState } from "react";
import { Container, Form, Row, FormGroup, Col, Button } from "react-bootstrap";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherPost from "../../fetcher/fetcherPost";
import imenaKoristnegaOrganizma from "./imenaKoristnegaOrganizma";

const JablanaKoristniForma = ({
  fetchKoristniData,
  setShowModal,
  setModalError,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [imeKoristnegaOrganizma, setImeKoristnegaOrganizma] = useState("");

  const [datum, setDatum] = useState(new Date().toISOString().slice(0, 10));
  const [opombe, setOpombe] = useState("");

  const [jeKoristniOrganizemPrisoten, setJeKoristniOrganizemPrisoten] =
    useState("jePrisoten");

  return (
    <Row>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          e.target.reset();
          await fetcherPost(
            `jablana/koristni`,
            {
              imeKoristnegaOrganizma: imeKoristnegaOrganizma,
              opombe: opombe,
              datum: datum,
              jeKoristniOrganizemPrisoten:
                jeKoristniOrganizemPrisoten === "jePrisoten" ? true : false,
            },
            nasadId,
            setModalError,
            setShowModal,
            signOut,
            history
          );

          setImeKoristnegaOrganizma("");
          setOpombe("");

          setJeKoristniOrganizemPrisoten("jePrisoten");
          fetchKoristniData();
        }}
      >
        <Form.Label className="m-2" htmlFor="koristni">
          <h4>Vnos podatkov o koristnih organizmih na jablani</h4>
        </Form.Label>

        <FormGroup className="m-2">
          <Form.Label htmlFor="imeKoristnegaOrganizma">
            Ime koristnega organizma:
          </Form.Label>
          <Form.Select
            name="imeKoristnegaOrganizma"
            aria-label="Ime koristnega organizma"
            required
            onChange={(e) => setImeKoristnegaOrganizma(e.target.value)}
            value={imeKoristnegaOrganizma}
          >
            <option hidden>Izberite ime koristnega organizma</option>
            {imenaKoristnegaOrganizma.map((ime) => (
              <option key={ime} value={ime}>
                {ime}
              </option>
            ))}
          </Form.Select>
          <Form.Text className="text-muted">
            Izberite koristnega organizma, ki ga želite vnesti
          </Form.Text>
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="datumSpremljanja">Datum spremljanja:</Form.Label>
          <Form.Control
            id="datumSpremljanja"
            type="date"
            min={`${new Date().getFullYear()}-01-01`}
            value={datum}
            max={new Date().toISOString().slice(0, 10)}
            onChange={(e) => setDatum(e.target.value)}
            placeholder="Vnesite datum spremljanja škodljivca"
          />
          <Form.Text className="text-muted">
            Izberite datum spremljanja škodljivca
          </Form.Text>
        </FormGroup>
        <FormGroup className="m-2">
          <Form.Label htmlFor="jeKoristniOrganizemPrisoten">
            Je koristni organizem prisoten:
          </Form.Label>
          <Form.Check
            inline
            label="Je prisoten"
            name="jePrisoten"
            value="jePrisoten"
            checked={jeKoristniOrganizemPrisoten === "jePrisoten"}
            type={"radio"}
            onChange={(e) => setJeKoristniOrganizemPrisoten(e.target.value)}
          />
          <Form.Check
            inline
            label="ni prisoten"
            name="niPrisoten"
            value="niPrisoten"
            checked={jeKoristniOrganizemPrisoten === "niPrisoten"}
            type={"radio"}
            onChange={(e) => setJeKoristniOrganizemPrisoten(e.target.value)}
          />
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="opombe">Opombe:</Form.Label>
          <Form.Control
            id="opombe"
            type="text"
            rows={3}
            as={"textarea"}
            value={opombe}
            onChange={(e) => setOpombe(e.target.value)}
            placeholder="Vnesite morebitne opombe"
          />

          <Form.Text className="text-muted">Vnesite morebitne opombe</Form.Text>
        </FormGroup>

        <FormGroup className="m-2">
          <Container>
            <Row className="g-3">
              <Col className="d-flex align-items-center justify-content-center">
                <Button type="submit" variant="success">
                  <span className="btn-label">
                    <i className="bi bi-plus-circle m-1"></i>
                    Shrani
                  </span>
                </Button>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Form>
    </Row>
  );
};

export default JablanaKoristniForma;
