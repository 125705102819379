import React from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import { useEffect } from "react";
import { SEZNAM_MEHANSKIH_UKREPOV } from "./seznamMehanskihUkrepov";

const JablanaMehanskiUkrepiTabela = ({
  vsiMehanskiUkrepi,
  fetchMehanskiUkrpei,
  setModalError,
  setShowModal,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsiMehanskiUkrepi?.length > 0 ? false : true);
  }, [vsiMehanskiUkrepi]);
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ backgroundColor: "LavenderBlush" }}>Vrsta</th>
            <th style={{ backgroundColor: "LavenderBlush" }}>Datum</th>
            <th style={{ backgroundColor: "LavenderBlush" }}>Opomba</th>
            {vsiMehanskiUkrepi &&
            vsiMehanskiUkrepi[0]?.datum &&
            new Date(vsiMehanskiUkrepi[0]?.datum).getFullYear() ===
              new Date().getFullYear() ? (
              <th style={{ backgroundColor: "LavenderBlush" }}>Izbris</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {vsiMehanskiUkrepi &&
            vsiMehanskiUkrepi.map((item) => (
              <DeletionRow
                id={item.id}
                key={item.id}
                link={"jablana/mehanskiUkrepi"}
                setModalError={setModalError}
                setShowModal={setShowModal}
                fetchNewData={fetchMehanskiUkrpei}
                briseSe={"JABLANA_MEHANSKI_UKREPI"}
                jeTrenutnoLeto={
                  new Date(item.datum).getFullYear() ===
                  new Date().getFullYear()
                }
                children={
                  <>
                    <td>
                      <EditableCell
                        initialValue={item.vrstaMehanskegaUkrepa}
                        inputType={"select"}
                        id={item.id}
                        link={"jablana/mehanskiUkrepi"}
                        imeLastnosti={"vrstaBiodiverzitete"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchMehanskiUkrpei}
                        spreminjaSe={"MEHANSKI_UKREPI_VRSTA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                        poljeMoznosti={SEZNAM_MEHANSKIH_UKREPOV}
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.datum}
                        inputType={"date"}
                        id={item.id}
                        link={"jablana/mehanskiUkrepi"}
                        imeLastnosti={"datum"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchMehanskiUkrpei}
                        spreminjaSe={"MEHANSKI_UKREPI_DATUM"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.opombe}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/mehanskiUkrepi"}
                        imeLastnosti={"opombe"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchMehanskiUkrpei}
                        spreminjaSe={"MEHANSKI_UKREPI_OPOMBE"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                  </>
                }
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default JablanaMehanskiUkrepiTabela;
