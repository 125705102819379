import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import fetcherPost from "../../fetcher/fetcherPost";
import EditableCell from "../../EditableCell";
import cesnjaOcenaZavezanostiPlodicevBesedilo from "../../../ocene/cesnjaOceneZavezanostiPlodicevBesedilo";
import DeletionRow from "../../DeletionRow";

const CesnjaOceneZavezanostiPlodicev = ({
  fetchPodatkeOcenjevanjeInNapoved,
  setShowModal,
  setModalError,
  oceneZavezanostiPlodicev,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [izbranaOcenaZavezanostiPlodicev, setIzbranaOcenaZavezanostiPlodicev] =
    useState();
  const [
    izbranDatumOceneZavezanostiPlodicev,
    setIzbranDatumOceneZavezanostiPlodicev,
  ] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(
      oceneZavezanostiPlodicev?.length > 0 ? false : true
    );
  }, [oceneZavezanostiPlodicev]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "Bisque" }}>
        <Card.Header>
          Ocene zavezanosti plodičev - uspešnost oplodnje
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "Bisque" }}>
                    Datum ocenjevanja
                  </th>
                  <th style={{ backgroundColor: "Bisque" }}>Ocena</th>

                  {oceneZavezanostiPlodicev[0]?.datum &&
                  new Date(oceneZavezanostiPlodicev[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "Bisque" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>

              <tbody>
                {oceneZavezanostiPlodicev.length > 0 &&
                  oceneZavezanostiPlodicev.map((item) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                      briseSe={"OCENA_ZAVEZANOSTI_PLODICEV"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={item.datum}
                              inputType={"date"}
                              id={item.id}
                              link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                              spreminjaSe={"ZAVEZANOST_DATUM"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>

                          <td>
                            <EditableCell
                              initialValue={item.ocena}
                              inputType={"number"}
                              id={item.id}
                              link={"cesnja/ocenjavanjeInNapovedovanjePridelka"}
                              imeLastnosti={"ocena"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOcenjevanjeInNapoved}
                              spreminjaSe={"ZAVEZANOST_OCENA"}
                              step={1}
                              min={1}
                              max={5}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>
          <Card className="m-1" style={{ backgroundColor: "Bisque" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();

                  if (
                    izbranaOcenaZavezanostiPlodicev < 1 ||
                    izbranaOcenaZavezanostiPlodicev > 5
                  ) {
                    setShowModal(true);
                    setModalError(
                      `Izbrana ocena zavezanosti plodičev ni pravilna. Izberite nekaj oceno od 1 do 5!`
                    );

                    return;
                  }

                  await fetcherPost(
                    `cesnja/ocenjavanjeInNapovedovanjePridelka`,
                    {
                      datum: izbranDatumOceneZavezanostiPlodicev,
                      ocena: izbranaOcenaZavezanostiPlodicev,
                      tip: "OCENA ZAVEZANOSTI PLODIČEV",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setIzbranaOcenaZavezanostiPlodicev();
                  setIzbranDatumOceneZavezanostiPlodicev(
                    new Date().toISOString().slice(0, 10)
                  );

                  fetchPodatkeOcenjevanjeInNapoved();
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="ocenaZavezanostiPlodicev">
                    Ocena zavezanosti plodičev:
                  </Form.Label>
                  <Form.Select
                    aria-label="Ocena zavezanosti plodičev"
                    name="ocenaZavezanostiPlodicev"
                    required
                    onChange={(e) =>
                      setIzbranaOcenaZavezanostiPlodicev(e.target.value)
                    }
                    value={izbranaOcenaZavezanostiPlodicev}
                  >
                    <option hidden>Izberite oceno zavezanosti plodičev</option>
                    {cesnjaOcenaZavezanostiPlodicevBesedilo.map(
                      (ocena, index) => (
                        <option key={Math.random()} value={index + 1}>
                          {ocena}
                        </option>
                      )
                    )}
                  </Form.Select>
                  <Form.Text className="text-muted">
                    Izberite oceno zavezanosti plodičev
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="ocenaZavezanostiPlodicev">
                    Datum:
                  </Form.Label>
                  <Form.Control
                    id="ocenaZavezanostiPlodicev"
                    type="date"
                    value={izbranDatumOceneZavezanostiPlodicev}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) =>
                      setIzbranDatumOceneZavezanostiPlodicev(e.target.value)
                    }
                    placeholder="Vnesite datum ocenjevanja zavezanosti plodičev"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum ocenjevanja zavezanosti plodičev
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani oceno zavezanosti plodičev
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CesnjaOceneZavezanostiPlodicev;
