import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import fetcherPost from "../../fetcher/fetcherPost";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import { useEffect } from "react";

const BorovnicaGnojenje = ({
  vsiGnojenje,
  fetchPodatkeUkrepi,
  setShowModal,
  setModalError,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [datumGnojenja, setDatumGnojenja] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [kolicina, setKolicina] = useState();
  const [pripravek, setPripravek] = useState("");

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsiGnojenje?.length > 0 ? false : true);
  }, [vsiGnojenje]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "Chocolate" }}>
        <Card.Header>Podatki o gnojenju nasada</Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "Chocolate" }}>
                    Datum gnojenja
                  </th>
                  <th style={{ backgroundColor: "Chocolate" }}>Pripravek</th>
                  <th style={{ backgroundColor: "Chocolate" }}>
                    Količina (kg/ha)
                  </th>

                  {vsiGnojenje[0]?.datum &&
                  new Date(vsiGnojenje[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "Chocolate" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {vsiGnojenje &&
                  vsiGnojenje.map((podatek) => (
                    <DeletionRow
                      id={podatek.id}
                      key={podatek.id}
                      link={"borovnica/ukrep"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkeUkrepi}
                      briseSe={"GNOJENJE"}
                      jeTrenutnoLeto={
                        new Date(podatek.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={podatek.datum}
                              inputType={"date"}
                              id={podatek.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={"GNOJENJE_DATUM"}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={podatek.pripravek}
                              inputType={"text"}
                              id={podatek.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"pripravek"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={"GNOJENJE_PRIPRAVEK"}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={podatek.kolicina}
                              inputType={"number"}
                              id={podatek.id}
                              link={"borovnica/ukrep"}
                              imeLastnosti={"kolicina"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeUkrepi}
                              spreminjaSe={"GNOJENJE_KOLICINA"}
                              step={0.1}
                              min={0}
                              max={Number.MAX_SAFE_INTEGER}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Card className="m-1" style={{ backgroundColor: "Chocolate" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await fetcherPost(
                    `borovnica/ukrep`,
                    {
                      datum: datumGnojenja,
                      kolicina: kolicina,
                      pripravek: pripravek,
                      tip: "GNOJENJE",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setKolicina("");
                  setPripravek("");
                  setDatumGnojenja(new Date().toISOString().slice(0, 10));

                  fetchPodatkeUkrepi(new AbortController().signal);
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="datumGnojenja">Datum:</Form.Label>
                  <Form.Control
                    id="datumGnojenja"
                    type="date"
                    value={datumGnojenja}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setDatumGnojenja(e.target.value)}
                    placeholder="Vnesite datum gnojenja"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum gnojenja
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="pripravek">Pripravek:</Form.Label>
                  <Form.Control
                    id="pripravek"
                    type="text"
                    minLength={4}
                    required
                    value={pripravek}
                    onChange={(e) => setPripravek(e.target.value)}
                    placeholder={`Vnesite pripravek`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite pripravek, s katerim ste pognojili nasad
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="obrok">Količina (kg/ha)</Form.Label>
                  <Form.Control
                    id="obrok"
                    type="number"
                    min={0.0}
                    step={0.1}
                    required
                    value={kolicina}
                    onChange={(e) => setKolicina(e.target.value)}
                    placeholder={`Vnesite količino (kg/ha) gnojenja`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite količino (kg/ha) gnojila, s katerim ste pognojili
                    nasad
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default BorovnicaGnojenje;
