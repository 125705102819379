import React, { useState, useEffect } from "react";
import { Container, Form, Row, FormGroup, Col, Button } from "react-bootstrap";
import imenaEnoteTerminiSkodljivcev from "./imenaEnoteTerminiSkodljivcev";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherPost from "../../fetcher/fetcherPost";

const JablanaSkodljivciForma = ({
  fetchSkodljivciData,
  setShowModal,
  setModalError,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [imeSkodljivca, setImeSkodljivca] = useState("");
  const [enota, setEnota] = useState("");

  const [termin, setTermin] = useState("");
  const [opombe, setOpombe] = useState("");

  const [datum, setDatum] = useState(new Date().toISOString().slice(0, 10));
  const [mocPojava, setMocPojava] = useState();

  useEffect(() => {
    if (
      imeSkodljivca !== "" &&
      imenaEnoteTerminiSkodljivcev[imeSkodljivca].length === 1
    ) {
      setEnota(imenaEnoteTerminiSkodljivcev[imeSkodljivca][0].enota);
    }

    const currTermin =
      imeSkodljivca !== "" &&
      enota !== "" &&
      imenaEnoteTerminiSkodljivcev[imeSkodljivca].filter((e) => {
        return e.enota === enota;
      });

    if (imeSkodljivca !== "" && enota !== "" && currTermin.length === 1) {
      setTermin(currTermin[0].termin);
    }
  }, [imeSkodljivca, enota]);
  return (
    <Row>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          e.target.reset();
          await fetcherPost(
            `jablana/skodljivci`,
            {
              imeSkodljivca: imeSkodljivca,
              enota: enota,
              termin: termin,
              opombe: opombe,
              datum: datum,
              mocPojava: mocPojava,
            },
            nasadId,
            setModalError,
            setShowModal,
            signOut,
            history
          );

          setImeSkodljivca("");
          setEnota("");
          setTermin("");
          setOpombe("");
          setMocPojava("");

          fetchSkodljivciData();
        }}
      >
        <Form.Label className="m-2" htmlFor="skodljivec">
          <h4>Vnos podatkov o škodljivcih na jablani</h4>
        </Form.Label>

        <FormGroup className="m-2">
          <Form.Label htmlFor="imeSkodljivca">Ime škodljivca:</Form.Label>
          <Form.Select
            name="imeSkodljivca"
            aria-label="Ime škodljivca"
            required
            onChange={(e) => setImeSkodljivca(e.target.value)}
            value={imeSkodljivca}
          >
            <option hidden>Izberite ime škodljivca</option>
            {Object.keys(imenaEnoteTerminiSkodljivcev).map((ime) => (
              <option key={ime} value={ime}>
                {ime}
              </option>
            ))}
          </Form.Select>
          <Form.Text className="text-muted">
            Izberite ime škodljivca, ki ga želite vnesti
          </Form.Text>
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="enotaSkodljivca">Enota škodljivca:</Form.Label>
          <Form.Select
            name="enotaSkodljivca"
            aria-label="Enota škodljivca"
            required
            onChange={(e) => setEnota(e.target.value)}
            value={enota}
          >
            <option hidden>Izberite enoto škodljivca</option>
            {imeSkodljivca !== "" &&
              imenaEnoteTerminiSkodljivcev[imeSkodljivca].map((e) => (
                <option key={JSON.stringify(e)} value={e.enota}>
                  {e.enota}
                </option>
              ))}
          </Form.Select>
          <Form.Text className="text-muted">
            Izberite enoto škodljivca, ki jo želite vnesti
          </Form.Text>
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="terminSkodljivca">
            Termin za spremljanje pojava škodljivca:
          </Form.Label>
          <Form.Select
            name="terminSkodljivca"
            aria-label="Termin škodljivca"
            required
            onChange={(e) => setTermin(e.target.value)}
            value={termin}
          >
            <option hidden>
              Izberite termin za spremljanje pojava škodljivca
            </option>
            {imeSkodljivca !== "" &&
              enota !== "" &&
              imenaEnoteTerminiSkodljivcev[imeSkodljivca]
                .filter((e) => {
                  return e.enota === enota;
                })
                .map((e) => (
                  <option key={JSON.stringify(e)} value={e.termin}>
                    {e.termin}
                  </option>
                ))}
          </Form.Select>
          <Form.Text className="text-muted">
            Izberite termin za spremljanje pojava škodljivca, ki ga želite
            vnesti
          </Form.Text>
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="datumSpremljanja">Datum spremljanja:</Form.Label>
          <Form.Control
            id="datumSpremljanja"
            type="date"
            min={`${new Date().getFullYear()}-01-01`}
            value={datum}
            max={new Date().toISOString().slice(0, 10)}
            onChange={(e) => setDatum(e.target.value)}
            placeholder="Vnesite datum spremljanja škodljivca"
          />
          <Form.Text className="text-muted">
            Izberite datum spremljanja škodljivca
          </Form.Text>
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="mocPojava">Moč pojava:</Form.Label>
          <Form.Control
            id="mocPojava"
            type="text"
            value={mocPojava}
            minLength={3}
            onChange={(e) => setMocPojava(e.target.value)}
            required
            placeholder="Vnesite moč pojava"
          />

          <Form.Text className="text-muted">
            Vnesite moč pojava škodljivca
          </Form.Text>
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="opombe">Opombe:</Form.Label>
          <Form.Control
            id="opombe"
            type="text"
            rows={3}
            as={"textarea"}
            value={opombe}
            onChange={(e) => setOpombe(e.target.value)}
            placeholder="Vnesite morebitne opombe"
          />

          <Form.Text className="text-muted">Vnesite morebitne opombe</Form.Text>
        </FormGroup>

        <FormGroup className="m-2">
          <Container>
            <Row className="g-3">
              <Col className="d-flex align-items-center justify-content-center">
                <Button type="submit" variant="success">
                  <span className="btn-label">
                    <i className="bi bi-plus-circle m-1"></i>
                    Shrani
                  </span>
                </Button>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Form>
    </Row>
  );
};

export default JablanaSkodljivciForma;
