import React, { Fragment } from "react";
import { Row, Table, Card, Form, Button } from "react-bootstrap";


const TabelaGerkovCesnja = ({ zdruzeniPodatki, handleCheckboxToggle }) => {
  return (
    <Row className="vh-100 d-flex justify-content-center align-items-center">
      <Card className="m-1">
        <Card.Body>
          <Card.Title>Pregled GERK-ov z nasadi češnje</Card.Title>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Je v seznamu aktivnih nasadov?</th>

                <th>GERK</th>

                <th>Število dreves na hektar</th>
                <th>Število dreves na površino</th>
                <th>Število sadik</th>
                <th>Srednja razdalja med vrstami (m)</th>
                <th>Srednja razdalja v vrsti (m)</th>
                <th>Gojitvena oblika</th>
                <th>Leto Nasaditve</th>
                <th>Sorta</th>
                <th>Je na seznamu MKGP?</th>
              </tr>
            </thead>
            <tbody>
              {zdruzeniPodatki &&
                zdruzeniPodatki.map((data) => (
                  <Fragment key={JSON.stringify(data)}>
                    {data.nasad.filter((e) => {return e.vrsta === "ČEŠNJA"}).map((nasad) => (
                      <tr key={nasad.id}>
                        <td>
                          <Form.Check
                            type="switch"
                            id={Math.random()}
                            label={nasad.jeAktivenNasad ? "Da" : "Ne"}
                            checked={nasad.jeAktivenNasad}
                            onChange={async (e) => {
                              await handleCheckboxToggle(
                                "NASAD",
                                "ČEŠNJA",
                                e.target.checked,
                                nasad.id
                              );
                            }}
                          />
                        </td>
                        <td>{data.gerkPodatki.gerkId}</td>
                        <td>{nasad.drevesaNaHektar}</td>
                        <td>{nasad.steviloDrevesNaPovrsino}</td>
                        <td>{nasad.steviloSadik}</td>

                        <td>{nasad.srednjaRazdaljaMedVrstami}</td>
                        <td>{nasad.srednjaRazdaljaVVrsti}</td>
                        <td>{nasad.gojitvenaOblika}</td>


                        <td>{nasad.letoNasaditve}</td>
                        <td>{nasad.sorta}</td>
                        <td>{nasad.jeNaSeznamu ? "Da" : "Ne"}</td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="text-center">
          <Button href="/domacaStran" variant="primary">
            Domača stran
          </Button>
        </Card.Footer>
      </Card>
    </Row>
  );
};

export default TabelaGerkovCesnja;
