import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import React, { useState } from "react";
import fetcherPost from "../../fetcher/fetcherPost";
import ModalError from "../../modals/ModalError";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";

const JablanaMesanicaVnos = ({
  modalError,
  setModalError,
  showModal,
  setShowModal,
  fetchMesanice,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [datum, setDatum] = useState(new Date().toISOString().slice(0, 10));
  const [kolicinaVode, setKolicinaVode] = useState();

  const [kolicinaGnojila, setKolicinaGnojila] = useState();
  const [gnojilo, setGnojilo] = useState("");

  const [delezN, setDelezN] = useState();
  const [delezP, setDelezP] = useState();
  const [delezK, setDelezK] = useState();

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                e.target.reset();

                await fetcherPost(
                  `jablana/fertirigacija`,
                  {
                    datum: datum,
                    kolicinaVode: kolicinaVode,
                    kolicinaGnojila: kolicinaGnojila,
                    gnojilo: gnojilo,
                    delezN: delezN,
                    delezP: delezP,
                    delezK: delezK,
                    kajSeDodaja: "MESANICA",
                  },
                  nasadId,
                  setModalError,
                  setShowModal,
                  signOut,
                  history
                );

                setDatum(new Date().toISOString().slice(0, 10));
                setKolicinaVode();

                setKolicinaGnojila();
                setGnojilo("");

                setDelezN();
                setDelezP();
                setDelezK();

                fetchMesanice();
              }}
            >
              <Form.Label htmlFor="skodljivec">
                <h4>Vnos mešanice za fertirigacijo za nasade jablane</h4>
              </Form.Label>

              <Table bordered responsive>
                <thead>
                  <tr>
                    <th rowSpan={2} style={{ backgroundColor: "Gainsboro" }}>
                      Datum priprave mešanice
                    </th>
                    <th rowSpan={2} style={{ backgroundColor: "Gainsboro" }}>
                      Količina vode (l)
                    </th>
                    <th rowSpan={2} style={{ backgroundColor: "Gainsboro" }}>
                      Količina gnojila (kg)
                    </th>
                    <th rowSpan={2} style={{ backgroundColor: "Gainsboro" }}>
                      Gnojilo
                    </th>
                    <th colSpan={3} style={{ backgroundColor: "Gainsboro" }}>
                      Delež (%)
                    </th>
                  </tr>
                  <tr>
                    {/* <th colSpan={4} style={{ backgroundColor: "Gainsboro" }}></th> */}
                    <th style={{ backgroundColor: "Gainsboro" }}>N</th>
                    <th style={{ backgroundColor: "Gainsboro" }}>P</th>
                    <th style={{ backgroundColor: "Gainsboro" }}>K</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        id="datum"
                        type="date"
                        min={`${new Date().getFullYear()}-01-01`}
                        value={datum}
                        max={new Date().toISOString().slice(0, 10)}
                        onChange={(e) => setDatum(e.target.value)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        id="kolicinaVode"
                        type="number"
                        value={kolicinaVode ?? ""}
                        min={0}
                        step={0.1}
                        onChange={(e) => setKolicinaVode(e.target.value)}
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        id="kolicinaGnojila"
                        type="number"
                        value={kolicinaGnojila ?? ""}
                        min={0}
                        step={0.1}
                        onChange={(e) => setKolicinaGnojila(e.target.value)}
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        id="gnojilo"
                        as="textarea"
                        value={gnojilo}
                        minLength={3}
                        onChange={(e) => setGnojilo(e.target.value)}
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        id="delezN"
                        type="number"
                        value={delezN ?? ""}
                        min={0}
                        step={0.1}
                        isValid={delezN <= 100 && delezN >= 0}
                        isInvalid={delezN > 100 || delezN < 0}
                        onChange={(e) => setDelezN(parseInt(e.target.value))}
                        required
                      />
                      <Form.Control.Feedback type="valid">
                        Vneseni delež dušika (N) je veljaven.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Vneseni delež dušika (N) ni veljaven
                      </Form.Control.Feedback>
                    </td>
                    <td>
                      <Form.Control
                        id="delezP"
                        type="number"
                        value={delezP ?? ""}
                        min={0}
                        step={0.1}
                        isValid={delezP <= 100 && delezP >= 0}
                        isInvalid={delezP > 100 || delezP < 0}
                        onChange={(e) => {
                          setDelezP(parseInt(e.target.value));
                        }}
                        required
                      />
                      <Form.Control.Feedback type="valid">
                        Vneseni delež fosforja (P) je veljaven.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Vneseni delež fosforja (P) ni veljaven.
                      </Form.Control.Feedback>
                    </td>
                    <td>
                      <Form.Control
                        id="delezK"
                        type="number"
                        value={delezK ?? ""}
                        min={0}
                        step={0.1}
                        isValid={delezK <= 100 && delezK >= 0}
                        isInvalid={delezK > 100 || delezK < 0}
                        onChange={(e) => setDelezK(parseInt(e.target.value))}
                        required
                      />

                      <Form.Control.Feedback type="valid">
                        Vneseni delež kalija (K) je veljaven.
                      </Form.Control.Feedback>

                      <Form.Control.Feedback type="invalid">
                        Vneseni delež kalija (K) ni veljaven.
                      </Form.Control.Feedback>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <FormGroup>
                <Container>
                  <Row className="g-3">
                    <Col className="d-flex align-items-center justify-content-center">
                      <Button
                        type="submit"
                        variant="success"
                        disabled={
                          delezN > 100 ||
                          delezN < 0 ||
                          delezK > 100 ||
                          delezK < 0 ||
                          delezP > 100 ||
                          delezP < 0 ||
                          delezN === undefined ||
                          delezP === undefined ||
                          delezK === undefined
                        }
                      >
                        <span className="btn-label">
                          <i className="bi bi-plus-circle m-1"></i>
                          Shrani
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </FormGroup>
            </Form>
          </Row>
        </Card.Body>
      </Card>
      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default JablanaMesanicaVnos;
