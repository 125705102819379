import React, { useState, useEffect } from "react";
import { Container, Row, Card, Form, Button } from "react-bootstrap";
import ModalError from "../modals/ModalError";
import BASE_URL from "../../utils/baseUrl";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useNavigate } from "react-router-dom";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

//https://youtu.be/wr3VmbZdVA4?feature=shared

const LoginUser = () => {
  const [uporabniskoIme, setUporabniskoIme] = useState("");
  const [geslo, setGeslo] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const history = useNavigate();

  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated()) history("/domacaStran");
  }, [history, isAuthenticated]);

  const handleUserLogin = async (e) => {
    e.preventDefault();
    console.log(
      `IME: ${uporabniskoIme} geslo: ${geslo} ${JSON.stringify({
        uporabniskoIme: uporabniskoIme,
        geslo: geslo,
      })}`
    );



    try {
      const response = await fetch(BASE_URL + `/uporabnik/prijava`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          uporabniskoIme: uporabniskoIme,
          geslo: geslo,
        }),
      });

      if (response.status === 401) {
        setModalError(`Napačno uporabniško ime ali geslo!`);
        setShowModal(true);

        return;
      }

      const data = await response.json();

      if (
        response.status === 200 &&
        data.loginSuccessful &&
        signIn({
          auth: {
            token: data.refreshToken,
          },
          userState: data.uporabnik,
          refresh: data.refreshToken,
          refreshTokenExpireIn: data.refreshTokenExpireIn,
        })
      ) {
        console.log(`uporabnik: ${JSON.stringify(data.uporabnik)}`);

        history("/domacaStran");
        return;
      }

      setModalError(`Prijava ni uspešna! Napaka na strežniku!`);
      setShowModal(true);
    } catch (error) {
      setModalError(`Neznana napaka pri prijavi uporabnika! ${error?.message}`);
      setShowModal(true);
    }
  };
  return (
    <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Card className="shadow">
          <Card.Body>
            <div className="mb-3 mt-md-4">
              <h2 className="fw-bold mb-2 text-center text-uppercase ">
                Digisad prijava uporabnika
              </h2>
            </div>

            <div className="mb-3">
              <Form onSubmit={handleUserLogin}>
                <Form.Group className="mb-3" controlId="gesloPrvic">
                  <Form.Label>Uporabniško ime</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Vnesite uporabniško ime"
                    minLength={5}
                    required
                    autoComplete={"on"}
                    onChange={(e) => {
                      setUporabniskoIme(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="gesloDrugic">
                  <Form.Label>Geslo</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Vnesite geslo"
                    minLength={5}
                    required
                    autoComplete={"on"}
                    onChange={(e) => {
                      setGeslo(e.target.value);
                    }}
                  />
                </Form.Group>

                <div className="d-grid">
                  <Button variant="primary" type="submit">
                    Prijava
                  </Button>
                </div>
              </Form>
            </div>
          </Card.Body>
          <Card.Footer>
            <div className="mt-3">
              <p className="mb-0  text-center">
                Nimate računa?
                <a href="/registracija" className="text-primary fw-bold">
                  Registracija
                </a>
              </p>
            </div>
          </Card.Footer>
        </Card>
      </Row>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </Container>
  );
};

export default LoginUser;
