import React, { useCallback, useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherGet from "../../fetcher/fetcherGet";
import BorovnicaSladkornaStopnja from "./BorovnicaSladkornaStopnja";
import BorovnicaPridelek from "./BorovnicaPridelek";

const BorovnicaPridelekGlavna = ({ izbranoLeto, setNiPodatkovZaIzbranoLeto }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [vsiPodatki, setVsiPodatki] = useState({
    pridelki: [],
    sladkorneStopnje: [],
  });

  const fetchPodatkePridelek = useCallback(
    (signal) => {
      return fetcherGet(
        "borovnica/pridelek",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setVsiPodatki
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchPodatkePridelek(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Container>
            <BorovnicaSladkornaStopnja
              vseSladkorneStopnje={vsiPodatki.sladkorneStopnje?.filter((e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              })}
              fetchPodatkePridelek={fetchPodatkePridelek}
              setShowModal={setShowModal}
              setModalError={setModalError}
              setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
            />
            <BorovnicaPridelek
              vsiPridelki={vsiPodatki.pridelki?.filter((e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              })}
              fetchPodatkePridelek={fetchPodatkePridelek}
              setShowModal={setShowModal}
              setModalError={setModalError}
              setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
            />
          </Container>
        </Card.Body>
      </Card>
      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default BorovnicaPridelekGlavna;
