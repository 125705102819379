import dayjs from "dayjs";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import { DateTimePicker } from "@mui/x-date-pickers";
import React, { useState, useEffect } from "react";
import EditableCell from "../../EditableCell";
import fetcherPost from "../../fetcher/fetcherPost";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import DeletionRow from "../../DeletionRow";

const CesnjaUporabaFFS = ({
  fetchPodatkeOTehnoloskihUkrepih,
  setShowModal,
  setModalError,
  fssPodatki,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [izbranCasTretiranja, setIzbranCasTretiranja] = useState(dayjs());
  const [izbranoPolnoTrgovskoImeSredstva, setIzbranoPolnoTrgovskoImeSredstva] =
    useState();

  const [izbranaUspesnostTretiranja, setIzbranaUspesnostTretiranja] =
    useState();

  const [izbranUporabljenOdmerek, setIzbranUporabljenOdmerek] = useState();
  const [izbranaPovrsina, setIzbranaPovrsina] = useState();

  const [izbranaEnotaOdmerka, setIzbranaEnotaOdmerka] = useState("kg/ha");
  const [izbraneOpombe, setIzbraneOpombe] = useState();

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(fssPodatki?.length > 0 ? false : true);
  }, [fssPodatki]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "Beige" }}>
        <Card.Header>Podatki o uporabi FFS v kmetijski pridelavi</Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "Beige" }} rowSpan={2}>
                    Zap. št. ukrepa
                  </th>
                  <th style={{ backgroundColor: "Beige" }} colSpan={2}>
                    Čas izvedenega ukrepa ali tretiranja
                  </th>
                  <th style={{ backgroundColor: "Beige" }} rowSpan={2}>
                    Površina (ha)
                  </th>
                  <th style={{ backgroundColor: "Beige" }} rowSpan={2}>
                    Polno trgovsko ime sredstva
                  </th>
                  <th style={{ backgroundColor: "Beige" }} rowSpan={2}>
                    Uporabljeni odmerek FFS v kg/ha ali l/ha
                  </th>
                  <th style={{ backgroundColor: "Beige" }} rowSpan={2}>
                    Uspešnost tretiranja
                  </th>
                  <th style={{ backgroundColor: "Beige" }} rowSpan={2}>
                    Opombe
                  </th>

                  {fssPodatki[0]?.datumInCas &&
                  new Date(fssPodatki[0]?.datumInCas).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "Beige" }} rowSpan={2}>
                      Izbris
                    </th>
                  ) : (
                    <></>
                  )}
                </tr>
                <tr>
                  <th style={{ backgroundColor: "Beige" }}>Datum</th>
                  <th style={{ backgroundColor: "Beige" }}>Čas</th>
                </tr>
              </thead>
              <tbody>
                {fssPodatki.length > 0 &&
                  fssPodatki.map((item, index) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"cesnja/tehnoloskiUkrepi"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                      briseSe={"UPORABA_FFS"}
                      jeTrenutnoLeto={
                        new Date(item.datumInCas).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>{index + 1}</td>
                          <td>
                            <EditableCell
                              initialValue={dayjs(item.datumInCas).format(
                                "YYYY-MM-DD"
                              )}
                              inputType={"date"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"datumInCas"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"FSS_DATUM"}
                              jeTrenutnoLeto={
                                new Date(item.datumInCas).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={dayjs(item.datumInCas).format(
                                "HH:mm"
                              )}
                              inputType={"time"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"datumInCas"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"FSS_CAS"}
                              jeTrenutnoLeto={
                                new Date(item.datumInCas).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={item.povrsina}
                              inputType={"number"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"povrsina"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"TEHNOLOSKI_POVRSINA"}
                              step={0.1}
                              min={0}
                              max={Number.MAX_SAFE_INTEGER}
                              jeTrenutnoLeto={
                                new Date(item.datumInCas).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={item.trgovskoImeFSS}
                              inputType={"text"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"trgovskoImeFSS"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"FFS_TRGOVSKO_IME"}
                              jeTrenutnoLeto={
                                new Date(item.datumInCas).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <EditableCell
                                initialValue={item.uporabljenOdmerekFSS}
                                inputType={"number"}
                                id={item.id}
                                link={"cesnja/tehnoloskiUkrepi"}
                                imeLastnosti={"uporabljenOdmerekFSS"}
                                setModalError={setModalError}
                                setShowModal={setShowModal}
                                fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                                spreminjaSe={"FFS_ODMEREK"}
                                step={0.1}
                                min={0}
                                max={Number.MAX_SAFE_INTEGER}
                                jeTrenutnoLeto={
                                  new Date(item.datumInCas).getFullYear() ===
                                  new Date().getFullYear()
                                }
                              />{" "}
                              <EditableCell
                                initialValue={
                                  item.jeEnotakgHa ? "kg/ha" : "l/ha"
                                }
                                inputType={"radio"}
                                radioValue={item.jeEnotakgHa}
                                id={item.id}
                                link={"cesnja/tehnoloskiUkrepi"}
                                imeLastnosti={"kateraEnota"}
                                setModalError={setModalError}
                                setShowModal={setShowModal}
                                fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                                spreminjaSe={"FFS_ENOTA"}
                                moznosti={["kg/ha", "l/ha"]}
                                jeTrenutnoLeto={
                                  new Date(item.datumInCas).getFullYear() ===
                                  new Date().getFullYear()
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <EditableCell
                              initialValue={item.uspesnostTretiranja}
                              inputType={"text"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"uspesnostTretiranja"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"FFS_USPESNOST"}
                              jeTrenutnoLeto={
                                new Date(item.datumInCas).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={item.opombe}
                              inputType={"text"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"opombe"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"FFS_OPOMBE"}
                              jeTrenutnoLeto={
                                new Date(item.datumInCas).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>
          <Card className="m-1" style={{ backgroundColor: "Beige" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();

                  await fetcherPost(
                    `cesnja/tehnoloskiUkrepi`,
                    {
                      datumInCas: izbranCasTretiranja,
                      povrsina: izbranaPovrsina,
                      trgovskoImeFSS: izbranoPolnoTrgovskoImeSredstva,
                      uporabljenOdmerekFSS: izbranUporabljenOdmerek,
                      jeEnotakgHa: izbranaEnotaOdmerka === "kg/ha",
                      uspesnostTretiranja: izbranaUspesnostTretiranja,
                      opombe: izbraneOpombe,
                      tip: "FFS",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setIzbranCasTretiranja(dayjs());
                  setIzbranaPovrsina();

                  setIzbranoPolnoTrgovskoImeSredstva();
                  setIzbranUporabljenOdmerek();

                  setIzbranaUspesnostTretiranja();
                  setIzbraneOpombe("");

                  setIzbranaEnotaOdmerka("kg/ha");
                  fetchPodatkeOTehnoloskihUkrepih();
                }}
              >
                <FormGroup className="m-2">
                  <p>
                    <Form.Label htmlFor="casTretiranja">
                      Čas izvedenega ukrepa ali tretiranja:
                    </Form.Label>
                  </p>
                  <DateTimePicker
                    id={"casTretiranja"}
                    ampm={false}
                    required
                    value={izbranCasTretiranja}
                    onChange={(newValue) => setIzbranCasTretiranja(newValue)}
                    className="d-flex justify-content-center"
                    defaultValue={dayjs()}
                    maxDateTime={dayjs()}
                  />
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="polnoTrgovskoImeSredstva">
                    Polno trgovsko ime sredstva:
                  </Form.Label>
                  <Form.Control
                    id="polnoTrgovskoImeSredstva"
                    type="text"
                    value={izbranoPolnoTrgovskoImeSredstva}
                    minLength={4}
                    required
                    onChange={(e) =>
                      setIzbranoPolnoTrgovskoImeSredstva(e.target.value)
                    }
                    placeholder="Vnesite polno trgovsko ime sredstva"
                  />

                  <Form.Text className="text-muted">
                    Vnesite polno trgovsko ime sredstva
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="povrsina">Površina (ha):</Form.Label>
                  <Form.Control
                    id="povrsina"
                    type="number"
                    min={0.0}
                    step={0.1}
                    required
                    value={izbranaPovrsina}
                    onChange={(e) => setIzbranaPovrsina(e.target.value)}
                    placeholder={`Vnesite površino v ha, ki ste jo tretirali`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite površino v ha, ki ste jo tretirali
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="uporabljenOdmerekFSS">
                    Uporabljen odmerek FFS v {izbranaEnotaOdmerka}:
                  </Form.Label>
                  <Form.Control
                    id="uporabljenOdmerekFSS"
                    type="number"
                    min={0.0}
                    step={0.1}
                    required
                    value={izbranUporabljenOdmerek}
                    onChange={(e) => setIzbranUporabljenOdmerek(e.target.value)}
                    placeholder={`Vnesite uporabljen odmerek FFS v ${izbranaEnotaOdmerka}`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite uporabljen odmerek FFS v{izbranaEnotaOdmerka}
                  </Form.Text>
                  <div className="mb-3">
                    <Form.Check
                      inline
                      label="kg/ha"
                      name="kgNaha"
                      value="kg/ha"
                      checked={izbranaEnotaOdmerka === "kg/ha"}
                      type={"radio"}
                      onChange={(e) => setIzbranaEnotaOdmerka(e.target.value)}
                    />
                    <Form.Check
                      inline
                      label="l/ha"
                      name="lNaHa"
                      value="l/ha"
                      checked={izbranaEnotaOdmerka === "l/ha"}
                      type={"radio"}
                      onChange={(e) => setIzbranaEnotaOdmerka(e.target.value)}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="uspesnostTretiranja">
                    Uspešnost tretiranja:
                  </Form.Label>
                  <Form.Control
                    id="uspesnostTretiranja"
                    type="text"
                    value={izbranaUspesnostTretiranja}
                    minLength={4}
                    onChange={(e) =>
                      setIzbranaUspesnostTretiranja(e.target.value)
                    }
                    required
                    placeholder="Vnesite uspešnost tretiranja"
                  />

                  <Form.Text className="text-muted">
                    Vnesite uspešnost tretiranja
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="opombe">Opombe:</Form.Label>
                  <Form.Control
                    id="opombe"
                    type="text"
                    rows={3}
                    as={"textarea"}
                    value={izbraneOpombe}
                    onChange={(e) => setIzbraneOpombe(e.target.value)}
                    placeholder="Vnesite morebitne opombe"
                  />

                  <Form.Text className="text-muted">
                    Vnesite morebitne opombe
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CesnjaUporabaFFS;
