import React, { useState, useEffect } from "react";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  InputGroup,
  Table,
} from "react-bootstrap";
import EditableCell from "../../EditableCell";
import fetcherPost from "../../fetcher/fetcherPost";
import DeletionRow from "../../DeletionRow";

const JablanaCiljniPridelek = ({
  ciljniPridelek,
  rocnoRedcenje,
  setModalError,
  setShowModal,
  fetchRazvojPlodicevData,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [ciljnaKolicinaPridelka, setCiljnaKolicinaPridelka] = useState(0);
  const [navedbaStPlodovKg, setNavedbaStPlodovKg] = useState(0);

  const makeCombinedArray = () => {
    const combinedArray = [];
    const maxValue = Math.max(
      ...[Object.keys(ciljniPridelek).length, Object.keys(rocnoRedcenje).length]
    );

    for (let i = 0; i < maxValue; i++) {
      combinedArray.push({
        ciljniPridelek: ciljniPridelek.length > i ? ciljniPridelek[i] : "",
        rocnoRedcenje: rocnoRedcenje.length > i ? rocnoRedcenje[i] : "",
        id: ciljniPridelek[i]?.id,
        createdAt: ciljniPridelek[i]?.createdAt,
      });
    }

    return combinedArray;
  };

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(ciljniPridelek?.length > 0 ? false : true);
  }, [ciljniPridelek]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "DarkRed" }}>
        <Card.Header as="h5">Ciljni pridelek</Card.Header>

        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "DarkRed" }} colSpan={2}>
                    Ciljni pridelek
                  </th>
                  <th style={{ backgroundColor: "DarkRed" }} rowSpan={2}>
                    Ciljno število plodičev na drevo
                  </th>
                  <th style={{ backgroundColor: "DarkRed" }} rowSpan={2}>
                    Ročno redčenje
                  </th>

                  {ciljniPridelek[0]?.createdAt &&
                  new Date(ciljniPridelek[0]?.createdAt).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "DarkRed" }} rowSpan={2}>
                      Izbris
                    </th>
                  ) : (
                    <></>
                  )}
                </tr>
                <tr>
                  <th style={{ backgroundColor: "DarkRed" }}>
                    Ciljna količina pridelka[t/ha]
                  </th>
                  <th style={{ backgroundColor: "DarkRed" }}>
                    Navedba št.plodov/kg
                  </th>
                </tr>
              </thead>
              <tbody>
                {ciljniPridelek &&
                  rocnoRedcenje &&
                  makeCombinedArray().map((item) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"jablana/razvojPlodicev"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchRazvojPlodicevData}
                      briseSe={"CILJNI_PRIDELEK"}
                      jeTrenutnoLeto={
                        new Date(item.createdAt).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={
                                item.ciljniPridelek.ciljnaKolicinaPridelka
                              }
                              inputType={"number"}
                              id={item.ciljniPridelek.id}
                              link={"jablana/razvojPlodicev"}
                              imeLastnosti={"ciljnaKolicinaPridelka"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchRazvojPlodicevData}
                              step={0.1}
                              min={0}
                              max={Number.MAX_SAFE_INTEGER}
                              spreminjaSe={"CILJNI_PRIDELEK"}
                              jeTrenutnoLeto={
                                new Date(item.createdAt).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={
                                item.ciljniPridelek.navedbaStPlodovKg
                              }
                              inputType={"number"}
                              id={item.ciljniPridelek.id}
                              link={"jablana/razvojPlodicev"}
                              imeLastnosti={"navedbaStPlodovKg"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchRazvojPlodicevData}
                              step={0.1}
                              min={0}
                              max={Number.MAX_SAFE_INTEGER}
                              spreminjaSe={"ST_PLODOV_KG"}
                              jeTrenutnoLeto={
                                new Date(item.createdAt).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            {item.ciljniPridelek &&
                              parseFloat(
                                item.ciljniPridelek
                                  .ciljnoSteviloPlodiscevNaDrevo
                              ).toFixed(2)}
                          </td>
                          <td>
                            {item.rocnoRedcenje &&
                              parseFloat(
                                item.rocnoRedcenje.izracunanoRocnoRedcenje
                              ).toFixed(2)}
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              e.preventDefault();
              e.target.reset();

              await fetcherPost(
                `jablana/razvojPlodicev`,
                {
                  ciljnaKolicinaPridelka: ciljnaKolicinaPridelka,
                  navedbaStPlodovKg: navedbaStPlodovKg,
                  tip: "ciljniPridelek",
                },
                nasadId,
                setModalError,
                setShowModal,
                signOut,
                history
              );
              setCiljnaKolicinaPridelka(0);
              setNavedbaStPlodovKg(0);

              fetchRazvojPlodicevData();
            }}
          >
            <FormGroup className="m-2">
              <Form.Label htmlFor="ciljniPridelek">Ciljni pridelek:</Form.Label>
              <InputGroup>
                <InputGroup.Text id="ciljniPridelek">
                  Navedba ciljne količine pridelka (t/ha)
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  value={ciljnaKolicinaPridelka}
                  onChange={(e) => setCiljnaKolicinaPridelka(e.target.value)}
                  placeholder="Navedba ciljne količine pridelka (t/ha)"
                  aria-label="Navedba ciljne količine pridelka (t/ha)"
                  aria-describedby="Navedba ciljne količine pridelka (t/ha)"
                  required
                  min={0}
                  step={0.1}
                />

                <InputGroup.Text id="ciljniPridelekStPlodovNaKg">
                  Navedba št.plodov/kg
                </InputGroup.Text>

                <Form.Control
                  type="number"
                  value={navedbaStPlodovKg}
                  onChange={(e) => setNavedbaStPlodovKg(e.target.value)}
                  min={0}
                  step={0.1}
                  placeholder="Ocene uspšnosti redčenja"
                  aria-label="Ocene uspšnosti redčenja"
                  aria-describedby="Ocene uspšnosti redčenja"
                  required
                />
              </InputGroup>
              <Form.Text className="text-muted">
                Vnesite ciljni pridelek v tonah na hektar in pričakovano število
                plodov na kg
              </Form.Text>
            </FormGroup>

            <FormGroup className="m-2">
              <Container>
                <Row className="g-3">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Button type="submit" variant="success">
                      <span className="btn-label">
                        <i className="bi bi-plus-circle m-1"></i>
                        Shrani oceno ciljnega pridelka
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default JablanaCiljniPridelek;
