import React from "react";
import { Button } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import fetcherDelete from "./fetcher/fetcherDelete";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";

const DeletionRow = ({
  children,
  id,
  link,
  setModalError,
  setShowModal,
  fetchNewData,
  briseSe,
  jeTrenutnoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const handleDeletion = async () => {
    console.log(`izbris: ${id}`);

    await fetcherDelete(
      link,
      id,
      briseSe,
      setModalError,
      setShowModal,
      signOut,
      history
    );

    fetchNewData();
  };

  return (
    <tr key={id}>
      {children}
      {jeTrenutnoLeto === true ? (
        <td>
          <Button variant="danger" onClick={handleDeletion}>
            <Trash />
          </Button>
        </td>
      ) : (
        <></>
      )}
    </tr>
  );
};

export default DeletionRow;
