import createRefresh from "react-auth-kit/createRefresh";
import BASE_URL from "./baseUrl";

const refreshApi = createRefresh({
  interval: 1, // Refreshs the token in every 1 minutes
  refreshApiCallback: async ({ refreshToken }) => {
    console.log(`klic refresh funkcije`);

    try {
      const response = await fetch(`${BASE_URL}/uporabnik/refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ refreshToken: refreshToken }),
      });

      if (response.status === 401) {
        console.log("Ni avtentizoran upodabnik pri refreshu!");

        return {
          isSuccess: false,
        };
      }

      const data = await response.json();

      console.log(
        `uspešna osvežitev žetona: ${
          data.refreshSuccessful === true && response.status === 200
        }`
      );

      return {
        isSuccess: data.refreshSuccessful === true && response.status === 200,
        newAuthToken: data.refreshToken,
        newAuthTokenExpireIn: 259200,
        newRefreshTokenExpiresIn: 259200,
      };
    } catch (error) {
      console.error(error);
      console.log(`napaka pri refresh funkcije ${error?.message}`);

      return {
        isSuccess: false,
      };
    }
  },
});

export default refreshApi;
