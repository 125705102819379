import React, { useState, useEffect, useCallback } from "react";
import { Container, Card } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherGet from "../../fetcher/fetcherGet";
import JablanaMehanskiUkrepiTabela from "./JablanaMehanskiUkrepiTabela";

const JablanaMehanskiUkrepiPrikaz = ({
  izbranoLeto,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [vsiMehanskiUkrepi, setVsiMehanskiUkrepi] = useState({});

  const fetchMehanskiUkrpei = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/mehanskiUkrepi",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setVsiMehanskiUkrepi
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchMehanskiUkrpei(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Card style={{ backgroundColor: "LavenderBlush" }}>
      <Card.Body>
        <Container>
          <h3>Pregled podatkov o mehanskih ukrepih v nasadu jablan</h3>

          <JablanaMehanskiUkrepiTabela
            vsiMehanskiUkrepi={
              Object.keys(vsiMehanskiUkrepi).length > 0 &&
              vsiMehanskiUkrepi?.mehanskiUkrepi?.filter((e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              })
            }
            fetchMehanskiUkrpei={fetchMehanskiUkrpei}
            setModalError={setModalError}
            setShowModal={setShowModal}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />
        </Container>
      </Card.Body>
      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </Card>
  );
};

export default JablanaMehanskiUkrepiPrikaz;
