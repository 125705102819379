import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherPost from "../../fetcher/fetcherPost";
import ModalError from "../../modals/ModalError";
import { KG, LITER } from "./enote";
import styled from "../../../App.module.css";
import { useFetchAktivniNasadi } from "../../../hooks/useFetchAktivniNasadi";
import SaveButton from "../common/SaveButton";

const JablanaSkropivaVnos = () => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [aktivniNasadi, setAktivniNasadi] = useState([]);
  const [idInDatumiSpravilaIzbranihNasadov, setIdInIzbranihNasadov] = useState(
    {}
  );

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [datum, setDatum] = useState(new Date().toISOString().slice(0, 10));
  const [uraTretiranja, setUraTretiranja] = useState(
    new Date().toISOString().substring(11, 16)
  );

  const [datumZakljuckaTretiranja, setDatumZakljuckaTretiranja] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const [bolezen, setBolezen] = useState("");
  const [pripravek, setPripravek] = useState("");

  const [aktivnaSnov, setAktivnaSnov] = useState("");
  const [karenca, setKarenca] = useState("");

  const [koncNaHa, setKoncNaHa] = useState();
  const [kolicinaVodeNaHa, setKolicinaVodeNaHa] = useState();

  const [uporabljenaMehanizacija, setUporabljenaMehanizacija] = useState("");
  const [vremeskiPogoji, setVremeskiPogoji] = useState("");

  const [izvajalecSkropljenja, setIzvajalecSkropljenja] = useState("");
  const [pooblasenaOseba, setPooblasenaOseba] = useState("");

  const [uncikovitostUkrepa, setUncikovitostUkrepa] = useState("");
  const [zamenjaniFFS, setZamenjaniFFS] = useState("");

  const [jeEnotaKoncNaHaL, setJeEnotaKoncNaHaL] = useState(LITER);

  const fetchAktivniNasadi = useFetchAktivniNasadi(
    setModalError,
    setShowModal,
    signOut,
    history,
    setAktivniNasadi
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchAktivniNasadi(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div
      className="mt-5 d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
        <Card>
          <Card.Body>
            <Row>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();
                  await fetcherPost(
                    `jablana/skropiva`,
                    {
                      datum: datum,
                      uraTretiranja: uraTretiranja,
                      datumZakljuckaTretiranja: datumZakljuckaTretiranja,
                      bolezen: bolezen,
                      pripravek: pripravek,
                      aktivnaSnov: aktivnaSnov,
                      karenca: karenca,
                      koncNaHa: koncNaHa,
                      jeEnotaKoncNaHaL: jeEnotaKoncNaHaL === LITER,
                      kolicinaVodeNaHa: kolicinaVodeNaHa,
                      uporabljenaMehanizacija: uporabljenaMehanizacija,
                      vremeskiPogoji: vremeskiPogoji,
                      izvajalecSkropljenja: izvajalecSkropljenja,
                      pooblasenaOseba: pooblasenaOseba,
                      uncikovitostUkrepa: uncikovitostUkrepa,
                      zamenjaniFFS: zamenjaniFFS,
                      idInDatumiSpravilaIzbranihNasadov: Object.keys(
                        idInDatumiSpravilaIzbranihNasadov
                      )
                        .filter(
                          (key) =>
                            idInDatumiSpravilaIzbranihNasadov[key].isChecked ===
                            true
                        )
                        .map((key) => ({
                          nasadId: key,
                          datumSpravila:
                            idInDatumiSpravilaIzbranihNasadov[key]
                              .datumSpravila ?? null,
                        })),
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setDatum(new Date().toISOString().slice(0, 10));

                  setDatumZakljuckaTretiranja(
                    new Date().toISOString().slice(0, 10)
                  );
                  setBolezen("");

                  setUraTretiranja(new Date().toISOString().slice(0, 10));

                  setPripravek("");
                  setAktivnaSnov("");

                  setKarenca("");

                  setKoncNaHa();
                  setKolicinaVodeNaHa();

                  setUporabljenaMehanizacija("");
                  setVremeskiPogoji("");

                  setIzvajalecSkropljenja("");
                  setPooblasenaOseba("");

                  setUncikovitostUkrepa("");
                  setZamenjaniFFS("");

                  setIdInIzbranihNasadov({});
                }}
              >
                <Form.Label htmlFor="skodljivec">
                  <h4>Vnos podatkov o škropljenju nasada jablane</h4>
                </Form.Label>
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "HoneyDew" }}>Datum</th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        Ura tretiranja s FFS
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        Datum zaključka škropljenja
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Škodljivec, bolezen, ostalo
                        </div>
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Pripravek
                        </div>
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Aktivna snov
                        </div>
                      </th>

                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Karenca (dni)
                        </div>
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Koncentracija(L/Ha ali kg/Ha)
                        </div>
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Količina vode na hektar (L)
                        </div>
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Uporabljena mehanizacija
                        </div>
                      </th>

                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Vremenski pogoji
                        </div>
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Izvajalec škropljenja
                        </div>
                      </th>

                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Pooblaščena oz. odgovorna oseba
                        </div>
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          Unčikovitost ukrepa
                        </div>
                      </th>
                      <th style={{ backgroundColor: "HoneyDew" }}>
                        <div
                          className={`d-flex justify-content-center ${styled.colWidth}`}
                        >
                          FFS, ki se zamenjuje
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <Form.Control
                          id="datum"
                          type="date"
                          min={`${new Date().getFullYear()}-01-01`}
                          value={datum}
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={(e) => setDatum(e.target.value)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="uraTretiranja"
                          value={uraTretiranja}
                          step={300}
                          type="time"
                          required
                          className="d-flex justify-content-center"
                          onChange={(e) => setUraTretiranja(e.target.value)}
                        />
                        {/* <TimePicker
                          id="uraTretiranja"
                          value={uraTretiranja}
                          step="300"
                          InputProps={{
                            endAdornment: null
                          }}
                          ampm={false}
                          required
                          className="border-0 d-flex justify-content-center"
                          onChange={(e) => setUraTretiranja(e)}
                        /> */}
                      </td>
                      <td>
                        <Form.Control
                          id="datumZakljuckaTretiranja"
                          type="date"
                          min={datum ?? `${new Date().getFullYear()}-01-01`}
                          value={datumZakljuckaTretiranja}
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={(e) =>
                            setDatumZakljuckaTretiranja(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="bolezen"
                          as="textarea"
                          value={bolezen}
                          minLength={3}
                          onChange={(e) => setBolezen(e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="pripravek"
                          as="textarea"
                          value={pripravek}
                          minLength={3}
                          onChange={(e) => setPripravek(e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="aktivnaSnov"
                          as="textarea"
                          value={aktivnaSnov}
                          minLength={3}
                          onChange={(e) => setAktivnaSnov(e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="karenca"
                          type="number"
                          value={karenca ?? ""}
                          min={0}
                          max={366}
                          step={1}
                          onChange={(e) => setKarenca(e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="koncNaHa"
                          type="number"
                          value={koncNaHa ?? ""}
                          min={0}
                          step={0.1}
                          onChange={(e) => setKoncNaHa(e.target.value)}
                          required
                        />
                        <div key={`inline-radio`} className="mb-3">
                          <Form.Check // prettier-ignore
                            type="radio"
                            inline
                            id="jeEnotaKoncNaHaL"
                            value={jeEnotaKoncNaHaL}
                            checked={jeEnotaKoncNaHaL === LITER}
                            onChange={(e) => {
                              setJeEnotaKoncNaHaL(
                                e.target.value === LITER ? KG : LITER
                              );
                            }}
                            label={LITER}
                          />
                          <Form.Check // prettier-ignore
                            inline
                            type="radio"
                            id="jeEnotaKoncNaHaL"
                            value={jeEnotaKoncNaHaL}
                            checked={jeEnotaKoncNaHaL === KG}
                            onChange={(e) => {
                              setJeEnotaKoncNaHaL(
                                e.target.value === LITER ? KG : LITER
                              );
                            }}
                            label={KG}
                          />
                        </div>
                      </td>
                      <td>
                        <Form.Control
                          id="kolicinaVodeNaHa"
                          type="number"
                          value={kolicinaVodeNaHa ?? ""}
                          min={0}
                          step={0.1}
                          onChange={(e) => setKolicinaVodeNaHa(e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="uporabljenaMehanizacija"
                          as="textarea"
                          value={uporabljenaMehanizacija}
                          minLength={3}
                          onChange={(e) =>
                            setUporabljenaMehanizacija(e.target.value)
                          }
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="vremeskiPogoji"
                          as="textarea"
                          value={vremeskiPogoji}
                          minLength={3}
                          onChange={(e) => setVremeskiPogoji(e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="izvajalecSkropljenja"
                          as="textarea"
                          value={izvajalecSkropljenja}
                          minLength={3}
                          onChange={(e) =>
                            setIzvajalecSkropljenja(e.target.value)
                          }
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="pooblasenaOseba"
                          as="textarea"
                          value={pooblasenaOseba}
                          minLength={3}
                          onChange={(e) => setPooblasenaOseba(e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="uncikovitostUkrepa"
                          as="textarea"
                          value={uncikovitostUkrepa}
                          minLength={3}
                          onChange={(e) =>
                            setUncikovitostUkrepa(e.target.value)
                          }
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="zamenjaniFFS"
                          as="textarea"
                          value={zamenjaniFFS}
                          minLength={3}
                          onChange={(e) => setZamenjaniFFS(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <FormGroup>
                  <Form.Label htmlFor="poskropljeniNasadi">
                    Poškropljeni nasadi (GERK, sorta, leto nasaditve, datum
                    spravila):
                  </Form.Label>

                  <div
                    key={`checkbox`}
                    id={"poskropljeniNasadi"}
                    className="mb-3"
                  >
                    <Container>
                      {aktivniNasadi &&
                        aktivniNasadi.map(
                          (nasad) =>
                            nasad.jablanas &&
                            nasad.jablanas.map((jablana) => (
                              <Row
                                className="w-75 py-2"
                                key={JSON.stringify(jablana)}
                              >
                                <Col xl>
                                  <Form.Check
                                    className="w-50"
                                    label={`${nasad.gerkId} ${jablana.sorta} ${jablana.letoNasaditve}`}
                                    name="aktivniNasadi"
                                    size="lg"
                                    type={"checkbox"}
                                    value={jablana.id || -1}
                                    id={`${nasad.gerkId}_${jablana.sorta}_${jablana.letoNasaditve}`}
                                    checked={
                                      idInDatumiSpravilaIzbranihNasadov[
                                        jablana.id
                                      ]?.isChecked ?? false
                                    }
                                    onChange={(e) => {
                                      if (
                                        idInDatumiSpravilaIzbranihNasadov[
                                          e.target.value
                                        ]?.isChecked === true
                                      ) {
                                        setIdInIzbranihNasadov((prevState) => ({
                                          ...prevState,
                                          [e.target.value]: {
                                            isChecked: false,
                                            datumSpravila: null,
                                          },
                                        }));
                                      } else {
                                        setIdInIzbranihNasadov((prevState) => ({
                                          ...prevState,
                                          [e.target.value]: {
                                            isChecked: true,
                                            datumSpravila: null,
                                          },
                                        })); // Key as unique identifier
                                      }
                                    }}
                                  />
                                </Col>
                                <Col xl>
                                  <FormGroup>
                                    <Form.Control
                                      className="w-50"
                                      id="datumSpravila"
                                      placeholder="Datum spravila"
                                      type="date"
                                      min={`${new Date().getFullYear()}-01-01`}
                                      value={
                                        idInDatumiSpravilaIzbranihNasadov[
                                          jablana.id
                                        ]?.datumSpravila ?? ""
                                      }
                                      disabled={
                                        idInDatumiSpravilaIzbranihNasadov[
                                          jablana.id
                                        ] === undefined
                                          ? true
                                          : idInDatumiSpravilaIzbranihNasadov[
                                              jablana.id
                                            ]?.isChecked === false ?? true
                                      }
                                      onChange={(e) =>
                                        setIdInIzbranihNasadov((prevState) => ({
                                          ...prevState,
                                          [jablana.id]: {
                                            isChecked: true,
                                            datumSpravila: e.target.value,
                                          },
                                        }))
                                      }
                                    />
                                    <Form.Text className="text-muted">
                                      Datum spravila
                                    </Form.Text>
                                  </FormGroup>
                                </Col>
                              </Row>
                            ))
                        )}
                    </Container>
                  </div>
                </FormGroup>

                <SaveButton
                  disabledCondition={
                    Object.keys(idInDatumiSpravilaIzbranihNasadov).filter(
                      (key) =>
                        idInDatumiSpravilaIzbranihNasadov[key].isChecked ===
                        true
                    ).length === 0
                  }
                />
              </Form>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default JablanaSkropivaVnos;
