import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Table, Card } from "react-bootstrap";
import BASE_URL from "../utils/baseUrl";
import ModalError from "./modals/ModalError";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../hooks/useCommonContext";

const AktivniNasadi = () => {
  const auth = useAuthUser();
  const signOut = useSignOut();

  const history = useNavigate();
  const { setNasadId, setCurrGerk } = useCommonContext();

  const [aktivniNasadi, setAktivniNasadi] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const fetchUsersActiveOrcards = useCallback(
    async (signal) => {
      try {
        const res = await fetch(
          `${BASE_URL}/midNumber/aktivniNasadi/${auth.midStevilka}`,
          {
            method: "GET",
            signal: signal,
            credentials: "include",
          }
        );

        if (res.status === 401) {
          setModalError(`Napaka pri avtorizaciji uporabnika.`);
          setShowModal(true);

          signOut();
          history("/prijava");

          return;
        }

        const data = await res.json();
        if (res.status === 400) {
          setModalError(
            `Napaka pri pridobivanju aktivnih nasadov na zalednem delu. ${data?.message}`
          );
          setShowModal(true);
          return;
        }

        if (res.status === 200) {
          if (data.length === 0) history("/nastavitve");

          setAktivniNasadi(data);
        }
      } catch (error) {
        if (error?.name === "AbortError") return;

        setModalError(
          `Napaka pri pridobivanju aktivnih nasadov. ${error?.message}`
        );
        setShowModal(true);
      }
    },
    [auth, history, signOut]
  );

  useEffect(() => {
    if (aktivniNasadi.length > 0) return;
    const controller = new AbortController();

    fetchUsersActiveOrcards(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  const handleRowClick = (vrsta, nasadId, gerkId) => {
    setCurrGerk(gerkId);

    switch (vrsta) {
      case "JABLANA": {
        setNasadId(nasadId);
        history(`/jablanaVnos`);
        break;
      }

      case "ČEŠNJA": {
        setNasadId(nasadId);
        history(`/cesnjaVnos`);
        break;
      }

      case "AMERIŠKA BOROVNICA": {
        setNasadId(nasadId);
        history(`/ameriskaBorovnicaVnos`);
        break;
      }

      default: {
        setNasadId(-1);
        break;
      }
    }
  };

  return (
    <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Card className="m-1">
          <Card.Body>
            <Card.Title>Aktivno spremljani nasadi</Card.Title>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Vrsta</th>
                  <th>GERK</th>
                  <th>Število dreves na hektar</th>
                  <th>
                    Število sadik / Število sadik na tekoči meter (am.
                    borovnica)
                  </th>
                  <th>Leto Nasaditve</th>
                  <th>Sorta</th>
                  <th>Je na seznamu MKGP?</th>
                </tr>
              </thead>
              <tbody>
                {aktivniNasadi &&
                  aktivniNasadi.map((gerk) =>
                    ["jablanas", "cesnjas", "borovnicas"].map((ime) =>
                      gerk[ime]?.map((nasad) => (
                        <tr
                          key={nasad.id}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleRowClick(nasad.vrsta, nasad.id, gerk.gerkId);
                          }}
                        >
                          <td>{nasad.vrsta}</td>
                          <td>{gerk.gerkId}</td>
                          <td>
                            {ime === "jablanas"
                              ? (10000 / (nasad.srednjaRazdaljaMedVrstami * nasad.srednjaRazdaljaVVrsti)).toFixed(2)
                              : ime === "borovnicas"
                              ? nasad.steviloSadikNaHektar.toFixed(2)
                              : nasad.drevesaNaHektar}
                          </td>
                          <td>
                            {nasad?.steviloSadik}
                            {nasad?.steviloSadikNaTekociMeter}
                          </td>
                          <td>{nasad.letoNasaditve}</td>
                          <td>{nasad.sorta}</td>
                          <td>{nasad.jeNaSeznamu ? "Da" : "Ne"}</td>
                        </tr>
                      ))
                    )
                  )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Row>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </Container>
  );
};

export default AktivniNasadi;
