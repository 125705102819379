import React from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import { useEffect } from "react";
import { KG, LITER } from "./enote";

const JablanaSkropivaTabela = ({
  vsaSkropiva,
  fetchSkropivaData,
  setModalError,
  setShowModal,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsaSkropiva?.length > 0 ? false : true);
  }, [vsaSkropiva]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ backgroundColor: "HoneyDew" }}>Datum</th>
            <th style={{ backgroundColor: "HoneyDew" }}>
              Ura tretiranja s FFS
            </th>
            <th style={{ backgroundColor: "HoneyDew" }}>
              Datum zaključka škropljenja
            </th>
            <th style={{ backgroundColor: "HoneyDew" }}>
              Škodljivec, bolezen, ostalo
            </th>
            <th style={{ backgroundColor: "HoneyDew" }}>Pripravek</th>
            <th style={{ backgroundColor: "HoneyDew" }}>Aktivna snov</th>

            <th style={{ backgroundColor: "HoneyDew" }}>Karenca (dni)</th>
            <th style={{ backgroundColor: "HoneyDew" }}>
              Koncentracija na površino
            </th>
            <th style={{ backgroundColor: "HoneyDew" }}>
              Koncentracija
            </th>
            <th style={{ backgroundColor: "HoneyDew" }}>
              Količina vode na hektar (L)
            </th>
            <th style={{ backgroundColor: "HoneyDew" }}>
              Uporabljena mehanizacija
            </th>

            <th style={{ backgroundColor: "HoneyDew" }}>Vremenski pogoji</th>
            <th style={{ backgroundColor: "HoneyDew" }}>
              Izvajalec škropljenja
            </th>

            <th style={{ backgroundColor: "HoneyDew" }}>
              Pooblaščena oz. odgovorna oseba
            </th>
            <th style={{ backgroundColor: "HoneyDew" }}>Unčikovitost ukrepa</th>
            <th style={{ backgroundColor: "HoneyDew" }}>Datum spravila</th>
            <th style={{ backgroundColor: "HoneyDew" }}>
              FFS, ki se zamenjuje
            </th>

            {vsaSkropiva[0]?.datum &&
            new Date(vsaSkropiva[0]?.datum).getFullYear() ===
              new Date().getFullYear() ? (
              <th style={{ backgroundColor: "HoneyDew" }}>Izbris</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {vsaSkropiva &&
            vsaSkropiva.map((item) => (
              <DeletionRow
                id={item.id}
                key={item.id}
                link={"jablana/skropiva"}
                setModalError={setModalError}
                setShowModal={setShowModal}
                fetchNewData={fetchSkropivaData}
                briseSe={"JABLANA_SKROPIVA"}
                jeTrenutnoLeto={
                  new Date(item.datum).getFullYear() ===
                  new Date().getFullYear()
                }
                children={
                  <>
                    <td>
                      <EditableCell
                        initialValue={item.datum}
                        inputType={"date"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"datum"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_DATUM"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.uraTretiranja.split(":00")[0]}
                        inputType={"time"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"uraTretiranja"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_URA_TRETIRANJA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        key={item.datum}
                        initialValue={item.datumZakljuckaTretiranja}
                        inputType={"date"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"datumZakljuckaTretiranja"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_DATUM_ZAKLJUCKA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.bolezen}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"bolezen"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_BOLEZEN"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.pripravek}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"pripravek"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_PRIPRAVEK"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.aktivnaSnov}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"aktivnaSnov"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_AKTIVNA_SNOV"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.karenca}
                        inputType={"number"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"karenca"}
                        min={0}
                        max={366}
                        step={1}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_KARENCA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      {item.koncNaPovrsino}
                      {item.jeEnotaKoncNaHaL ? `${LITER}` : `${KG}`}
                    </td>
                    <td>
                      <div style={{ display: "flex" }}>
                        <EditableCell
                          initialValue={item.koncNaHa}
                          inputType={"number"}
                          id={item.id}
                          link={"jablana/skropiva"}
                          imeLastnosti={"koncNaHa"}
                          min={0}
                          max={Number.MAX_SAFE_INTEGER}
                          step={0.1}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchSkropivaData}
                          spreminjaSe={"SKROPIVA_KONC_NA_HA"}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />{" "}
                        <EditableCell
                          initialValue={item.jeEnotaKoncNaHaL ? LITER : KG}
                          inputType={"radio"}
                          radioValue={item.jeEnotaKoncNaHaL}
                          id={item.id}
                          link={"jablana/skropiva"}
                          imeLastnosti={"kateraEnota"}
                          setModalError={setModalError}
                          setShowModal={setShowModal}
                          fetchNewData={fetchSkropivaData}
                          spreminjaSe={"SKROPIVA_FFS_ENOTA"}
                          moznosti={[LITER, KG]}
                          jeTrenutnoLeto={
                            new Date(item.datum).getFullYear() ===
                            new Date().getFullYear()
                          }
                        />/Ha
                      </div>
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.kolicinaVodeNaHa}
                        inputType={"number"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"kolicinaVodeNaHa"}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                        step={0.1}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_KOLICINA_VODE"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.uporabljenaMehanizacija}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"uporabljenaMehanizacija"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_UPORABLJENA_MEHANIZACIJA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.vremeskiPogoji}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"vremeskiPogoji"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_VREMENSKI_POGOJI"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.izvajalecSkropljenja}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"izvajalecSkropljenja"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_IZVAJALEC_SKROPLJENJA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.pooblasenaOseba}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"pooblasenaOseba"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_POOBLASCENA_OSEBA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.uncikovitostUkrepa}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"uncikovitostUkrepa"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_UNCIKOVITOST_UKREPA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                    <td>
                      {item.datumSpravila ? new Date(item.datumSpravila)
                        .toLocaleString("sl-Sl")
                        .split(",")[0] : ""}
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.zamenjaniFFS}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/skropiva"}
                        imeLastnosti={"zamenjaniFFS"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchSkropivaData}
                        spreminjaSe={"SKROPIVA_FFS_ZAMENJAVA"}
                        jeTrenutnoLeto={
                          new Date(item.datum).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                  </>
                }
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default JablanaSkropivaTabela;
