import React from "react";
import { Table } from "react-bootstrap";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";
import { useEffect } from "react";
import { VRSTE_BIODIVERZITETE } from "./vrsteBiodiverzitete";

const JablanaBiodiverzitetaTabela = ({
  vsaBiodiverziteta,
  fetchBiodiverzitetaData,
  setModalError,
  setShowModal,
  setNiPodatkovZaIzbranoLeto,
}) => {
  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsaBiodiverziteta?.length > 0 ? false : true);
  }, [vsaBiodiverziteta]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ backgroundColor: "IndianRed" }}>Vrsta</th>
            <th style={{ backgroundColor: "IndianRed" }}>Opomba</th>
            {vsaBiodiverziteta &&
            vsaBiodiverziteta[0]?.createdAt &&
            new Date(vsaBiodiverziteta[0]?.createdAt).getFullYear() ===
              new Date().getFullYear() ? (
              <th style={{ backgroundColor: "IndianRed" }}>Izbris</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {vsaBiodiverziteta &&
            vsaBiodiverziteta.map((item) => (
              <DeletionRow
                id={item.id}
                key={item.id}
                link={"jablana/biodiverziteta"}
                setModalError={setModalError}
                setShowModal={setShowModal}
                fetchNewData={fetchBiodiverzitetaData}
                briseSe={"JABLANA_BIODIVERZITETA"}
                jeTrenutnoLeto={
                  new Date(item.createdAt).getFullYear() ===
                  new Date().getFullYear()
                }
                children={
                  <>
                    <td>
                      <EditableCell
                        initialValue={item.vrstaBiodiverzitete}
                        inputType={"select"}
                        id={item.id}
                        link={"jablana/biodiverziteta"}
                        imeLastnosti={"vrstaBiodiverzitete"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchBiodiverzitetaData}
                        spreminjaSe={"BIODIVERZITETA_VRSTA"}
                        jeTrenutnoLeto={
                          new Date(item.createdAt).getFullYear() ===
                          new Date().getFullYear()
                        }
                        poljeMoznosti={VRSTE_BIODIVERZITETE}
                      />
                    </td>
                    <td>
                      <EditableCell
                        initialValue={item.opombe}
                        inputType={"text"}
                        id={item.id}
                        link={"jablana/biodiverziteta"}
                        imeLastnosti={"opombe"}
                        setModalError={setModalError}
                        setShowModal={setShowModal}
                        fetchNewData={fetchBiodiverzitetaData}
                        spreminjaSe={"BIODIVERZITETA_OPOMBE"}
                        jeTrenutnoLeto={
                          new Date(item.createdAt).getFullYear() ===
                          new Date().getFullYear()
                        }
                      />
                    </td>
                  </>
                }
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default JablanaBiodiverzitetaTabela;
