import React, { useState, useEffect, useCallback } from "react";
import { Container, Card } from "react-bootstrap";
import ModalError from "../../modals/ModalError";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import CesnjaFenofaze from "./CesnjaFenofaze";
import CesnjaOceneCvetenja from "./CesnjaOceneCvetenja";
import CesnjaOceneZavezanostiPlodicev from "./CesnjaOceneZavezanostiPlodicev";
import CesnjaPridelek from "./CesnjaPridelek";
import fetcherGet from "../../fetcher/fetcherGet";

const CesnjaOcenjevanjeInNapovedPridelka = ({ izbranoLeto, setNiPodatkovZaIzbranoLeto }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [vsiPodatkiOcenjavanje, setVsiPodatkiOcenjavanje] = useState({
    oceneCvetenja: [],
    oceneZavezanostiPlodicev: [],
    fenofazeCesnje: [],
    pridelekCesnje: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const fetchPodatkeOcenjevanjeInNapoved = useCallback(
    (signal) => {
      return fetcherGet(
        "cesnja/ocenjavanjeInNapovedovanjePridelka",
        signal,
        nasadId,
        setModalError,
        setShowModal,
        signOut,
        history,
        setVsiPodatkiOcenjavanje
      );
    },
    [nasadId, history, signOut]
  );

  useEffect(() => {
    const controller = new AbortController();

    fetchPodatkeOcenjevanjeInNapoved(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Container>
            <CesnjaFenofaze
              fetchPodatkeOcenjevanjeInNapoved={
                fetchPodatkeOcenjevanjeInNapoved
              }
              setShowModal={setShowModal}
              setModalError={setModalError}
              fenofazeCesnje={vsiPodatkiOcenjavanje.fenofazeCesnje.filter(
                (e) => {
                  return new Date(e.datum).getFullYear() === izbranoLeto;
                }
              )}
              setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
            />

            <CesnjaOceneCvetenja
              fetchPodatkeOcenjevanjeInNapoved={
                fetchPodatkeOcenjevanjeInNapoved
              }
              setShowModal={setShowModal}
              setModalError={setModalError}
              oceneCvetenja={vsiPodatkiOcenjavanje.oceneCvetenja.filter((e) => {
                return new Date(e.datum).getFullYear() === izbranoLeto;
              })}
              setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
            />

            <CesnjaOceneZavezanostiPlodicev
              fetchPodatkeOcenjevanjeInNapoved={
                fetchPodatkeOcenjevanjeInNapoved
              }
              setShowModal={setShowModal}
              setModalError={setModalError}
              oceneZavezanostiPlodicev={vsiPodatkiOcenjavanje.oceneZavezanostiPlodicev.filter(
                (e) => {
                  return new Date(e.datum).getFullYear() === izbranoLeto;
                }
              )}
              setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
            />
            <CesnjaPridelek
              fetchPodatkeOcenjevanjeInNapoved={
                fetchPodatkeOcenjevanjeInNapoved
              }
              setShowModal={setShowModal}
              setModalError={setModalError}
              pridelekCesnje={vsiPodatkiOcenjavanje.pridelekCesnje.filter(
                (e) => {
                  return (
                    new Date(e.datum).getFullYear() === izbranoLeto
                  );
                }
              )}
              skupneVrednosti={vsiPodatkiOcenjavanje.skupneVrednosti}
              setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
            />
          </Container>
        </Card.Body>
      </Card>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default CesnjaOcenjevanjeInNapovedPridelka;
