import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherPost from "../../fetcher/fetcherPost";
import ModalError from "../../modals/ModalError";
import { useFetchAktivniNasadi } from "../../../hooks/useFetchAktivniNasadi";
import AktivniSeznamiChecklist from "../common/AktivniSeznamiChecklist";
import SaveButton from "../common/SaveButton";

const JablanaGnojenjeVnos = () => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [aktivniNasadi, setAktivniNasadi] = useState([]);
  const [datum, setDatum] = useState(new Date().toISOString().slice(0, 10));

  const [gnojilo, setGnojilo] = useState("");
  const [dozaNaNasad, setDozaNaNasad] = useState();

  const [izvajalecGnojenja, setIzvajalecGnojenja] = useState("");
  const [odgovornaOseba, setOdgovornaOseba] = useState("");

  const [uporabljenaMehanizacija, setUporabljenaMehanizacija] = useState("");
  const [idIzbranihNasadov, setIdIzbranihNasadov] = useState({});

  const fetchAktivniNasadi = useFetchAktivniNasadi(
    setModalError,
    setShowModal,
    signOut,
    history,
    setAktivniNasadi
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchAktivniNasadi(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div
      className="mt-5 d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
        <Card>
          <Card.Body>
            <Row>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();
                  await fetcherPost(
                    `jablana/gnojenje`,
                    {
                      datum: datum,
                      dozaNaNasad: dozaNaNasad,
                      gnojilo: gnojilo,
                      izvajalecGnojenja: izvajalecGnojenja,
                      odgovornaOseba: odgovornaOseba,
                      uporabljenaMehanizacija: uporabljenaMehanizacija,
                      idIzbranihNasadov: Object.keys(idIzbranihNasadov)
                        .filter(
                          (key) => idIzbranihNasadov[key].isChecked === true
                        )
                        .map((key) => ({
                          nasadId: key,
                        })),
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setDatum(new Date().toISOString().slice(0, 10));

                  setDozaNaNasad("");
                  setGnojilo("");

                  setIzvajalecGnojenja("");
                  setOdgovornaOseba("");

                  setUporabljenaMehanizacija("");
                  setIdIzbranihNasadov({});
                }}
              >
                <Form.Label htmlFor="skodljivec">
                  <h4>
                    Vnos podatkov o mineralnem in organskem gnojenju nasada
                    jablane
                  </h4>
                </Form.Label>

                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "BurlyWood" }}>Datum</th>
                      <th style={{ backgroundColor: "BurlyWood" }}>
                        Gnojilo (vsebnost hranljivih snovi v %)
                      </th>
                      <th style={{ backgroundColor: "BurlyWood" }}>
                        Doza gnojila (kg/nasad)
                      </th>
                      <th style={{ backgroundColor: "BurlyWood" }}>
                        Izjavajec gnojenja
                      </th>
                      <th style={{ backgroundColor: "BurlyWood" }}>
                        Odgovorna oseba
                      </th>
                      <th style={{ backgroundColor: "BurlyWood" }}>
                        Uporabljena mehanizacija
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Control
                          id="datum"
                          type="date"
                          min={`${new Date().getFullYear()}-01-01`}
                          value={datum}
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={(e) => setDatum(e.target.value)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="gnojilo"
                          as="textarea"
                          value={gnojilo}
                          minLength={3}
                          onChange={(e) => setGnojilo(e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="dozaNaNasad"
                          type="number"
                          value={dozaNaNasad ?? ""}
                          min={0}
                          step={0.1}
                          onChange={(e) => setDozaNaNasad(e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="izvajalecSkropljenja"
                          as="textarea"
                          value={izvajalecGnojenja}
                          minLength={3}
                          onChange={(e) => setIzvajalecGnojenja(e.target.value)}
                          required
                        />
                      </td>

                      <td>
                        <Form.Control
                          id="pooblasenaOseba"
                          as="textarea"
                          value={odgovornaOseba}
                          minLength={3}
                          onChange={(e) => setOdgovornaOseba(e.target.value)}
                          required
                        />
                      </td>

                      <td>
                        <Form.Control
                          id="uporabljenaMehanizacija"
                          as="textarea"
                          value={uporabljenaMehanizacija}
                          minLength={3}
                          onChange={(e) =>
                            setUporabljenaMehanizacija(e.target.value)
                          }
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <AktivniSeznamiChecklist
                  text={
                    "Pognojeni nasadi (GERK, sorta, leto nasaditve, datum spravila):"
                  }
                  setIdIzbranihNasadov={setIdIzbranihNasadov}
                  aktivniNasadi={aktivniNasadi}
                  idIzbranihNasadov={idIzbranihNasadov}
                />

                <SaveButton
                  disabledCondition={
                    Object.keys(idIzbranihNasadov).filter(
                      (key) => idIzbranihNasadov[key].isChecked === true
                    ).length === 0
                  }
                />
              </Form>
            </Row>
          </Card.Body>
        </Card>
      </div>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default JablanaGnojenjeVnos;
