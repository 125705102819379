import React, { useState } from "react";
import {
  Container,
  Row,
  Modal,
  Button,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import fetcherPut from "../fetcher/fetcherPut";

const ModalBorovnicaManjkajociPodatki = ({
  seModalnoPrikaze,
  izbranGERK,
  nasadId,
  setModalError,
  setShowModal,
  signOut,
  history,
  fetchSplosniPodatkiBorovnica,
}) => {
  const [prikaziModalno, setPrikaziModalno] = useState(seModalnoPrikaze);

  const [steviloSadikNaTekociMeter, setSteviloSadikNaTekociMeter] =
    useState(1.0);

  const [kvalitetaSadik, setKvalitetaSadik] = useState("");
  const [casSajenja, setCasSajenja] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const [jeEnkratRodna, setJeEnkratRodna] = useState("enkratRodna");

  return (
    <Modal show={prikaziModalno} onHide={() => setPrikaziModalno(false)}>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();

          await fetcherPut(
            "borovnica/gerkData",
            {
              steviloSadikNaTekociMeter: steviloSadikNaTekociMeter,
              kvalitetaSadik: kvalitetaSadik,
              casSajenja: casSajenja,
              jeEnkratRodna: jeEnkratRodna === "enkratRodna",
            },
            nasadId,
            setModalError,
            setShowModal,
            signOut,
            history
          );

          fetchSplosniPodatkiBorovnica(new AbortController().signal);
          setPrikaziModalno(false);
        }}
      >
        <FormGroup className="m-2">
          <Form.Label htmlFor="stSadikNaTekociMeter">
            Število sadik na tekoči meter:
          </Form.Label>
          <Form.Control
            type="number"
            name="stSadikNaTekociMeter"
            value={steviloSadikNaTekociMeter}
            onChange={(e) => setSteviloSadikNaTekociMeter(e.target.value)}
            required
            min={0.0}
            step={0.1}
            placeholder="Vnesite število sadik na tekoči meter v nasadu"
          ></Form.Control>
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="kvalitetaSadik">Kvaliteta sadik:</Form.Label>
          <Form.Control
            type="text"
            name="kvalitetaSadik"
            value={kvalitetaSadik}
            onChange={(e) => setKvalitetaSadik(e.target.value)}
            required
            minLength={3}
            placeholder={`Vnesite kvaliteto sadik ameriške borovnice, ki rastejo na GERK-u ${izbranGERK}`}
          ></Form.Control>
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="casSajenja">Čas sajenja:</Form.Label>
          <Form.Control
            id="casSajenja"
            type="date"
            value={casSajenja}
            onChange={(e) => setCasSajenja(e.target.value)}
            max={new Date().toISOString().slice(0, 10)}
            placeholder="Vnesite čas sajenja"
          />
          <Form.Text className="text-muted">
            Izberite čas sajenja ameriške borovnice
          </Form.Text>
        </FormGroup>

        <FormGroup className="m-2">
          <Form.Label htmlFor="sorta">Rodnost:</Form.Label>
          <br />
          <Form.Check
            inline
            label="Enkrat rodne"
            name="enkratRodna"
            value="enkratRodna"
            checked={jeEnkratRodna === "enkratRodna"}
            type={"radio"}
            onChange={(e) => setJeEnkratRodna(e.target.value)}
          />
          <Form.Check
            inline
            label="Dvakrat rodne"
            name="dvakratRodna"
            value="dvakratRodna"
            checked={jeEnkratRodna === "dvakratRodna"}
            type={"radio"}
            onChange={(e) => setJeEnkratRodna(e.target.value)}
          />
        </FormGroup>

        <FormGroup className="m-2">
          <Container>
            <Row className="g-3">
              <Col className="d-flex align-items-center justify-content-center">
                <Button type="submit" variant="success">
                  <span className="btn-label">
                    <i className="bi bi-plus-circle m-1"></i>
                    Posodobi podatke
                  </span>
                </Button>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Form>
    </Modal>
  );
};

export default ModalBorovnicaManjkajociPodatki;
