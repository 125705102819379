import React, { useState, useCallback } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import CesnjaSplosniPodatki from "./splosniPodatki/CesnjaSplosniPodatki";
import CesnjaOcenjevanjeInNapovedPridelka from "./ocenjevanjeInNapovedovanjePridelka/CesnjaOcenjevanjeInNapovedPridelka";
import CesnjaTehnoloskiUkrepi from "./tehnoloskiUkrepi/CesnjaTehnoloskiUkrepi";
import CesnjaUrediSplosnePodatke from "./splosniPodatki/CesnjaUrediSplosnePodatke";
import IzbiraLetaInNaslov from "../IzbiraLetaInNaslov";
import PrikazGpsPodatkovDreves from "../nastavitve/PrikazGpsPodatkovDreves";


const CesnjaObrazci = () => {
  const [key, setKey] = useState("gerk");
  const [jePrikazSplosnihPodatkov, setJePrikazSplosnihPodatkov] =
    useState(true);

  const [izbranoLeto, setIzbranoLeto] = useState(new Date().getFullYear());
  const [niPodatkovZaIzbranoLeto, setNiPodatkovZaIzbranoLeto] = useState(true);

  const memoizedSetNiPodatkovZaIzbranoLeto = useCallback(
    (newValue) => setNiPodatkovZaIzbranoLeto(newValue),
    [] // Empty dependency array to prevent unnecessary re-creations
  );

  return (
    <div
      className="mt-5 d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
        <Card>
          <Card.Body>
            <IzbiraLetaInNaslov
              naslov={"CRP obrazec za češnjo"}
              izbranoLeto={izbranoLeto}
              setIzbranoLeto={setIzbranoLeto}
              niPodatkovZaIzbranoLeto={niPodatkovZaIzbranoLeto}
            />

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="gerk" title="GERK">
                {jePrikazSplosnihPodatkov ? (
                  <CesnjaSplosniPodatki
                    setJePrikazSplosnihPodatkov={setJePrikazSplosnihPodatkov}
                  />
                ) : (
                  <CesnjaUrediSplosnePodatke
                    setJePrikazSplosnihPodatkov={setJePrikazSplosnihPodatkov}
                  />
                )}
              </Tab>

              <Tab
                eventKey="ocenjevanjeInNapovediPridelka"
                title="Ocenjevanje in napovedi pridelka"
              >
                <CesnjaOcenjevanjeInNapovedPridelka
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={memoizedSetNiPodatkovZaIzbranoLeto}
                />
              </Tab>
              <Tab eventKey="tehnoloskiUkrepi" title="Tehnološki ukrepi">
                <CesnjaTehnoloskiUkrepi
                  izbranoLeto={izbranoLeto}
                  key={izbranoLeto}
                  setNiPodatkovZaIzbranoLeto={memoizedSetNiPodatkovZaIzbranoLeto}
                />
              </Tab>
              <Tab
            eventKey="prikazGpsPodatkovDreves"
            title="Dodatne informacije o nasadu"
          >
            <PrikazGpsPodatkovDreves disableUploading={true}
            />
          </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CesnjaObrazci;
