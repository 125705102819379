import React, { useState, useCallback, useEffect } from "react";
import fetcherGet from "../fetcher/fetcherGet";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import fetcherDelete from "../fetcher/fetcherDelete";
import { Row, Card, Pagination, Col, Container, Button } from "react-bootstrap";

const OgledSlik = ({ setModalError, setShowModal }) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const [imagesUrls, setImagesUrls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  const [pagesToShow, setPagesToShow] = useState(9);
  let totalPages = Math.ceil(imagesUrls.length / itemsPerPage) ?? 0;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = imagesUrls.slice(indexOfFirstItem, indexOfLastItem);

  let startPageIndex = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  const endPageIndex = Math.min(
    imagesUrls.length ?? 0 + 1,
    startPageIndex + pagesToShow
  );

  startPageIndex = Math.max(1, endPageIndex - pagesToShow);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let activePage = currentPage;
  let pageNumbers = [];
  for (
    let number = startPageIndex;
    number <= endPageIndex && number <= totalPages;
    number++
  ) {
    pageNumbers.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => paginate(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const fetchSlike = useCallback(
    async (signal) => {
      return fetcherGet(
        "slikeData",
        signal,
        null,
        setModalError,
        setShowModal,
        signOut,
        history,
        setImagesUrls
      );
    },
    [history, signOut, setModalError, setShowModal]
  );

  useEffect(() => {
    fetchSlike();
  }, []);

  useEffect(() => {
    const checkBreakpoint = () => {
      const width = window.innerWidth;
      if (width < 290) {
        setPagesToShow(1);
      } else if (width < 390) {
        setPagesToShow(2);
      } else if (width < 630) {
        setPagesToShow(4);
      }
    };

    // Check breakpoint on mount and on resize
    checkBreakpoint();
    window.addEventListener("resize", checkBreakpoint);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", checkBreakpoint);
  }, []);

  const handleImageDeletion = async (imageId) => {
    await fetcherDelete(
      "slikeData",
      imageId,
      null,
      setModalError,
      setShowModal,
      signOut,
      history
    );

    fetchSlike();
  }

  return (
    <Row className="vh-100 d-flex justify-content-center align-items-center">
      <Card className="m-1">
        <Card.Body>
          <Card.Title>Pregled slik aktivnih nasadov</Card.Title>
          <Container>
            <Row className="gy-3">
              {currentItems.length > 0 &&
                currentItems.map((imageData) => (
                  <Col
                    className="col-xl-3 col-lg-4 col-md-8 col-sm-12"
                    key={imageData.linkSlike}
                  >
                    <Card className="m-1 h-100">
                      <Card.Body>
                        <Card.Title>
                          Slika na GERK-u: {imageData.gerk}
                        </Card.Title>

                        <img
                          src={`${imageData.linkSlike}`}
                          alt="Opis slike"
                          className="img-fluid"
                        />
                      </Card.Body>
                      <Card.Footer>
                        <div className="d-flex justify-content-center align-items-center">
                          <Button variant="danger" onClick={() => handleImageDeletion(imageData.id)}>Izbris</Button>
                        </div>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
            </Row>
            <Row className="my-2">
              <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => setCurrentPage(1)} />
                  <Pagination.Prev
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(1, prev - 1))
                    }
                  />
                  {pageNumbers}
                  <Pagination.Next
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(totalPages, prev + 1))
                    }
                  />
                  <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
                </Pagination>
              </div>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default OgledSlik;
