import React from "react";
import {
  Container,
  Row,
  Modal,
  Button,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import { JE_ENKRAT_RODNA, JE_DVAKRAT_RODNA } from "../../constants/constans";

const ModalBorovnicaMetaPodatki = ({
  showBlueberryInputModal,
  setShowBlueberryInputModal,
  izbranGERK,
  uploadNewGerkData,
  refBorovnicaLetoNasaditve,
  refBorovnicaStSadikNaTekociMeter,
  refBorovnicaSorta,
  refBorovnicaKvalitetaSadik,
  refBorovnicaCasSajenja,
  refBorovnicaRazdaljaMedVrstami,
  jeEnkratRodna,
  setJeEnkratRodna,
}) => {
  return (
    <Modal
      show={showBlueberryInputModal}
      onHide={() => setShowBlueberryInputModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Vnesite podatke o nasadu ameriške borovnice na GERK-u {izbranGERK}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setShowBlueberryInputModal(false);
            uploadNewGerkData("AMERIŠKA BOROVNICA");
          }}
        >
          <FormGroup className="m-2">
            <Form.Label htmlFor="stSadikNaTekociMeter">
              Število sadik na tekoči meter:
            </Form.Label>
            <Form.Control
              type="number"
              name="stSadikNaTekociMeter"
              ref={refBorovnicaStSadikNaTekociMeter}
              required
              min={0.0}
              step={0.1}
              placeholder="Vnesite število sadik na tekoči meter v nasadu"
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="razdaljaMedVrstami">
              Razdalja med vrstami [m]:
            </Form.Label>
            <Form.Control
              type="number"
              name="razdaljaMedVrstami"
              ref={refBorovnicaRazdaljaMedVrstami}
              required
              min={0.0}
              step={0.1}
              placeholder="Vnesite razdaljo med vrstami [m] v nasadu"
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="sorta">Sorta:</Form.Label>
            <Form.Control
              type="text"
              name="sorta"
              ref={refBorovnicaSorta}
              required
              minLength={3}
              placeholder={`Vnesite sorto ameriške borovnice, ki raste na GERK-u ${izbranGERK}`}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="m-2">
            <Form.Label htmlFor="kvalitetaSadik">Kvaliteta sadik:</Form.Label>
            <Form.Control
              type="text"
              name="kvalitetaSadik"
              ref={refBorovnicaKvalitetaSadik}
              required
              minLength={3}
              placeholder={`Vnesite kvaliteto sadik ameriške borovnice, ki rastejo na GERK-u ${izbranGERK}`}
            ></Form.Control>
          </FormGroup>

          <FormGroup className="m-2">
            <Form.Label htmlFor="drevesaNaHa">Leto nasaditve:</Form.Label>
            <Form.Control
              type="number"
              name="letoNasaditve"
              ref={refBorovnicaLetoNasaditve}
              required
              step={1}
              min={1000}
              max={new Date().getFullYear()}
              placeholder="Vnesite letnico nasaditve"
            ></Form.Control>
          </FormGroup>

          <FormGroup className="m-2">
            <Form.Label htmlFor="casSajenja">Čas sajenja:</Form.Label>
            <Form.Control
              id="casSajenja"
              type="date"
              ref={refBorovnicaCasSajenja}
              max={new Date().toISOString().slice(0, 10)}
              placeholder="Vnesite čas sajenja"
            />
            <Form.Text className="text-muted">
              Izberite čas sajenja ameriške borovnice
            </Form.Text>
          </FormGroup>

          <FormGroup className="m-2">
            <Form.Label htmlFor="sorta">Rodnost:</Form.Label>
            <br />
            <Form.Check
              inline
              label="Enkrat rodne"
              name={JE_ENKRAT_RODNA}
              value={JE_ENKRAT_RODNA}
              checked={jeEnkratRodna === JE_ENKRAT_RODNA}
              type={"radio"}
              onChange={(e) => setJeEnkratRodna(e.target.value)}
            />
            <Form.Check
              inline
              label="Dvakrat rodne"
              name={JE_DVAKRAT_RODNA}
              value={JE_DVAKRAT_RODNA}
              checked={jeEnkratRodna === JE_DVAKRAT_RODNA}
              type={"radio"}
              onChange={(e) => setJeEnkratRodna(e.target.value)}
            />
          </FormGroup>

          <FormGroup className="m-2">
            <Container>
              <Row className="g-3">
                <Col className="d-flex align-items-center justify-content-center">
                  <Button type="submit" variant="success">
                    <span className="btn-label">
                      <i className="bi bi-plus-circle m-1"></i>
                      Shrani podatke
                    </span>
                  </Button>
                </Col>
              </Row>
            </Container>
          </FormGroup>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalBorovnicaMetaPodatki;
