import React from "react";
import {
    Container,
    Form,
    Row,
    FormGroup,
    Col,
  } from "react-bootstrap";

const AktivniSeznamiChecklist = ({text, aktivniNasadi, idIzbranihNasadov, setIdIzbranihNasadov}) => {
  return (
    <FormGroup>
      <Form.Label htmlFor="poskropljeniNasadi">
        {text}
      </Form.Label>

      <div key={`checkbox`} id={"aktivniNasadi"} className="mb-3">
        <Container>
          {aktivniNasadi &&
            aktivniNasadi.map(
              (nasad) =>
                nasad.jablanas &&
                nasad.jablanas.map((jablana) => (
                  <Row className="w-75 py-2" key={JSON.stringify(jablana)}>
                    <Col xl>
                      <Form.Check
                        className="w-50"
                        label={`${nasad.gerkId} ${jablana.sorta} ${jablana.letoNasaditve}`}
                        name="aktivniNasadi"
                        size="lg"
                        type={"checkbox"}
                        value={jablana.id || -1}
                        id={`${nasad.gerkId}_${jablana.sorta}_${jablana.letoNasaditve}`}
                        checked={
                          idIzbranihNasadov[jablana.id]?.isChecked ?? false
                        }
                        onChange={(e) => {
                          if (
                            idIzbranihNasadov[e.target.value]?.isChecked ===
                            true
                          ) {
                            setIdIzbranihNasadov((prevState) => ({
                              ...prevState,
                              [e.target.value]: {
                                isChecked: false,
                              },
                            }));
                          } else {
                            setIdIzbranihNasadov((prevState) => ({
                              ...prevState,
                              [e.target.value]: {
                                isChecked: true,
                              },
                            })); // Key as unique identifier
                          }
                        }}
                      />
                    </Col>
                  </Row>
                ))
            )}
        </Container>
      </div>
    </FormGroup>
  );
};

export default AktivniSeznamiChecklist;
