import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherPost from "../../fetcher/fetcherPost";
import ModalError from "../../modals/ModalError";
import { SEZNAM_MEHANSKIH_UKREPOV } from "./seznamMehanskihUkrepov";
import { useFetchAktivniNasadi } from "../../../hooks/useFetchAktivniNasadi";
import AktivniSeznamiChecklist from "../common/AktivniSeznamiChecklist";
import SaveButton from "../common/SaveButton";

const JablanaMehanskiUkrepiVnos = () => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [aktivniNasadi, setAktivniNasadi] = useState([]);
  const [vrstaMehanskegaUkrepa, setVrstaMehanskegaUkrepa] = useState();

  const [opombe, setOpombe] = useState("");
  const [datum, setDatum] = useState(new Date().toISOString().slice(0, 10));
  const [idIzbranihNasadov, setIdIzbranihNasadov] = useState({});

  const fetchAktivniNasadi = useFetchAktivniNasadi(
    setModalError,
    setShowModal,
    signOut,
    history,
    setAktivniNasadi
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchAktivniNasadi(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div
      className="mt-5 d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
        <Card>
          <Card.Body>
            <Row>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();
                  await fetcherPost(
                    `jablana/mehanskiUkrepi`,
                    {
                      vrstaMehanskegaUkrepa: vrstaMehanskegaUkrepa,
                      datum: datum,
                      opombe: opombe,
                      idIzbranihNasadov: Object.keys(idIzbranihNasadov)
                        .filter(
                          (key) => idIzbranihNasadov[key].isChecked === true
                        )
                        .map((key) => ({
                          nasadId: key,
                        })),
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setVrstaMehanskegaUkrepa();
                  setDatum(new Date().toISOString().slice(0, 10));
                  setOpombe("");

                  setIdIzbranihNasadov({});
                }}
              >
                <Form.Label htmlFor="skodljivec">
                  <h4>Vnos podatkov o mehanskih ukrepih v nasadu jablane</h4>
                </Form.Label>

                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "LavenderBlush" }}>
                        Vrsta
                      </th>
                      <th style={{ backgroundColor: "LavenderBlush" }}>
                        Datum
                      </th>
                      <th style={{ backgroundColor: "LavenderBlush" }}>
                        Opomba
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Select
                          size="sm"
                          required
                          value={vrstaMehanskegaUkrepa}
                          onChange={(e) => {
                            setVrstaMehanskegaUkrepa(e.target.value);
                          }}
                          isInvalid={
                            vrstaMehanskegaUkrepa === undefined
                              ? false
                              : vrstaMehanskegaUkrepa === ""
                          }
                        >
                          <option hidden>
                            Izberite vrsto mehanskega ukrepa
                          </option>

                          {SEZNAM_MEHANSKIH_UKREPOV.map(
                            (mehanskiUkrep, index) => (
                              <option key={index} value={mehanskiUkrep}>
                                {mehanskiUkrep}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Control
                          id="datum"
                          type="date"
                          min={`${new Date().getFullYear()}-01-01`}
                          value={datum}
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={(e) => setDatum(e.target.value)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          id="opombe"
                          as="textarea"
                          value={opombe}
                          minLength={3}
                          onChange={(e) => setOpombe(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <AktivniSeznamiChecklist
                  text={
                    "Namakani nasadi (GERK, sorta, leto nasaditve, datum spravila):"
                  }
                  setIdIzbranihNasadov={setIdIzbranihNasadov}
                  aktivniNasadi={aktivniNasadi}
                  idIzbranihNasadov={idIzbranihNasadov}
                />

                <SaveButton
                  disabledCondition={
                    Object.keys(idIzbranihNasadov).filter(
                      (key) => idIzbranihNasadov[key].isChecked === true
                    ).length === 0 || !vrstaMehanskegaUkrepa
                  }
                />
              </Form>
            </Row>
          </Card.Body>
        </Card>
      </div>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default JablanaMehanskiUkrepiVnos;
