const ocenaCvetenjaBesedilo = [
  "1 = drevo nima cveta",
  "2 = par cvetov na večjem drevesu",
  "3 = recimo 5-10 cvetov na 3 m velikem drevesu",
  "4 = zelo šibek cvetni nastavek",
  "5 = šibek cvetni nastavek",
  "6 = nastavek cvetov, ki še vedno ne obeta polnega pridelka",
  "7 = primerno cvetenje, ne močno",
  "8 = primerno cvetenje, močno, optimalno",
  "9 = zelo močno cvetenje, vsi terminalni brsti cvetijo, potreba po močnem redčenju plodičev",
  "10 = snežna kepa, vsi brsti so cvetni, tudi lateralni brsti na daljših enoletnih poganjkih (t.i. cvetenje na enoletnem lesu), tudi močno redčenje cvetov in plodičev verjetno ne bo zadostovalo za preprečitev izmenične rodnosti",
];

export default ocenaCvetenjaBesedilo;
