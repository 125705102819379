import React, { Fragment } from "react";
import { Row, Table, Card, Form, Button } from "react-bootstrap";

const TabelaGerkovBorovnica = ({ zdruzeniPodatki, handleCheckboxToggle }) => {
  return (
    <Row className="vh-100 d-flex justify-content-center align-items-center">
      <Card className="m-1">
        <Card.Body>
          <Card.Title>Pregled GERK-ov z nasadi ameriške borovnice</Card.Title>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Je v seznamu aktivnih nasadov?</th>
                <th>GERK</th>

                <th>Število dreves na površino</th>
                <th>Število dreves na hektar</th>
                <th>Število sadik na tekoči meter</th>
                <th>Razdalja med vrstami (m)</th>
                <th>Sorta</th>
                <th>Kvaliteta sadik</th>
                <th>Leto Nasaditve</th>
                <th>Čas sajenja</th>
                <th>Rodnost</th>
                <th>Je na seznamu MKGP?</th>
              </tr>
            </thead>
            <tbody>
              {zdruzeniPodatki &&
                zdruzeniPodatki.map((data) => (
                  <Fragment key={JSON.stringify(data)}>
                    {data.nasad
                      .filter((e) => {
                        return e.vrsta === "AMERIŠKA BOROVNICA";
                      })
                      .map((nasad) => (
                        <tr key={nasad.id}>
                          <td>
                            <Form.Check
                              type="switch"
                              id={Math.random()}
                              label={nasad.jeAktivenNasad ? "Da" : "Ne"}
                              checked={nasad.jeAktivenNasad}
                              onChange={async (e) => {
                                await handleCheckboxToggle(
                                  "NASAD",
                                  "AMERIŠKA BOROVNICA",
                                  e.target.checked,
                                  nasad.id
                                );
                              }}
                            />
                          </td>
                          <td>{data.gerkPodatki.gerkId}</td>
                          <td>{nasad.steviloSadikNaPovrsino}</td>
                          <td>{nasad.steviloSadikNaHektar}</td>
                          <td>{nasad.steviloSadikNaTekociMeter}</td>
                          <td>{nasad.razdaljaMedVrstami}</td>
                          <td>{nasad.sorta}</td>
                          <td>{nasad.kvalitetaSadik}</td>
                          <td>{nasad.letoNasaditve}</td>
                          <td>{nasad.casSajenja}</td>
                          <td>
                            {nasad.jeEnkratRodna === true
                              ? "Enkrat rodna"
                              : "Dvakrat rodna"}
                          </td>
                          <td>{nasad.jeNaSeznamu ? "Da" : "Ne"}</td>
                        </tr>
                      ))}
                  </Fragment>
                ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="text-center">
          <Button href="/domacaStran" variant="primary">
            Domača stran
          </Button>
        </Card.Footer>
      </Card>
    </Row>
  );
};

export default TabelaGerkovBorovnica;
