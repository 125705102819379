const cesnjaOcenaCvetenjaBesedilo = [
    "1 = drevo nima cveta",
    "2 = par cvetov na večjem drevesu",
    "3 = prisotnih 20-30 cvetov",
    "4 = zelo šibek cvetni nastavek",
    "5 = šibek cvetni nastavek",
    "6 = nastavek cvetov, ki še vedno ne obeta polnega pridelka",
    "7 = primerno cvetenje, pričakovan povprečni pridelek",
    "8 = močno cvetenje",
    "9 = zelo močno cvetenje",
    "10 = snežna kepa",
  ];
  
  export default cesnjaOcenaCvetenjaBesedilo;
  