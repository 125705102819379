import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import fetcherPost from "../../fetcher/fetcherPost";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";

const CesnjaUporabaGnojil = ({
  fetchPodatkeOTehnoloskihUkrepih,
  setShowModal,
  setModalError,
  gnojenjePodatki,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [izbranaPovrsinaGnojenje, setIzbranaPovrsinaGnojenje] = useState();
  const [izbranaVrstaGnojila, setIzbranaVrstaGnojila] = useState();

  const [izbranaEnotaOdmerkaGnojenje, setIzbranaEnotaOdmerkaGnojenje] =
    useState("kg/ha");

  const [izbranDatumGnojil, setIzbranDatumGnojil] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [izbraneOpombeGnojenje, setIzbraneOpombeGnojenje] = useState();

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(gnojenjePodatki?.length > 0 ? false : true);
  }, [gnojenjePodatki]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "DarkKhaki" }}>
        <Card.Header>
          Podatki o uporabi gnojil in sredstev za krepitev rastlin
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover style={{ width: "90%" }}>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "DarkKhaki" }}>
                    Zap. št. ukrepa
                  </th>
                  <th style={{ backgroundColor: "DarkKhaki" }}>Datum</th>
                  <th style={{ backgroundColor: "DarkKhaki" }}>
                    Površina (m²)
                  </th>
                  <th style={{ backgroundColor: "DarkKhaki" }}>
                    Vrsta gnojila/sredstva
                  </th>
                  <th style={{ backgroundColor: "DarkKhaki" }}>Opombe</th>
                  {gnojenjePodatki[0]?.datum &&
                  new Date(gnojenjePodatki[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "DarkKhaki" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {gnojenjePodatki.length > 0 &&
                  gnojenjePodatki.map((item, index) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"cesnja/tehnoloskiUkrepi"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                      briseSe={"UPORABA_GNOJIL"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>{index + 1}</td>
                          <td>
                            <EditableCell
                              initialValue={item.datum}
                              inputType={"date"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"GNOJENJE_DATUM"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={item.povrsina}
                              inputType={"number"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"povrsina"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"GNOJENJE_POVRSINA"}
                              step={0.1}
                              max={Number.MAX_SAFE_INTEGER}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <EditableCell
                                initialValue={item.vrstaGnojilaSredstva}
                                inputType={"text"}
                                id={item.id}
                                link={"cesnja/tehnoloskiUkrepi"}
                                imeLastnosti={"vrstaGnojilaSredstva"}
                                setModalError={setModalError}
                                setShowModal={setShowModal}
                                fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                                spreminjaSe={"GNOJENJE_VRSTA"}
                                jeTrenutnoLeto={
                                  new Date(item.datum).getFullYear() ===
                                  new Date().getFullYear()
                                }
                              />{" "}
                              <EditableCell
                                initialValue={
                                  item.jeEnotakgHa ? "kg/ha" : "l/ha"
                                }
                                inputType={"radio"}
                                radioValue={item.jeEnotakgHa}
                                id={item.id}
                                link={"cesnja/tehnoloskiUkrepi"}
                                imeLastnosti={"kateraEnota"}
                                setModalError={setModalError}
                                setShowModal={setShowModal}
                                fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                                spreminjaSe={"GNOJENJE_ENOTA"}
                                moznosti={["kg/ha", "l/ha"]}
                                jeTrenutnoLeto={
                                  new Date(item.datum).getFullYear() ===
                                  new Date().getFullYear()
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <EditableCell
                              initialValue={item.opombe}
                              inputType={"text"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"opombe"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"GNOJENJE_OPOMBE"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Card className="m-1" style={{ backgroundColor: "DarkKhaki" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();

                  e.preventDefault();
                  await fetcherPost(
                    `cesnja/tehnoloskiUkrepi`,
                    {
                      datum: izbranDatumGnojil,
                      povrsina: izbranaPovrsinaGnojenje,
                      vrstaGnojilaSredstva: izbranaVrstaGnojila,
                      jeEnotakgHa: izbranaEnotaOdmerkaGnojenje === "kg/ha",
                      opombe: izbraneOpombeGnojenje,
                      tip: "GNOJILA",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );
                  setIzbranDatumGnojil(new Date().toISOString().slice(0, 10));
                  setIzbranaPovrsinaGnojenje();

                  setIzbranaVrstaGnojila();
                  setIzbranaEnotaOdmerkaGnojenje("kg/ha");

                  setIzbraneOpombeGnojenje("");
                  fetchPodatkeOTehnoloskihUkrepih();
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="datumFenofaze">Datum:</Form.Label>
                  <Form.Control
                    id="datumFenofaze"
                    type="date"
                    value={izbranDatumGnojil}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setIzbranDatumGnojil(e.target.value)}
                    placeholder="Vnesite datum uporabe gnojil"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum uporabe gnojil in sredstev za krepitev
                    rastlin
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="povrsina">Površina (m²):</Form.Label>
                  <Form.Control
                    id="povrsina"
                    type="number"
                    min={0.0}
                    step={0.1}
                    required
                    value={izbranaPovrsinaGnojenje}
                    onChange={(e) => setIzbranaPovrsinaGnojenje(e.target.value)}
                    placeholder={`Vnesite površino v m², ki ste jo gnjili`}
                  />
                  <Form.Text className="text-muted">
                    Vnesite površino v m², ki ste jo gnjili
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="vrstaGnojilaSredstva">
                    Vrsta gnojila/sredstva v {izbranaEnotaOdmerkaGnojenje}:
                  </Form.Label>
                  <Form.Control
                    id="vrstaGnojilaSredstva"
                    type="text"
                    minLength={4}
                    required
                    value={izbranaVrstaGnojila}
                    onChange={(e) => setIzbranaVrstaGnojila(e.target.value)}
                    placeholder={`Vnesite uporabljeno gnojilo v ${izbranaEnotaOdmerkaGnojenje}`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite uporabljeno vrsto gnojila ali sredstva v
                    {izbranaEnotaOdmerkaGnojenje}
                  </Form.Text>
                  <div className="mb-3">
                    <Form.Check
                      inline
                      label="kg/ha"
                      name="kgNaha"
                      value="kg/ha"
                      checked={izbranaEnotaOdmerkaGnojenje === "kg/ha"}
                      type={"radio"}
                      onChange={(e) =>
                        setIzbranaEnotaOdmerkaGnojenje(e.target.value)
                      }
                    />
                    <Form.Check
                      inline
                      label="l/ha"
                      name="lNaHa"
                      value="l/ha"
                      checked={izbranaEnotaOdmerkaGnojenje === "l/ha"}
                      type={"radio"}
                      onChange={(e) =>
                        setIzbranaEnotaOdmerkaGnojenje(e.target.value)
                      }
                    />
                  </div>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="opombe">Opombe:</Form.Label>
                  <Form.Control
                    id="opombe"
                    type="text"
                    rows={3}
                    as={"textarea"}
                    value={izbraneOpombeGnojenje}
                    onChange={(e) => setIzbraneOpombeGnojenje(e.target.value)}
                    placeholder="Vnesite morebitne opombe"
                  />

                  <Form.Text className="text-muted">
                    Vnesite morebitne opombe
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CesnjaUporabaGnojil;
