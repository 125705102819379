import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import EditableCell from "../../EditableCell";
import fetcherPost from "../../fetcher/fetcherPost";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import DeletionRow from "../../DeletionRow";

const CesnjaPodatkiORezi = ({
  fetchPodatkeOTehnoloskihUkrepih,
  setShowModal,
  setModalError,
  podatkiRez,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [izbranTipRezi, setIzbranTipRezi] = useState();
  const [izbranaJakostRezi, setIzbranaJakostRezi] = useState();

  const [izbraneOpombeRezi, setIzbraneOpombeRezi] = useState();
  const [izbranDatumRezi, setIzbranDatumRezi] = useState(
    new Date().toISOString().slice(0, 10)
  );

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(podatkiRez?.length > 0 ? false : true);
  }, [podatkiRez]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "DarkSalmon" }}>
        <Card.Header>Podatki o rezi dreves v nasadu</Card.Header>

        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "DarkSalmon" }}>
                    Zap. št. ukrepa
                  </th>
                  <th style={{ backgroundColor: "DarkSalmon" }}>Datum</th>
                  <th style={{ backgroundColor: "DarkSalmon" }}>Sorta</th>
                  <th style={{ backgroundColor: "DarkSalmon" }}>Tip rezi</th>
                  <th style={{ backgroundColor: "DarkSalmon" }}>Jakost rezi</th>
                  <th style={{ backgroundColor: "DarkSalmon" }}>Opombe</th>
                  {podatkiRez[0]?.datum &&
                  new Date(podatkiRez[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: "DarkSalmon" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {podatkiRez.length > 0 &&
                  podatkiRez.map((item, index) => (
                    <DeletionRow
                      id={item.id}
                      key={item.id}
                      link={"cesnja/tehnoloskiUkrepi"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                      briseSe={"PODATKI_O_REZI"}
                      jeTrenutnoLeto={
                        new Date(item.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>{index + 1}</td>
                          <td>
                            <EditableCell
                              initialValue={item.datum}
                              inputType={"date"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"TEHNOLOSKI_DATUM"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>{item.cesnja.sorta}</td>
                          <td>
                            <EditableCell
                              initialValue={item.tipRezi}
                              inputType={"text"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"tipRezi"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"TEHNOLOSKI_TIP"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={item.jakostRezi}
                              inputType={"text"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"jakostRezi"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"TEHNOLOSKI_JAKOST"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={item.opombe}
                              inputType={"text"}
                              id={item.id}
                              link={"cesnja/tehnoloskiUkrepi"}
                              imeLastnosti={"opombe"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkeOTehnoloskihUkrepih}
                              spreminjaSe={"TEHNOLOSKI_OPOMBE"}
                              jeTrenutnoLeto={
                                new Date(item.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Card className="m-1" style={{ backgroundColor: "DarkSalmon" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();

                  e.preventDefault();
                  await fetcherPost(
                    `cesnja/tehnoloskiUkrepi`,
                    {
                      datum: izbranDatumRezi,
                      tipRezi: izbranTipRezi,
                      jakostRezi: izbranaJakostRezi,
                      opombe: izbraneOpombeRezi,
                      tip: "REZ",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setIzbranTipRezi();
                  setIzbranaJakostRezi();

                  setIzbraneOpombeRezi("");
                  setIzbranDatumRezi(new Date().toISOString().slice(0, 10));

                  fetchPodatkeOTehnoloskihUkrepih();
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="datumRezi">Datum:</Form.Label>
                  <Form.Control
                    id="datumRezi"
                    type="date"
                    value={izbranDatumRezi}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setIzbranDatumRezi(e.target.value)}
                    placeholder="Vnesite datum rezi dreves"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum rezi dreves
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="tipRezi">Tip rezi dreves:</Form.Label>
                  <Form.Control
                    id="tipRezi"
                    type="text"
                    minLength={4}
                    required
                    value={izbranTipRezi}
                    onChange={(e) => setIzbranTipRezi(e.target.value)}
                    placeholder={`Vnesite tip rezi dreves, ki ste jo opravili v nasadu`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite tip rezi dreves, ki ste jo opravili v nasadu
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="jakostRezi">
                    Jakost rezi dreves:
                  </Form.Label>
                  <Form.Control
                    id="jakostRezi"
                    type="text"
                    minLength={4}
                    required
                    value={izbranaJakostRezi}
                    onChange={(e) => setIzbranaJakostRezi(e.target.value)}
                    placeholder={`Vnesite jakost rezi dreves, ki ste jo opravili v nasadu`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite jakost rezi dreves, ki ste jo opravili v nasadu
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="opombe">Opombe:</Form.Label>
                  <Form.Control
                    id="opombe"
                    type="text"
                    rows={3}
                    as={"textarea"}
                    value={izbraneOpombeRezi}
                    onChange={(e) => setIzbraneOpombeRezi(e.target.value)}
                    placeholder="Vnesite morebitne opombe"
                  />

                  <Form.Text className="text-muted">
                    Vnesite morebitne opombe
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CesnjaPodatkiORezi;
