import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import fetcherPost from "../../fetcher/fetcherPost";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import EditableCell from "../../EditableCell";
import DeletionRow from "../../DeletionRow";

const BorovnicaPridelek = ({
  vsiPridelki,
  fetchPodatkePridelek,
  setShowModal,
  setModalError,
  setNiPodatkovZaIzbranoLeto,
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();

  const [datumObiranja, setDatumObiranja] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const [masaPridelkaTrzneKakovosti, setMasaPridelkaTrzneKakovosti] =
    useState();
  const [masaPridelkaNetrzneKakovosti, setMasaPridelkaNetrzneKakovosti] =
    useState();

  useEffect(() => {
    setNiPodatkovZaIzbranoLeto(vsiPridelki?.length > 0 ? false : true);
  }, [vsiPridelki]);

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: " LightSalmon" }}>
        <Card.Header>Podatki o pridelku</Card.Header>

        <Card.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: " LightSalmon" }}>
                    Datum obiranja
                  </th>
                  <th style={{ backgroundColor: " LightSalmon" }}>
                    Masa pridelka tržne kakovosti (g/grm)
                  </th>
                  <th style={{ backgroundColor: " LightSalmon" }}>
                    Masa pridelka netržne kakovosti (g/grm)
                  </th>
                  <th style={{ backgroundColor: " LightSalmon" }}>
                    Skupaj pridelka (kg/parcelo)
                  </th>
                  <th style={{ backgroundColor: " LightSalmon" }}>
                    Skupaj (kg/hektar)
                  </th>
                  {vsiPridelki[0]?.datum &&
                  new Date(vsiPridelki[0]?.datum).getFullYear() ===
                    new Date().getFullYear() ? (
                    <th style={{ backgroundColor: " LightSalmon" }}>Izbris</th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {vsiPridelki &&
                  vsiPridelki.map((podatek) => (
                    <DeletionRow
                      id={podatek.id}
                      key={podatek.id}
                      link={"borovnica/pridelek"}
                      setModalError={setModalError}
                      setShowModal={setShowModal}
                      fetchNewData={fetchPodatkePridelek}
                      briseSe={"PRIDELEK"}
                      jeTrenutnoLeto={
                        new Date(podatek.datum).getFullYear() ===
                        new Date().getFullYear()
                      }
                      children={
                        <>
                          <td>
                            <EditableCell
                              initialValue={podatek.datum}
                              inputType={"date"}
                              id={podatek.id}
                              link={"borovnica/pridelek"}
                              imeLastnosti={"datum"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkePridelek}
                              spreminjaSe={"PRIDELEK_DATUM"}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={podatek.masaPridelkaTrzneKakovosti}
                              inputType={"number"}
                              id={podatek.id}
                              link={"borovnica/pridelek"}
                              imeLastnosti={"masaPridelkaTrzneKakovosti"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkePridelek}
                              spreminjaSe={"PRIDELEK_MASA_TRZNE_KAKOVOSTI"}
                              step={0.1}
                              min={0}
                              max={Number.MAX_SAFE_INTEGER}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>
                            <EditableCell
                              initialValue={
                                podatek.masaPridelkaNetrzneKakovosti
                              }
                              inputType={"number"}
                              id={podatek.id}
                              link={"borovnica/pridelek"}
                              imeLastnosti={"masaPridelkaNetrzneKakovosti"}
                              setModalError={setModalError}
                              setShowModal={setShowModal}
                              fetchNewData={fetchPodatkePridelek}
                              spreminjaSe={"PRIDELEK_MASA_NETRZNE_KAKOVOSTI"}
                              step={0.1}
                              min={0}
                              max={Number.MAX_SAFE_INTEGER}
                              jeTrenutnoLeto={
                                new Date(podatek.datum).getFullYear() ===
                                new Date().getFullYear()
                              }
                            />
                          </td>
                          <td>{podatek.masaPridelkaNaParcelo}</td>
                          <td>{podatek.masaPridelkaNaHektar}</td>
                        </>
                      }
                    />
                  ))}
              </tbody>
            </Table>
          </div>

          <Card className="m-1" style={{ backgroundColor: " LightSalmon" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await fetcherPost(
                    `borovnica/pridelek`,
                    {
                      datum: datumObiranja,
                      masaPridelkaTrzneKakovosti: parseFloat(
                        masaPridelkaTrzneKakovosti
                      ),
                      masaPridelkaNetrzneKakovosti: parseFloat(
                        masaPridelkaNetrzneKakovosti
                      ),
                      tip: "PRIDELEK",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setMasaPridelkaTrzneKakovosti("");
                  setMasaPridelkaNetrzneKakovosti("");
                  setDatumObiranja(new Date().toISOString().slice(0, 10));

                  fetchPodatkePridelek(new AbortController().signal);
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor=" datumObiranja">Datum:</Form.Label>
                  <Form.Control
                    id=" datumObiranja"
                    type="date"
                    value={datumObiranja}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setDatumObiranja(e.target.value)}
                    placeholder="Vnesite datum obiranja ameriške borovnice"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum obiranja ameriške borovnice
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="masaTrzneKakovosti">
                    Masa pridelka tržne kakovosti (g/grm)
                  </Form.Label>
                  <Form.Control
                    id="masaTrzneKakovosti"
                    type="number"
                    min={0.0}
                    step={0.1}
                    required
                    value={masaPridelkaTrzneKakovosti}
                    onChange={(e) =>
                      setMasaPridelkaTrzneKakovosti(e.target.value)
                    }
                    placeholder={`Vnesite maso pridelka tržne kakovosti (g/grm)`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite maso pridelka tržne kakovosti (g/grm)
                  </Form.Text>
                </FormGroup>
                <FormGroup className="m-2">
                  <Form.Label htmlFor="masaNetrzneKakovosti">
                    Masa pridelka netržne kakovosti (g/grm)
                  </Form.Label>
                  <Form.Control
                    id="masaNetrzneKakovosti"
                    type="number"
                    min={0.0}
                    step={0.1}
                    required
                    value={masaPridelkaNetrzneKakovosti}
                    onChange={(e) =>
                      setMasaPridelkaNetrzneKakovosti(e.target.value)
                    }
                    placeholder={`Vnesite maso pridelka netržne kakovosti (g/grm)`}
                  />

                  <Form.Text className="text-muted">
                    Vnesite maso pridelka netržne kakovosti (g/grm)
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default BorovnicaPridelek;
