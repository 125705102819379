import React, { useCallback, useState, useEffect } from "react";
import {
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Col,
  Container,
  Alert,
} from "react-bootstrap";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../utils/baseUrl";

const UrediPodatkeOLastniku = ({ setModalError, setShowModal }) => {
  const auth = useAuthUser();
  const signOut = useSignOut();
  const history = useNavigate();

  const [ime, setIme] = useState("");
  const [priimek, setPriimek] = useState("");

  const [ulica, setUlica] = useState("");
  const [hisnaStevilka, setHisnaStevilka] = useState("");

  const [postnaStevilka, setPostnaStevilka] = useState(0);
  const [isZipCodeValid, setIsZipCodeValid] = useState(null);

  const [kraj, setKraj] = useState("");
  const [successfullyUpdatedData, setSuccessfullyUpdatedData] = useState(false);

  const [staroGeslo, setStaroGeslo] = useState("");
  const [novoGeslo1, setNovoGeslo1] = useState("");
  const [novoGeslo2, setNovoGeslo2] = useState("");
  const [passwordError, setPasswordError] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);



  const fetchPodatkeONosilcu = useCallback(
    async (signal) => {
      try {
        const res = await fetch(
          `${BASE_URL}/uporabnik/${auth.uporabniskoIme}`,
          {
            method: "GET",
            signal: signal,
            credentials: "include",
          }
        );

        if (res.status === 401) {
          setModalError(`Napaka pri avtorizaciji uporabnika.`);
          setShowModal(true);

          signOut();
          history("/prijava");

          return;
        }

        const data = await res.json();
        if (res.status === 400) {
          setModalError(
            `Napaka pri pridobivanju podatkov o nosilcu GERK-a na podlagi uporabniškega imena ${
              auth.uporabniskoIme
            } na zalednem delu. ${data?.message}`
          );
          setShowModal(true);
          return;
        }

        if (res.status === 200) {
          setIme(data.ime);
          setPriimek(data.priimek);

          setUlica(data.ulica);
          setHisnaStevilka(data.hisnaStevilka);

          setKraj(data.kraj);
          setPostnaStevilka(data.postnaStevilka);
        }
      } catch (error) {
        if (error.name === "AbortError") return;

        setModalError(
          `Napaka pri pridobivanju podatkov o nosilcu GERK-a na podlagi uporabniškega imena ${
            auth.uporabniskoIme
          }. ${error?.message}`
        );
        setShowModal(true);
      }
    },
    [history, signOut, auth, setShowModal, setModalError]
  );

  const handlePostnaStevilkaChange = (postnaStevilkaStr) => {
    // if (postnaStevilkaStr.length < 4 && isZipCodeValid === null) return;

    const postnaStevilka = parseInt(postnaStevilkaStr);
    setPostnaStevilka(postnaStevilka);

    if (
      postnaStevilka < 1000 ||
      (postnaStevilka > 1434 && postnaStevilka < 2000) ||
      (postnaStevilka > 2394 && postnaStevilka < 3000) ||
      (postnaStevilka > 3342 && postnaStevilka < 4000) ||
      (postnaStevilka > 4294 && postnaStevilka < 5000) ||
      (postnaStevilka > 5297 && postnaStevilka < 6000) ||
      (postnaStevilka > 6333 && postnaStevilka < 8000) ||
      (postnaStevilka > 8362 && postnaStevilka < 9000) ||
      postnaStevilka > 9265 ||
      postnaStevilkaStr.length === 0
    ) {
      setIsZipCodeValid(false);
      return;
    }

    setIsZipCodeValid(true);
  };

  const handlePosodobiPodatke = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${BASE_URL}/uporabnik/${auth.uporabniskoIme}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            ime: ime,
            priimek: priimek,
            ulica: ulica,
            hisnaStevilka: hisnaStevilka,
            postnaStevilka: postnaStevilka,
            kraj: kraj,
            midStevilka: auth.midStevilka,
          }),
        }
      );

      if (response.status === 401) {
        setModalError(`Napaka pri avtorizaciji uporabnika.`);
        setShowModal(true);

        signOut();
        history("/prijava");

        return;
      }

      const data = await response.json();
      if (response.status === 400 && data?.message) {
        setModalError(
          `Napaka pri posodobitvi podatkov o nosilcu z MID številko ${
            auth.midStevilka
          } na strežniškem delu. ${data?.message}`
        );
        setShowModal(true);
        return;
      }

      if (response.status === 200 && data.dataUpdatedSuccessfully === true) {
        setIme(data.noviPodatkiONosilcu.ime);
        setPriimek(data.noviPodatkiONosilcu.priimek);

        setUlica(data.noviPodatkiONosilcu.ulica);
        setHisnaStevilka(data.noviPodatkiONosilcu.hisnaStevilka);

        setPostnaStevilka(data.noviPodatkiONosilcu.postnaStevilka);
        setKraj(data.noviPodatkiONosilcu.kraj);

        setSuccessfullyUpdatedData(true);
      }
    } catch (error) {
      if (error?.name === "AbortError") return;

      setModalError(
        `Napaka pri posodobitvi podatkov o nosilcu z MID številko ${
          auth.midStevilka
        }. ${error?.message}`
      );
      setShowModal(true);
    }
  };
  const validatePasswords = () => {
    if (novoGeslo1 !== novoGeslo2) {
      setPasswordError('Novi gesli se ne ujemata.');
      return false;
    }
    if (novoGeslo1.length < 5) {
      setPasswordError('Novo geslo mora vsebovati vsaj 5 znakov.');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const handlePosodobiGeslo = async (e) => {
    e.preventDefault();

    if (!validatePasswords()) return;

    try {
      const response = await fetch(
        `${BASE_URL}/uporabnik/spremeniGeslo/${auth.uporabniskoIme}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            ime: auth.ime,
            staroGeslo: staroGeslo,
            novoGeslo: novoGeslo1,
          }),
        }
      );

      if (response.status === 401) {
        // setModalError(`Vnesli ste napačno trenutno geslo.`);
        setPasswordError(`Vnesli ste napačno trenutno geslo.`);
        setStaroGeslo('');

        // setShowModal(true);
        // signOut();
        // history("/prijava");
        return;
      }

      const data = await response.json();
      if (response.status === 400 && data?.message) {
        setModalError(
          `Napaka pri posodobitvi gesla. ${data?.message}`
        );
        setShowModal(true);
        return;
      }

      if (response.status === 200) {
        // console.log("Uspešno spremenjeno geslo", data);
        setPasswordChanged(true); // Show success message
        setStaroGeslo('');
        setNovoGeslo1('');
        setNovoGeslo2('');
        setPasswordError('');
        // Handle successful password change
      }
    } catch (error) {
      if (error?.name === "AbortError") return;

      setModalError(
        `Napaka pri posodobitvi gesla. ${error?.message}`
      );
      setShowModal(true);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchPodatkeONosilcu(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Container className="vh-100 d-flex flex-column">

    <Row className="vh-100 d-flex justify-content-center align-items-center">
      <Card className="m-1">
        <Card.Body>
          <Card.Title>
            Urejanje podatkov o nosilcu z MID številko {auth.midStevilka}
          </Card.Title>

          <Form onSubmit={handlePosodobiPodatke}>
            <Container>
              <Row>
                <Col lg>
                  <Form.Group className="mb-3" controlId="ime">
                    <Form.Label className="text-center">Ime</Form.Label>
                    <Form.Control
                      type="text"
                      minLength={3}
                      value={ime}
                      onChange={(e) => {
                        setSuccessfullyUpdatedData(false);
                        setIme(e.target.value);
                      }}
                      placeholder="Uredite vašo ime"
                    />
                  </Form.Group>
                </Col>

                <Col lg>
                  <Form.Group className="mb-3" controlId="priimek">
                    <Form.Label className="text-center">Priimek</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        setSuccessfullyUpdatedData(false);
                        setPriimek(e.target.value);
                      }}
                      minLength={3}
                      required
                      value={priimek}
                      placeholder="Uredite vaš priimek"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={5}>
                  <Form.Group className="mb-3" controlId="ulica">
                    <Form.Label>Ulica</Form.Label>

                    <Form.Control
                      type="text"
                      required
                      minLength={5}
                      onChange={(e) => {
                        setSuccessfullyUpdatedData(false);
                        setUlica(e.target.value);
                      }}
                      placeholder="Uredite vašo ulico"
                      value={ulica}
                    />
                  </Form.Group>
                </Col>
                <Col xl={3}>
                  <Form.Group className="mb-3" controlId="hisnaStevilka">
                    <Form.Label>Hišna številka</Form.Label>

                    <Form.Control
                      type="text"
                      required
                      minLength={1}
                      onChange={(e) => {
                        setSuccessfullyUpdatedData(false);
                        setHisnaStevilka(e.target.value);
                      }}
                      placeholder="Uredite vašo hišno številko"
                      value={hisnaStevilka}
                    />
                  </Form.Group>
                </Col>

                <Col xl={4}>
                  <Form.Group className="mb-3" controlId="hisnaStevilka">
                    <Form.Label>Poštna številka</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="number"
                        required
                        minLength={4}
                        onChange={(e) => {
                          setSuccessfullyUpdatedData(false);
                          handlePostnaStevilkaChange(e.target.value);
                        }}
                        value={postnaStevilka}
                        isValid={isZipCodeValid}
                        isInvalid={!isZipCodeValid && isZipCodeValid !== null}
                        placeholder="Uredite vašo poštno številko"
                      />

                      <Form.Control.Feedback type="valid">
                        Poštna številka je veljavna
                      </Form.Control.Feedback>

                      <Form.Control.Feedback type="invalid">
                        Poštna številka ni veljavna
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Form.Group className="mb-3" controlId="gesloPrvic">
                  <Form.Label>Kraj/naselje</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Uredite kraj oz. naselje"
                    minLength={3}
                    required
                    value={kraj}
                    onChange={(e) => {
                      setSuccessfullyUpdatedData(false);
                      setKraj(e.target.value);
                    }}
                  />
                </Form.Group>
              </Row>

              <Row>
                <div className="d-grid">
                  <Button variant="primary" type="submit">
                    Posodobi podatke
                  </Button>
                </div>
              </Row>
              <Row className="d-flex justify-content-center align-items-center">
                <Alert
                  key={"success"}
                  variant={"success"}
                  show={successfullyUpdatedData}
                  className="m-2 g-1 w-50 h-25"
                >
                  Uspešno posodobili podatke o nosilcu!
                </Alert>
              </Row>
            </Container>
          </Form>
        </Card.Body>
      </Card>
    </Row>
     <Row className="vh-100 d-flex justify-content-center align-items-center mb-5">
     <Col lg>
       <Card className="">
         <Card.Header>
           <h3 className="mb-0">Posodobi geslo</h3>
         </Card.Header>
         <Card.Body >
         <Form className="form" role="form" autoComplete="off" onSubmit={handlePosodobiGeslo}>
        <Form.Group className="mb-3" controlId="inputPasswordOld">
          <Form.Label>Trenutno geslo</Form.Label>
          <Form.Control
            onChange={(e) => {setPasswordChanged(false);
              setStaroGeslo(e.target.value);}}
            type="password"
            id="inputPasswordOld"
            required
            value={staroGeslo}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="inputPasswordNew">
          <Form.Label>Novo geslo</Form.Label>
          <Form.Control
            type="password"
            id="inputPasswordNew"
            required
            onChange={(e) =>{setPasswordChanged(false);
               setNovoGeslo1(e.target.value);}}
            value={novoGeslo1}

          />
          <Form.Text className="text-muted">
            Geslo mora vsebovati najmanj 5 znakov.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="inputPasswordNewVerify">
          <Form.Label>Potrdi</Form.Label>
          <Form.Control
            type="password"
            id="inputPasswordNewVerify"
            required
            onChange={(e) => {setPasswordChanged(false);
              setNovoGeslo2(e.target.value);}}
            value={novoGeslo2}
          />
          <Form.Text className="text-muted">
            Za potrditev še enkrat vpišite novo geslo.
          </Form.Text>
        </Form.Group>
        {passwordError && (
          <Alert variant="danger">
            {passwordError}
          </Alert>
        )}
        {passwordChanged && (
                  <Alert variant="success">
                    Geslo je bilo uspešno spremenjeno!
                  </Alert>
                )}
        <Form.Group className="mb-3">
        <Row>
                <div className="d-grid">
                <Button variant="primary" type="submit" className="float-end">
                Posodobi geslo
                </Button>
                </div>
        </Row>
          
        </Form.Group>
      </Form>
         </Card.Body>
         <Card.Footer className="text-center">
          <Button href="/domacaStran" variant="primary">
            Domača stran
          </Button>
        </Card.Footer>
       </Card>
     </Col>
   </Row>
 </Container>
  );
};

export default UrediPodatkeOLastniku;
