import React, { useState, useCallback } from "react";
import { Tab, Tabs, Card } from "react-bootstrap";
import JablanaMesanicaVnos from "./JablanaMesanicaVnos";
import JablanaPosameznaFertirigacijaVnos from "./JablanaPosameznaFertirigacijaVnos";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import fetcherGet from "../../fetcher/fetcherGet";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import ModalError from "../../modals/ModalError";
import { useNavigate } from "react-router-dom";

const JablanaFertirigacijaVnos = () => {
  const [key, setKey] = useState("mesanica");
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const auth = useAuthUser();

  const signOut = useSignOut();
  const history = useNavigate();
  const [mesaniceNaVoljo, setMesaniceNaVoljo] = useState({});

  const fetchMesanice = useCallback(
    async (signal) => {
      return fetcherGet(
        "jablana/fertirigacija",
        signal,
        auth.midStevilka,
        setModalError,
        setShowModal,
        signOut,
        history,
        setMesaniceNaVoljo
      );
    },
    [history, signOut, auth.midStevilka]
  );

  return (
    <>
      <div
        className="mt-5 d-flex justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
          <Card>
            <Card.Body>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="mesanica" title="Vnos mešanice za fertirigacijo">
                  <JablanaMesanicaVnos
                    modalError={modalError}
                    setModalError={setModalError}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    fetchMesanice={fetchMesanice}
                  
                  />
                </Tab>
                <Tab
                  eventKey="fertirigacija"
                  title="Vnos fertirigacije za nasade"
                >
                  <JablanaPosameznaFertirigacijaVnos
                    setModalError={setModalError}
                    setShowModal={setShowModal}
                    mesaniceNaVoljo={mesaniceNaVoljo}
                    fetchMesanice={fetchMesanice}
                  />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </div>
      </div>
      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default JablanaFertirigacijaVnos;
