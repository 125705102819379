import BASE_URL from "../../utils/baseUrl";

const fetcherDelete = async (
  link,
  id,
  briseSe,
  setModalError,
  setShowModal,
  signOut,
  history,
) => {
  try {
    const reqLink =
    briseSe === null
      ? `${BASE_URL}/${link}/${id}`
      : `${BASE_URL}/${link}/${id}?briseSe=${briseSe}`;

    const res = await fetch(reqLink, {
      method: "DELETE",
      signal: new AbortController().signal,
      credentials: "include",
    });

    if (res.status === 401) {
      setModalError(`Napaka pri avtorizaciji uporabnika.`);
      setShowModal(true);

      signOut();
      history("/prijava");

      return;
    }

    const data = await res.json();
    if (res.status === 400) {
      setModalError(
        `Napaka pri izbrisu splošnih podatkov o ${link} na zalednem delu. ${data?.message}`
      );
      setShowModal(true);
      return;
    }
    if (res.status === 200 && data.dataDeletedSuccessfully) {
      return true
    } else {
      setModalError(
        `Napaka pri izbrisu podatkov. Podatki niso uspešno izbrisani.`
      );
      setShowModal(true);
    }
  } catch (error) {
    if (error.name === "AbortError") return;

    setModalError(
      `Napaka pri izbrisu splošnih podatkov o ${link} na podlagi Nasad-idja. ${error?.message}`
    );
    setShowModal(true);
  }

  return false
};

export default fetcherDelete;
