import BASE_URL from "../../utils/baseUrl";

const fetcherPost = async (link, payload, nasadId, setModalError, setShowModal, signOut, history) => {
  try {
    const res = await fetch(BASE_URL + `/${link}/${nasadId}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (res.status === 401) {
      setModalError(`Napaka pri avtorizaciji uporabnika.`);
      setShowModal(true);

      signOut();
      history("/prijava");

      return false;
    }

    const data = await res.json();

    if (res.status === 400) {
      setShowModal(true);
      setModalError(`Napaka pri shranjevanju podatkov. ${data?.message}`);

      return false;
    }

    if (data.dataSavedSuccessfully && res.status === 201) {
      return true;
    } else {
      setModalError(
        `Napaka pri shranjevanju podatkov. Podatki niso uspešno shranjeni.`
      );
      setShowModal(true);
    }
  } catch (error) {
    setShowModal(true);
    setModalError(`Napaka pri shranjevanju podatkov. ${error?.message}`);
  }

  return false;
};

export default fetcherPost;
