import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import useCommonContext from "../../../hooks/useCommonContext";
import fetcherPost from "../../fetcher/fetcherPost";
import ModalError from "../../modals/ModalError";
import { VRSTE_BIODIVERZITETE } from "./vrsteBiodiverzitete";
import { useFetchAktivniNasadi } from "../../../hooks/useFetchAktivniNasadi";
import AktivniSeznamiChecklist from "../common/AktivniSeznamiChecklist";
import SaveButton from "../common/SaveButton";

const JablanaBiodiverzitetaVnos = () => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const [aktivniNasadi, setAktivniNasadi] = useState([]);
  const [vrstaBiodiverzitete, setVrstaBiodiverzitete] = useState();

  const [opombe, setOpombe] = useState("");
  const [idIzbranihNasadov, setIdIzbranihNasadov] = useState({});

  const fetchAktivniNasadi = useFetchAktivniNasadi(
    setModalError,
    setShowModal,
    signOut,
    history,
    setAktivniNasadi
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchAktivniNasadi(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div
      className="mt-5 d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div className="w-100 g-3" style={{ maxWidth: "1500px" }}>
        <Card>
          <Card.Body>
            <Row>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  e.target.reset();
                  await fetcherPost(
                    `jablana/biodiverziteta`,
                    {
                      vrstaBiodiverzitete: vrstaBiodiverzitete,
                      opombe: opombe,
                      idIzbranihNasadov: Object.keys(idIzbranihNasadov)
                        .filter(
                          (key) => idIzbranihNasadov[key].isChecked === true
                        )
                        .map((key) => ({
                          nasadId: key,
                        })),
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setVrstaBiodiverzitete();
                  setOpombe();

                  setIdIzbranihNasadov({});
                }}
              >
                <Form.Label htmlFor="skodljivec">
                  <h4>
                    Vnos podatkov o ohranjanju biodiverzitete nasada jablane
                  </h4>
                </Form.Label>

                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "IndianRed" }}>Vrsta</th>
                      <th style={{ backgroundColor: "IndianRed" }}>Opomba</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Select
                          size="sm"
                          required
                          value={vrstaBiodiverzitete}
                          onChange={(e) => {
                            setVrstaBiodiverzitete(e.target.value);
                          }}
                          isInvalid={
                            vrstaBiodiverzitete === undefined
                              ? false
                              : vrstaBiodiverzitete === ""
                          }
                        >
                          <option hidden>Izberite vrsto ohranjanja</option>

                          {VRSTE_BIODIVERZITETE.map((biodiverziteta, index) => (
                            <option key={index} value={biodiverziteta}>
                              {biodiverziteta}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Control
                          id="opombe"
                          as="textarea"
                          value={opombe}
                          minLength={3}
                          onChange={(e) => setOpombe(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <AktivniSeznamiChecklist
                  text={
                    "Nasadi z izvedenimi ukrepi za ohranjanje biodiverzitete (GERK, sorta, leto nasaditve, datum spravila):"
                  }
                  setIdIzbranihNasadov={setIdIzbranihNasadov}
                  aktivniNasadi={aktivniNasadi}
                  idIzbranihNasadov={idIzbranihNasadov}
                />

                <SaveButton
                  disabledCondition={
                    Object.keys(idIzbranihNasadov).filter(
                      (key) => idIzbranihNasadov[key].isChecked === true
                    ).length === 0 || !vrstaBiodiverzitete
                  }
                />
              </Form>
            </Row>
          </Card.Body>
        </Card>
      </div>

      <ModalError
        modalError={modalError}
        show={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default JablanaBiodiverzitetaVnos;
