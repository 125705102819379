import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  Card,
  InputGroup,
} from "react-bootstrap";
import useCommonContext from "../../../hooks/useCommonContext";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import fetcherPost from "../../fetcher/fetcherPost";
import CesnjaPridelekTabela from "./pridelek/CesnjaPridelekTabela";

const CesnjaPridelek = ({
  fetchPodatkeOcenjevanjeInNapoved,
  setShowModal,
  setModalError,
  pridelekCesnje,
  skupneVrednosti,
  setNiPodatkovZaIzbranoLeto
}) => {
  const signOut = useSignOut();
  const history = useNavigate();

  const { nasadId } = useCommonContext();
  const [izbranDatumObiranja, setIzbranDatumObiranja] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [izbranoSteviloObranihCesenj, setIzbranoSteviloObranihCesenj] =
    useState();
  const [izbranKgTrzneKakovosti, setIzbranKgTrzneKakovosti] = useState();
  const [izbranKgNetrzneKakovosti, setIzbranKgNetrzneKakovosti] = useState();
  const [izbranaDebelinaPlodovDo26, setIzbranaDebelinaPlodovDo26] = useState();
  const [izbranaDebelinaPlodovOd26Do28, setIzbranaDebelinaPlodovOd26Do28] =
    useState();
  const [izbranaDebelinaPlodovOd28, setIzbranaDebelinaPlodovOd28] = useState();
  const [izbranaMasa50Plodov, setIzbranaMasa50Plodov] = useState();
  const [izbranTSS, setIzbranTSS] = useState();

  return (
    <Row>
      <Card className="m-1" style={{ backgroundColor: "AliceBlue" }}>
        <Card.Header>Pridelek</Card.Header>
        <Card.Body>
          <CesnjaPridelekTabela
            pridelekCesnje={pridelekCesnje}
            skupneVrednosti={skupneVrednosti}
            fetchPodatkeOcenjevanjeInNapoved={fetchPodatkeOcenjevanjeInNapoved}
            setShowModal={setShowModal}
            setModalError={setModalError}
            setNiPodatkovZaIzbranoLeto={setNiPodatkovZaIzbranoLeto}
          />

          <Card className="m-1" style={{ backgroundColor: "AliceBlue" }}>
            <Card.Body>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();

                  if (
                    izbranaDebelinaPlodovDo26 +
                      izbranaDebelinaPlodovOd26Do28 +
                      izbranaDebelinaPlodovOd28 !==
                    100
                  ) {
                    setModalError("Vsota deležev debelin plodov ni enaka 100!");
                    setShowModal(true);

                    return;
                  }

                  e.target.reset();

                  await fetcherPost(
                    `cesnja/ocenjavanjeInNapovedovanjePridelka`,
                    {
                      datum: izbranDatumObiranja,
                      steviloObranihDreves: izbranoSteviloObranihCesenj,
                      kgTrzneKakovosti: izbranKgTrzneKakovosti,
                      kgNetrzneKakovosti: izbranKgNetrzneKakovosti,
                      debelinaPlodovDo26: izbranaDebelinaPlodovDo26,
                      debelinaPlodovOd26Do28: izbranaDebelinaPlodovOd26Do28,
                      debelinaPlodovOd28: izbranaDebelinaPlodovOd28,
                      masa50Plodov: izbranaMasa50Plodov,
                      tss: izbranTSS,
                      tip: "PRIDELEK",
                    },
                    nasadId,
                    setModalError,
                    setShowModal,
                    signOut,
                    history
                  );

                  setIzbranDatumObiranja(new Date().toISOString().slice(0, 10));
                  setIzbranoSteviloObranihCesenj();

                  setIzbranKgTrzneKakovosti();
                  setIzbranKgNetrzneKakovosti();

                  setIzbranaDebelinaPlodovDo26();
                  setIzbranaDebelinaPlodovOd26Do28();

                  setIzbranaDebelinaPlodovOd28();
                  setIzbranaMasa50Plodov();
                  setIzbranTSS();

                  fetchPodatkeOcenjevanjeInNapoved();
                }}
              >
                <FormGroup className="m-2">
                  <Form.Label htmlFor="datumObiranja">Datum:</Form.Label>
                  <Form.Control
                    id="datumObiranja"
                    type="date"
                    value={izbranDatumObiranja}
                    min={`${new Date().getFullYear()}-01-01`}
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => setIzbranDatumObiranja(e.target.value)}
                    placeholder="Vnesite datum obiranje"
                  />
                  <Form.Text className="text-muted">
                    Izberite datum obiranja češenj
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="steviloObranihCesenj">
                    Število obiranih dreves:
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="steviloObranihCesenj"
                    value={izbranoSteviloObranihCesenj}
                    required
                    min={0}
                    step={1}
                    placeholder="Vnesite število obranih dreves"
                    onChange={(e) =>
                      setIzbranoSteviloObranihCesenj(e.target.value)
                    }
                  />
                  <Form.Text className="text-muted">
                    Vnesite število obiranih dreves
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="kgTrzneKakovosti">
                    Masa pridelka tržne kakovosti (kg/obiranje):
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="kgTrzneKakovosti"
                    value={izbranKgTrzneKakovosti}
                    required
                    min={0}
                    step={0.1}
                    placeholder="Vnesite maso pridelka tržne kakovosti (kg/obiranje)"
                    onChange={(e) => setIzbranKgTrzneKakovosti(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Vnesite maso pridelka tržne kakovosti (kg/obiranje)
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="kgNetrzneKakovosti">
                    Masa pridelka netržne kakovosti (kg/obiranje):
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="kgNetrzneKakovosti"
                    value={izbranKgNetrzneKakovosti}
                    required
                    min={0}
                    step={0.1}
                    placeholder="Vnesite maso pridelka netržne kakovosti (kg/obiranje)"
                    onChange={(e) =>
                      setIzbranKgNetrzneKakovosti(e.target.value)
                    }
                  />
                  <Form.Text className="text-muted">
                    Vnesite maso pridelka netržne kakovosti (kg/obiranje)
                  </Form.Text>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="debelinaPlodovDo26">
                    Delež plodov manjši od 26mm v %:
                  </Form.Label>

                  <Form.Control
                    type="number"
                    name="debelinaPlodovDo26"
                    value={izbranaDebelinaPlodovDo26}
                    required
                    min={0}
                    max={100}
                    step={1}
                    isValid={
                      izbranaDebelinaPlodovDo26 <= 100 &&
                      izbranaDebelinaPlodovDo26 >= 0
                    }
                    isInvalid={
                      izbranaDebelinaPlodovDo26 > 100 ||
                      izbranaDebelinaPlodovDo26 < 0
                    }
                    placeholder="Vnesite delež plodov s premerov manjši od 26mm v %"
                    onChange={(e) =>
                      setIzbranaDebelinaPlodovDo26(parseInt(e.target.value))
                    }
                  />
                  <Form.Text className="text-muted">
                    Vnesite delež plodov manjši od 26mm v %
                  </Form.Text>

                  <Form.Control.Feedback type="valid">
                    Vneseni delež plodov s premerom manj kot 26mm je veljaven.
                    Za ostala deleža ostane še {100 - izbranaDebelinaPlodovDo26}
                    %
                  </Form.Control.Feedback>

                  <Form.Control.Feedback type="invalid">
                    Vneseni delež plodov s premerom manj kot 26mm ni veljaven
                  </Form.Control.Feedback>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="debelinaPlodovOd26Do28">
                    Delež plodov med 26mm in 28mm v %:
                  </Form.Label>

                  <Form.Control
                    type="number"
                    name="debelinaPlodovOd26Do28"
                    value={izbranaDebelinaPlodovOd26Do28}
                    required
                    min={0}
                    max={100}
                    step={1}
                    isValid={
                      izbranaDebelinaPlodovOd26Do28 <= 100 &&
                      izbranaDebelinaPlodovOd26Do28 >= 0 &&
                      izbranaDebelinaPlodovDo26 +
                        izbranaDebelinaPlodovOd26Do28 <=
                        100
                    }
                    isInvalid={
                      izbranaDebelinaPlodovOd26Do28 > 100 ||
                      izbranaDebelinaPlodovOd26Do28 < 0 ||
                      izbranaDebelinaPlodovDo26 +
                        izbranaDebelinaPlodovOd26Do28 >
                        100
                    }
                    placeholder="Vnesite delež plodov s premerov med 26mm in 28mm v %"
                    onChange={(e) => {
                      setIzbranaDebelinaPlodovOd26Do28(
                        parseInt(e.target.value)
                      );

                      if (isNaN(parseInt(e.target.value))) {
                        setIzbranaDebelinaPlodovOd28();
                        return;
                      }

                      if (
                        parseInt(e.target.value) + izbranaDebelinaPlodovDo26 <=
                        100
                      ) {
                        setIzbranaDebelinaPlodovOd28(
                          100 -
                            izbranaDebelinaPlodovDo26 -
                            parseInt(e.target.value)
                        );
                      } else {
                        setIzbranaDebelinaPlodovOd28();
                      }
                    }}
                  />
                  <Form.Text className="text-muted">
                    Vnesite delež plodov med 26mm in 28mm v %
                  </Form.Text>

                  <Form.Control.Feedback type="valid">
                    Vneseni delež plodov s premerom od 26 do 28mm je veljaven.
                    Za preostali delež ostane še{" "}
                    {100 -
                      izbranaDebelinaPlodovDo26 -
                      izbranaDebelinaPlodovOd26Do28}
                    %
                  </Form.Control.Feedback>

                  <Form.Control.Feedback type="invalid">
                    Vneseni delež plodov s premerom od 26 do 28mm ni veljaven.
                  </Form.Control.Feedback>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="debelinaPlodovOd28">
                    Delež plodov nad 28mm v %:
                  </Form.Label>

                  <Form.Control
                    type="number"
                    name="debelinaPlodovOd28"
                    value={izbranaDebelinaPlodovOd28}
                    required
                    min={0}
                    max={100}
                    step={1}
                    isValid={
                      izbranaDebelinaPlodovOd28 <= 100 &&
                      izbranaDebelinaPlodovOd28 >= 0 &&
                      izbranaDebelinaPlodovDo26 +
                        izbranaDebelinaPlodovOd26Do28 +
                        izbranaDebelinaPlodovOd28 ===
                        100
                    }
                    isInvalid={
                      izbranaDebelinaPlodovOd28 > 100 ||
                      izbranaDebelinaPlodovOd28 < 0 ||
                      izbranaDebelinaPlodovDo26 +
                        izbranaDebelinaPlodovOd26Do28 +
                        izbranaDebelinaPlodovOd28 >
                        100 ||
                      izbranaDebelinaPlodovDo26 +
                        izbranaDebelinaPlodovOd26Do28 +
                        izbranaDebelinaPlodovOd28 <
                        100
                    }
                    placeholder="Vnesite delež plodov s premerov nad 28mm v %"
                    onChange={(e) =>
                      setIzbranaDebelinaPlodovOd28(parseInt(e.target.value))
                    }
                  />
                  <Form.Text className="text-muted">
                    Vnesite delež plodov večji od 28mm v %
                  </Form.Text>

                  <Form.Control.Feedback type="valid">
                    Vneseni delež plodov s premerom večjim do kot 28mm je
                    veljaven.
                  </Form.Control.Feedback>

                  <Form.Control.Feedback type="invalid">
                    Vneseni delež plodov s premerom večjim kot 28mm ni veljaven.
                    {izbranaDebelinaPlodovDo26 +
                      izbranaDebelinaPlodovOd26Do28 +
                      izbranaDebelinaPlodovOd28 >
                    100 ? (
                      <>
                        Vsote deležev presegajo 100%! Glede na prejšnja deleža,
                        je ta delež natanko{" "}
                        {100 -
                          izbranaDebelinaPlodovDo26 -
                          izbranaDebelinaPlodovOd26Do28}
                        %
                      </>
                    ) : (
                      <>
                        Vsote deležev ne dosegajo 100%! Glede na prejšnja
                        deleža, je ta delež natanko{" "}
                        {100 -
                          (izbranaDebelinaPlodovDo26 +
                            izbranaDebelinaPlodovOd26Do28)}
                        %
                      </>
                    )}
                  </Form.Control.Feedback>
                </FormGroup>

                <FormGroup className="m-2">
                  <Form.Label htmlFor="masa50PlodovInTss">
                    Masa 50 plodov (g) in TSS (Brix°):
                  </Form.Label>
                  <InputGroup id="masa50PlodovInTss">
                    <InputGroup.Text htmlFor="masa50Plodov">
                      Masa 50 plodov (g)
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="masa50Plodov"
                      value={izbranaMasa50Plodov}
                      required
                      min={0}
                      step={0.1}
                      placeholder="Vnesite maso 50 plodov (g)"
                      onChange={(e) => setIzbranaMasa50Plodov(e.target.value)}
                    />
                    <InputGroup.Text htmlFor="tss">TSS (Brix°)</InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="tss"
                      value={izbranTSS}
                      required
                      min={0}
                      step={0.1}
                      placeholder="Vnesite TSS (Brix°)"
                      onChange={(e) => setIzbranTSS(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="m-2">
                  <Container>
                    <Row className="g-3">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Button type="submit" variant="success">
                          <span className="btn-label">
                            <i className="bi bi-plus-circle m-1"></i>
                            Shrani podatke
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CesnjaPridelek;
